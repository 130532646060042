/*

BoxDownload : Download の Button とその処理

Dec.08,2021 : Round.js から関連部分を移動

*/
import React from "react";
import { IconButton, } from "@chakra-ui/react"
import { DownloadIcon } from '@chakra-ui/icons'

export default function BoxDownload(props) {

    const API_ORIGIN = process.env.REACT_APP_BESVR_URI_ORIGIN

    function convertToCSV(objArray) {
        // JSON to CSV Converter
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        //console.log(array, array[0]);
        var str = '';

        for (var id in array) {
            str += array[id] + '\r\n';
        }
        return str;
    }

    const download = async (e) => {
        /*
        round score を download する．
        */
        console.log("Download")

        const downloadFile = ({ data, fileName, fileType }) => {
            const blob = new Blob([data], { type: fileType })

            const a = document.createElement('a')
            a.download = fileName
            a.href = window.URL.createObjectURL(blob)
            const clickEvt = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true,
            })
            a.dispatchEvent(clickEvt)
            a.remove()
        }

        try {
            const response = await fetch(
                API_ORIGIN + "/api/score/round/" + props.rid, { method: "GET" })
            const jsonResponse = await response.json();

            // convert to csv
            const csvFile = convertToCSV(jsonResponse)
            console.log("CSV: ", csvFile)
            // Download csv

            downloadFile({
                data: csvFile,
                fileName: 'ura.csv',
                fileType: 'text/csv',
            })

            /*
            setDlText(csvFile)   // ********* debug 用
            setIsDebug(isDebug ? false : true)

            var blob = new Blob([csvFile], { "type": "text/plain" });

            if (window.navigator.msSaveBlob) {
                window.navigator.msSaveBlob(blob, "test.txt");

                // msSaveOrOpenBlobの場合はファイルを保存せずに開ける
                window.navigator.msSaveOrOpenBlob(blob, "test.txt");
            } else {
                console.log("DL")
                document.getElementById("download").href = window.URL.createObjectURL(blob);
            }*/
        } catch (err) {
            console.log("DB Err.................")
        }
    }

    return (
        <>
            <IconButton h={props.hh} w={props.ww}
                color={props.color}
                onClick={download} icon={<DownloadIcon />} />
        </>
    )

}
