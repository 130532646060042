/*
    Manage Party
        /Party/:pid
        state: rid, course_id, course_name

    Aug.25,2024 : submit 後の windows.back 時に，スマホの browser では cache が使用されて，DB に設定した new Party が reload されないことがある．1組目を表示されないままに NewParty をすると，2組目にも関わらず 1組目の guid が設定されるため Point 計算が出来ない．要因は分かったが，対策を検討中．-> Sep.30,2024 文言を追加した．
    Feb.21,2024 : odds_pt を pbest に変更, Btn 表示修正
    Feb.20,2024 : HCap の db 連携を実装
    Jul.14,2023 : play_mode で team が変化しなかったのを修正．実害はたぶんなし
    Dec.06,2021 : Nav fixed 対応
    Dec.02,2021 : 環境変数を config から .env に変更
    Nov.30,2021 : Party Delete を追加
    Sep.01,2021 : 2組目を NewParty 時に default 値を Party0 と同じとするように変更．
                  レイアウトを微調整
    Jul.06,2021 : 前回の fetch-then-render 変更で，新Partyを作るときに，無限ループになっていたのを修正．
    Jul.04,2021 : react-hook-form の register の使い方が変更されていた
                  ついでに useEffect の warning 対策で function を中に取り込んだ．
                  fetch-then-render の新しいやり方を取得した!!!
    May.24,2021 : チーム選択時のコメント追加
    Apr.26,2021 : Half の select をシンプルに書き直し
    Apr.25,2021 : 1st Half, 2nd Half が Edit 時た正しく表示されなかった Bug を修正
    Apr.21,2021 : party default値を設定．guid は固定値を割り当て
                  cancel でも post が動いていたのを修正
    Apr.16,2021 : guid 元の値が 0 だと，false と勘違いして正しく動かなかったのを修正．selecter 修正
    Apr.14,2021 : New Party 作成で default 値を入れておいた．Submit，Cancel で元画面に戻した．
    Apr.10,2021 : API_ORIGIN を config に

*/
import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
    Button,
    Center,
    Flex,
    FormLabel,
    FormControl,
    Input,
    Select,
    Spacer,
    Spinner,
    Text,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react'
import { useParams, useLocation } from 'react-router-dom'

import config from './const'

const API_ORIGIN = process.env.REACT_APP_BESVR_URI_ORIGIN

const AlertDialogDelete = (props) => {
    const [isOpen, setIsOpen] = useState(false)

    const onClose = () => setIsOpen(false)
    const cancelRef = useRef()

    const handleDelete = async (e) => {
        // Update Party
        fetch(API_ORIGIN + '/api/party/' + props.id, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        })
        console.log('DELETE: pid: ', props.id)
        onClose()
        window.history.back()
    }

    return (
        <>
            <Button mt={props.mt} colorScheme={props.colorScheme} onClick={() => setIsOpen(true)}>
                Delete Party
            </Button>

            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Party
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? 関連する Score も削除されます． You can't undo this action
                            afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme={props.colorScheme} onClick={handleDelete} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

//
//
//
export default function Party(props) {
    const { pid } = useParams()

    const [party, setParty] = useState(null)
    const [halfList, setHalfList] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingPty, setIsLoadingPty] = useState(false)
    const { handleSubmit, errors, register, setValue, getValues, formState } = useForm()

    const location = useLocation()
    const rid = location.state.round_id
    const cid = location.state.course_id
    const course_name = location.state.course_name
    const pnum = location.state.pnum // party の数
    const inputMode = location.state.inputMode // Party1 の input mode or Default値
    const party0 = location.state.party0

    console.log('Party: pnum, inputMode, party0: rid', pnum, inputMode, party0, rid)
    // newParty 時は data あり．edit 時は data は undefined

    // ./Party/:pid
    // path param があったら，DB から pid を fetch する
    // Create New OR Update
    /* rid は使わない
    if (rid == undefined) {
        console.log("Party: Undefined rid: ")
    } else {
        console.log("Party: rid: ", rid)
    }
    */
    //const onSubmit = values => console.log("form =", values);
    //
    const onSubmit = (values) => {
        console.log('form =', values)
        if (pid == undefined) {
            // Create New Party
            fetch(API_ORIGIN + '/api/party', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values),
            })
        } else {
            // Update Party
            fetch(API_ORIGIN + '/api/party/' + pid, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values),
            })
        }
        // var base = new URL('/round/' + rid, window.location.href)
        // console.log('document.referrer: ', base.href)
        // window.location.href = base.href
        window.history.back()
    }
    const handleCancel = (e) => {
        window.history.back()
    }
    //
    // Get Course Half List
    //
    useEffect(() => {
        const fetchHalfList = async () => {
            setIsLoading(true)
            const response = await fetch(API_ORIGIN + '/api/half/' + cid)
            const resps = await response.json()
            setHalfList(resps)
            setIsLoading(false)
            console.log('courses half list :', resps)
        }
        fetchHalfList()
    }, [])
    //
    // Get Party Data
    //
    useEffect(() => {
        const fetchParty = async () => {
            setIsLoadingPty(true)
            const response = await fetch(API_ORIGIN + '/api/party/' + pid)
            const data = await response.json()
            console.log('party data :', data)
            setParty(data)
            setIsLoadingPty(false)
        }
        // pid があれば，その party を fetch
        if (pid != undefined) {
            fetchParty()
        }
    }, [])

    const selectHandler = (e) => {
        console.log('SELCT: setV: ', e.target.value)
        if (e.target.value == config.MODE4) {
            console.log('SELCT: Mode4')
            setValue('player1_tid', 'A')
            setValue('player2_tid', 'A')
            setValue('player3_tid', 'B')
            setValue('player4_tid', 'B')
        } else {
            console.log('SELCT: Mode2')
            setValue('player1_tid', 'A')
            setValue('player2_tid', 'B')
            setValue('player3_tid', 'C')
            setValue('player4_tid', 'D')
        }
    }

    const AlertGetHCap = (props) => {
        // HCap，PBest 取得ボタン
        const [isOpen, setIsOpen] = useState(false)

        const onClose = () => setIsOpen(false)
        const cancelRef = useRef()

        const handleGetHCap = async (e) => {
            // Get HCap
            var list = [
                ['player1_name', 'player1_odds', 'player1_pbest'],
                ['player2_name', 'player2_odds', 'player2_pbest'],
                ['player3_name', 'player3_odds', 'player3_pbest'],
                ['player4_name', 'player4_odds', 'player4_pbest'],
            ]
            for (var num in list) {
                if (getValues(list[num][0]) == '')
                    // 名前無しは HCap DB を引かない
                    break
                else {
                    // 名前に対応する HCap を検索
                    const response = await fetch(
                        API_ORIGIN +
                            '/api/hcap?' +
                            new URLSearchParams({
                                name: getValues(list[num][0]),
                            }),
                        {
                            method: 'GET',
                            headers: { 'Content-Type': 'application/json' },
                        }
                    )
                    const data = await response.json()
                    console.log('   HCap: ', data.hcap.username, data.hcap.hcap, data.hcap.pbest)
                    setValue(list[num][1], data.hcap.hcap)
                    setValue(list[num][2], data.hcap.pbest)
                }
            }
            onClose()
        }

        return (
            <>
                <Button
                    mt={props.mt}
                    colorScheme={props.colorScheme}
                    onClick={() => setIsOpen(true)}
                >
                    Get HandiCap & P-Best
                </Button>

                <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                Get HandiCap & P-Best
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                DBからデータを取得します．取得後に変更も可能です．
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button
                                    colorScheme={props.colorScheme}
                                    onClick={handleGetHCap}
                                    ml={3}
                                >
                                    GetHCap
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </>
        )
    }

    if ((pid !== undefined && party === null) || halfList === null) {
        // fetch が完了するまで wait.
        return <Spinner />
    }

    return (
        <>
            {isLoading || isLoadingPty ? (
                <Spinner />
            ) : (
                <Flex pos="relative" top="38px">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Text>Party Config</Text>
                        <FormControl isRequired>
                            <FormLabel htmlFor="party_data">Party Data</FormLabel>
                            <Flex>
                                <Input
                                    name="round_id"
                                    w="15%"
                                    value={rid}
                                    isReadOnly="true"
                                    {...register('round_id')}
                                />
                                <Text width="35%">{course_name} </Text>
                                <Select
                                    name="first_half"
                                    w="25%"
                                    placeholder="前半"
                                    {...register('first_half')}
                                    defaultValue={
                                        party
                                            ? party.first_half
                                            : party0
                                            ? party0.first_half
                                            : halfList[0]
                                    }
                                >
                                    {halfList.map((half, n) => {
                                        return <option value={half}>{half}</option>
                                    })}
                                </Select>
                                <Select
                                    name="second_half"
                                    w="25%"
                                    placeholder="後半"
                                    {...register('second_half')}
                                    defaultValue={
                                        party
                                            ? party.second_half
                                            : party0
                                            ? party0.second_half
                                            : halfList[1]
                                    }
                                >
                                    {halfList.map((half, n) => {
                                        return <option value={half}>{half}</option>
                                    })}
                                </Select>
                            </Flex>
                            <Flex>
                                <Select
                                    name="input_mode"
                                    w="35%"
                                    placeholder="Play Mode"
                                    {...register('input_mode')}
                                    defaultValue={
                                        party
                                            ? party.input_mode
                                            : party0
                                            ? party0.input_mode
                                            : config.VOLUNTEER_MODE
                                    }
                                >
                                    <option value={config.INDIVISUAL_MODE}>個別入力</option> :
                                    <option selected="true" value={config.VOLUNTEER_MODE}>
                                        まとめ入力
                                    </option>
                                </Select>
                                <Select
                                    w="65%"
                                    name="play_mode"
                                    placeholder="Play Mode"
                                    {...register('play_mode')}
                                    defaultValue={
                                        party
                                            ? party.play_mode
                                            : party0
                                            ? party0.play_mode
                                            : config.MODE4
                                    }
                                    onChange={selectHandler}
                                >
                                    <option value={config.MODE2}>2人/チーム(要2 Party)</option>
                                    <option selected value={config.MODE4}>
                                        4人/チーム(1 Party時はこちら)
                                    </option>
                                </Select>
                            </Flex>
                        </FormControl>
                        <Center>Party Members</Center>

                        <FormControl>
                            <Flex>
                                <FormLabel textAlign="center" htmlFor="Name" w="20%">
                                    Name
                                </FormLabel>
                                <Input
                                    name="player1_name"
                                    placeholder="name"
                                    defaultValue={party ? party.player1_name : ''}
                                    w="20%"
                                    {...register('player1_name')}
                                />
                                <Input
                                    name="player2_name"
                                    placeholder="name"
                                    defaultValue={party ? party.player2_name : ''}
                                    w="20%"
                                    {...register('player2_name')}
                                />
                                <Input
                                    name="player3_name"
                                    placeholder="name"
                                    defaultValue={party ? party.player3_name : ''}
                                    w="20%"
                                    {...register('player3_name')}
                                />
                                <Input
                                    name="player4_name"
                                    placeholder="name"
                                    defaultValue={party ? party.player4_name : ''}
                                    w="20%"
                                    {...register('player4_name')}
                                />
                            </Flex>
                        </FormControl>
                        <FormControl>
                            <Flex>
                                <FormLabel textAlign="center" htmlFor="Tid" w="20%">
                                    Team id
                                </FormLabel>
                                <Input
                                    name="player1_tid"
                                    defaultValue={party ? party.player1_tid : 'A'}
                                    w="20%"
                                    {...register('player1_tid')}
                                />
                                <Input
                                    name="player2_tid"
                                    defaultValue={
                                        party
                                            ? party.player2_tid
                                            : party0 && party0.play_mode == config.MODE2
                                            ? 'B'
                                            : 'A'
                                    }
                                    w="20%"
                                    {...register('player2_tid')}
                                />
                                <Input
                                    name="player3_tid"
                                    defaultValue={
                                        party
                                            ? party.player3_tid
                                            : party0 && party0.play_mode == config.MODE2
                                            ? 'C'
                                            : 'B'
                                    }
                                    w="20%"
                                    {...register('player3_tid')}
                                />
                                <Input
                                    name="player4_tid"
                                    defaultValue={
                                        party
                                            ? party.player4_tid
                                            : party0 && party0.play_mode == config.MODE2
                                            ? 'D'
                                            : 'B'
                                    }
                                    w="20%"
                                    {...register('player4_tid')}
                                />
                            </Flex>
                        </FormControl>
                        <FormControl isReadOnly>
                            <Flex>
                                <FormLabel textAlign="center" htmlFor="guid" w="20%">
                                    Guid
                                </FormLabel>
                                <Input
                                    name="player1_guid"
                                    placeholder="0"
                                    value={party ? party.player1_guid : pnum * 4 + 0}
                                    w="20%"
                                    {...register('player1_guid')}
                                />
                                <Input
                                    name="player2_guid"
                                    placeholder="1"
                                    value={party ? party.player2_guid : pnum * 4 + 1}
                                    w="20%"
                                    {...register('player2_guid')}
                                />
                                <Input
                                    name="player3_guid"
                                    placeholder="2"
                                    value={party ? party.player3_guid : pnum * 4 + 2}
                                    w="20%"
                                    {...register('player3_guid')}
                                />
                                <Input
                                    name="player4_guid"
                                    placeholder="3"
                                    value={party ? party.player4_guid : pnum * 4 + 3}
                                    w="20%"
                                    {...register('player4_guid')}
                                />
                            </Flex>
                        </FormControl>
                        <FormControl>
                            <Flex>
                                <FormLabel textAlign="center" htmlFor="Odds" w="20%">
                                    ハンデ
                                </FormLabel>
                                <Input
                                    name="player1_odds"
                                    defaultValue={party ? party.player1_odds : '0'}
                                    w="20%"
                                    {...register('player1_odds')}
                                />
                                <Input
                                    name="player2_odds"
                                    defaultValue={party ? party.player2_odds : '0'}
                                    w="20%"
                                    {...register('player2_odds')}
                                />
                                <Input
                                    name="player3_odds"
                                    defaultValue={party ? party.player3_odds : '0'}
                                    w="20%"
                                    {...register('player3_odds')}
                                />
                                <Input
                                    name="player4_odds"
                                    defaultValue={party ? party.player4_odds : '0'}
                                    w="20%"
                                    {...register('player4_odds')}
                                />
                            </Flex>
                        </FormControl>
                        <FormControl>
                            <Flex>
                                <FormLabel textAlign="center" htmlFor="OddsPt" w="20%">
                                    自己ベスト:pbest
                                </FormLabel>
                                <Input
                                    name="player1_pbest"
                                    defaultValue={party ? party.player1_pbest : '0'}
                                    w="20%"
                                    {...register('player1_pbest')}
                                />
                                <Input
                                    name="player2_pbest"
                                    defaultValue={party ? party.player2_pbest : '0'}
                                    w="20%"
                                    {...register('player2_pbest')}
                                />
                                <Input
                                    name="player3_pbest"
                                    defaultValue={party ? party.player3_pbest : '0'}
                                    w="20%"
                                    {...register('player3_pbest')}
                                />
                                <Input
                                    name="player4_pbest"
                                    defaultValue={party ? party.player4_pbest : '0'}
                                    w="20%"
                                    {...register('player4_pbest')}
                                />
                            </Flex>
                        </FormControl>
                        <FormControl>
                            <Flex>
                                <FormLabel textAlign="center" htmlFor="al" w="20%">
                                    朝アル
                                </FormLabel>
                                <Input
                                    name="player1_al"
                                    defaultValue={party ? party.player1_al : '0'}
                                    w="20%"
                                    {...register('player1_al')}
                                />
                                <Input
                                    name="player2_al"
                                    defaultValue={party ? party.player2_al : '0'}
                                    w="20%"
                                    {...register('player2_al')}
                                />
                                <Input
                                    name="player3_al"
                                    defaultValue={party ? party.player3_al : '0'}
                                    w="20%"
                                    {...register('player3_al')}
                                />
                                <Input
                                    name="player4_al"
                                    defaultValue={party ? party.player4_al : '0'}
                                    w="20%"
                                    {...register('player4_al')}
                                />
                            </Flex>
                        </FormControl>

                        <Flex>
                            <Spacer />
                            <AlertGetHCap mt={4} colorScheme="blue" />
                        </Flex>
                        <Button
                            mt={4}
                            colorScheme="teal"
                            variant="outline"
                            onClick={handleCancel}
                            type="reset"
                        >
                            Cancel
                        </Button>
                        <AlertDialogDelete mt={4} colorScheme="orange" id={pid} />
                        <Button
                            mt={4}
                            colorScheme="teal"
                            isLoading={formState.isSubmitting}
                            type="submit"
                        >
                            Submit
                        </Button>
                        <Flex>
                            <Text as="em" color="tomato" textAlign="left" ml={5}>
                                *** 作成後は Reload して内容を確認してください．***
                            </Text>
                        </Flex>
                    </form>
                </Flex>
            )}
        </>
    )
}
