/*
   Total Score 表示
    props.uid に対応した scorePx に stroke を設定する．

    Input: PartyContext, uid
    Output: Display Only / update TotalScore

    Mar.08,2025 : コメント修正のみ
    Nov.03,2024 : Layout 調整
    Nov.02,2024 : 文字列 Pt が select されて，うざいので選択不可に css を設定
                  Pt を click すると，stroke/putt に default 値を入れる
    Nov.01,2024 : plusMinusMode 変更ボタンを ScoreBoard から BoxTotal に移動
    Jul.18,2023 : plusMinusMode 対応
    Jul.14,2023 : fontSize Up して，delta 表示を削除
    Apr.18,2021 : Stroke と st_delta を上下から左右に並べることでコンパクト化した．
    Apr.16,2021 : 最初のHole で score data が無い時の処理を追加
*/
import React, { useContext } from 'react'
import { Box, Button, Center, HStack, Text } from '@chakra-ui/react'

import { PartyContext } from '../pages/Score'
import config from '../pages/const'

export default function BoxTotal(props) {
    // 基本 Box
    const {
        curHole,
        inputMode,
        inputUid,
        colorZone1,
        colorZone2,
        colorZone3,
        courseData,
        plusMinusMode,
        partyData,
        setPlusMinusMode,
        scoreP1,
        setScoreP1,
        scoreP2,
        setScoreP2,
        scoreP3,
        setScoreP3,
        scoreP4,
        setScoreP4,
        shotScores,
    } = useContext(PartyContext)

    var myZone = colorZone3
    if (inputUid === props.uid) myZone = colorZone1
    else if (inputMode === config.VOLUNTEER_MODE) myZone = colorZone2

    const scorePx = eval('scoreP' + props.uid)
    const setScorePx = eval('setScoreP' + props.uid)
    const writeProtect = scorePx.lock || props.zone === colorZone3

    //console.log("TTL: ", props.uid, shotScores.filter((v) => v.p_id == props.uid))

    // curHole 以外の shotScore を scores とする．
    const scores =
        shotScores.filter((v) => v.p_id == props.uid) !== undefined
            ? shotScores.filter((v) => v.p_id == props.uid && v.hole_order !== curHole)
            : null

    // score の ttl と，現在表示の stroke を足して ttlStroke とする．
    const ttlStroke = scores
        ? scores.reduce((sum, sc) => {
              return sum + sc.stroke
          }, 0) + scorePx.stroke
        : scorePx.stroke
    const ttlStrokeDelta = scores
        ? scores.reduce((sum, sc) => {
              return sum + sc.st_delta
          }, 0) + scorePx.st_delta
        : scorePx.st_delta
    const ttlPutt = scores
        ? scores.reduce((sum, sc) => {
              return sum + sc.putt
          }, 0) + scorePx.putt
        : scorePx.putt

    // Pt を Click すると，Stroke == 0(未入力) なら default値 Stroke = Par，Pt = 2 が入る
    // VOL MODE なら全員に Default を入れる(名前なしは入れない)
    const handleDefStroke = (e) => {
        if (!writeProtect) {
            if (
                inputMode === config.VOLUNTEER_MODE &&
                scoreP1.stroke === 0 &&
                scoreP2.stroke === 0 &&
                scoreP3.stroke === 0 &&
                scoreP4.stroke === 0
            ) {
                if (partyData.player1_name !== '') {
                    console.log('setScore1 : ', partyData.player1_name)
                    setScoreP1({
                        ...scoreP1,
                        stroke: courseData[curHole - 1].par,
                        st_delta: 0,
                        putt: 2,
                    })
                }
                if (partyData.player2_name !== '') {
                    console.log('setScore2 : ', partyData.player2_name)
                    setScoreP2({
                        ...scoreP2,
                        stroke: courseData[curHole - 1].par,
                        st_delta: 0,
                        putt: 2,
                    })
                }
                if (partyData.player3_name !== '') {
                    console.log('setScore3 : ', partyData.player3_name)
                    setScoreP3({
                        ...scoreP3,
                        stroke: courseData[curHole - 1].par,
                        st_delta: 0,
                        putt: 2,
                    })
                }
                if (partyData.player4_name !== '') {
                    console.log('setScore4 : ', partyData.player4_name)
                    setScoreP4({
                        ...scoreP4,
                        stroke: courseData[curHole - 1].par,
                        st_delta: 0,
                        putt: 2,
                    })
                }
            } else if (inputMode !== config.VOLUNTEER_MODE && scorePx.stroke === 0) {
                setScorePx({
                    ...scorePx,
                    stroke: courseData[curHole - 1].par,
                    st_delta: 0,
                    putt: 2,
                })
            }
        }
        console.log('TTL: SET !! : ', writeProtect)
    }

    return (
        <>
            {/* Total Stroke */}
            <Center color="gray.600" h={3} fontSize={14}>
                THRU
            </Center>
            <Button
                textAlign="center"
                my={0}
                borderRadius={5}
                w="100%"
                bg="gray.200"
                onClick={() => setPlusMinusMode((prev_state) => !prev_state)}
            >
                <Center
                    fontSize={32}
                    fontWeight="bold"
                    borderRadius={5}
                    color="gray.600"
                    bg="gray.200"
                >
                    {plusMinusMode ? (ttlStrokeDelta > 0 && '+') + ttlStrokeDelta : ttlStroke}
                </Center>
            </Button>
            <HStack>
                <Box color="gray.600" fontSize={18} h={18} w="30%" onClick={handleDefStroke}>
                    Pt
                </Box>
                {/* Total Putt */}
                <Center
                    fontSize={30}
                    fontWeight="bold"
                    borderRadius={5}
                    color="gray.600"
                    bg="gray.200"
                    w="70%"
                    sx={{
                        webkitUserSelect: 'none',
                        mozUserSelect: 'none',
                        webkitTouchCallout: 'none',
                        userSelect: 'none',
                    }}
                >
                    {ttlPutt}
                </Center>
            </HStack>
        </>
    )
}
