/*
DrawOBPE : OB/PE/BK

OB / PE / BK の Drawer 入力

Nov.03,2024 : Layout 調整
Nov.02,2024 : input: readonly -> isReadOnly だった
Nov.01,2024 : Slider の lock 対応，CntBtn を DrawMemo と共通化
Oct.29,2024 : DrawerCloseButton を追加
Oct.28,2024 : ひとつの Drawer で 4人分の OE/PE/BK を入力可とする．

*/
import React, { useContext, useEffect } from 'react'
import {
    Box,
    Button,
    Center,
    Stack,
    HStack,
    VStack,
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Input,
    useNumberInput,
} from '@chakra-ui/react'

import config from '../pages/const'
import MiniBoxOBPE from './MiniBoxOBPE'

import { PartyContext } from '../pages/Score'

export function CntBtn(props) {
    /*
    Gon(drawerMemo) 用の logic が対象的ではないので注意
    props:
        cat: [ ob | pe | bk | Gon ]
        uid: inputUid
        scorePx: scorePn | memoGon
        setScorePx: setScorePn | setMemoGon
        lock: writeProtect
    */
    const {
        inputMode,
        inputUid,
        colorZone1,
        colorZone2,
        colorZone3,
        scoreP1,
        setScoreP1,
        scoreP2,
        setScoreP2,
        scoreP3,
        setScoreP3,
        scoreP4,
        setScoreP4,
    } = useContext(PartyContext)

    var myZone = colorZone3
    if (inputUid === props.uid) myZone = colorZone1
    else if (inputMode === config.VOLUNTEER_MODE) myZone = colorZone2

    const scorePx = eval('scoreP' + props.uid)
    const setScorePx = eval('setScoreP' + props.uid)
    // ind mode 時は zone3 は書き込み禁止
    const writeProtect = (props.cat != 'Gon' ? scorePx.lock : props.lock) || myZone === colorZone3

    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps, valueAsNumber } =
        useNumberInput({
            step: 1,
            defaultValue:
                props.cat == 'ob'
                    ? scorePx.ob
                    : props.cat == 'pe'
                    ? scorePx.penalty
                    : props.cat == 'bk'
                    ? scorePx.banker
                    : props.cat == 'Gon'
                    ? props.memoGon
                    : 0,
            min: 0,
            max: 9,
            precision: 0,
            // onChange: console.log('IN ***** '),
            // onValueChange: console.log('ValChange ***** '),
        })

    // Button の isDisabled が効かないので，関数を disable にして対応した
    const inc = !writeProtect && getIncrementButtonProps()
    const dec = !writeProtect && getDecrementButtonProps()
    const input = getInputProps()

    useEffect(() => {
        // console.log('HC ***** ', valueAsNumber)
        if (writeProtect != true) {
            if (props.cat === 'ob') {
                setScorePx({
                    ...scorePx,
                    ob: Number(valueAsNumber),
                })
            } else if (props.cat === 'bk') {
                setScorePx({
                    ...scorePx,
                    banker: Number(valueAsNumber),
                })
            } else if (props.cat === 'pe') {
                setScorePx({
                    ...scorePx,
                    penalty: Number(valueAsNumber),
                })
            } else if (props.cat === 'Gon') {
                props.setMemoGon(valueAsNumber)
            }
        }
        // console.log('HC val = ', props.cat, valueAsNumber)
    }, [, valueAsNumber])

    return (
        <HStack spacing={1.0}>
            <Input
                fontSize={40}
                fontWeight="bold"
                // maxW={60}
                h={45}
                w={34}
                p={1}
                color="white"
                bg={myZone}
                isReadOnly={true}
                isDisabled={writeProtect}
                sx={{
                    webkitUserSelect: 'none',
                    mozUserSelect: 'none',
                    webkitTouchCallout: 'none',
                    userSelect: 'none',
                }}
                {...input}
            />
            <VStack spacing={0}>
                <Button
                    w={30}
                    h={25}
                    m={0}
                    fontSize={24}
                    fontWeight="bold"
                    color="white"
                    bg={writeProtect ? 'gray.400' : 'red.500'}
                    {...inc}
                >
                    ＋
                </Button>
                <Button
                    w={30}
                    h={25}
                    m={0}
                    fontSize={30}
                    fontWeight="bold"
                    color="white"
                    bg={writeProtect ? 'gray.400' : 'blue.600'}
                    {...dec}
                >
                    −
                </Button>
            </VStack>
        </HStack>
    )
}

export default function DrawOBPE(props) {
    //
    // Drawer for OB/PE/BK
    //
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    return (
        <>
            <HStack spacing={3}>
                <Button w="100%" p={0} m={0} color="gray.700" bg="blue.100" h={70} onClick={onOpen}>
                    <Box w="25%">
                        <MiniBoxOBPE uid={1} />
                    </Box>
                    <Box w="25%">
                        <MiniBoxOBPE uid={2} />
                    </Box>
                    <Box w="25%">
                        <MiniBoxOBPE uid={3} />
                    </Box>
                    <Box w="25%">
                        <MiniBoxOBPE uid={4} />
                    </Box>
                </Button>
            </HStack>
            <Drawer
                size="md"
                isOpen={isOpen}
                placement="bottom"
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerBody>
                            <Box h={10} />
                            <Box fontSize={18} bg="gray.100">
                                OB
                            </Box>
                            <HStack>
                                <CntBtn key="ob1" cat="ob" uid="1" />
                                <CntBtn key="ob2" cat="ob" uid="2" />
                                <CntBtn key="ob3" cat="ob" uid="3" />
                                <CntBtn key="ob4" cat="ob" uid="4" />
                            </HStack>
                            <Box fontSize={18} bg="gray.100">
                                PE
                            </Box>
                            <HStack>
                                <CntBtn key="pe1" cat="pe" uid="1" />
                                <CntBtn key="pe2" cat="pe" uid="2" />
                                <CntBtn key="pe3" cat="pe" uid="3" />
                                <CntBtn key="pe4" cat="pe" uid="4" />
                            </HStack>
                            <Box fontSize={18} bg="gray.100">
                                BK
                            </Box>
                            <HStack>
                                <CntBtn key="bk1" cat="bk" uid="1" />
                                <CntBtn key="bk2" cat="bk" uid="2" />
                                <CntBtn key="bk3" cat="bk" uid="3" />
                                <CntBtn key="bk4" cat="bk" uid="4" />
                            </HStack>
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    )
}
