/*
    BoxHole : curHole Button

    Nov.01,2024 : menuList の位置と幅を調整
    Jul.17,2023 : MenuList の文字を大きくした
    Nov.10,2022 : Half 名が入るように Layout 変更
    Nov.15,2021 : Menu は Lock 時に gray に変更
    Aug.10,2021 : Menu Button Color 変更
    May.24,2021 : Half 名の文字数制限追加
    Apr.26,2021 : map の key 修正
    Apr.14,2021 : font color 変更
*/
import React, { useContext } from 'react'
import {
    // Box, Text,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    //MenuItemOption, MenuGroup, MenuOptionGroup,
    //MenuIcon, MenuCommand,MenuDivider,
} from '@chakra-ui/react'

import { PartyContext } from '../pages/Score'

const MAX_NAME_LEN = 3

export default function BoxHole(props) {
    // Hole  の基本 Box
    const { curHole, setCurHole, courseData, handleSubmit } = useContext(PartyContext)
    //console.log("HOLE :", courseData)
    //console.log("HOLE1 :", curHole)

    //const [curHole, setCurHole] = useState([]);
    //{ courseData[curHole].hole_name }
    var list = []
    for (var i in courseData) {
        list.push({ label: courseData[i].hole_full_name, value: i })
    }
    //console.log("Holelist :", list)

    return (
        <Menu {...props} offset={[-100, 0]} scroll="true" matchWidth="true">
            <MenuButton
                color={props.color}
                fontWeight="bold"
                bg={props.bg}
                p={0}
                w={28}
                as={Button}
            >
                {courseData
                    ? courseData[curHole - 1].half_name.slice(0, MAX_NAME_LEN) +
                      courseData[curHole - 1].hole_num
                    : ''}
            </MenuButton>
            <MenuList maxHeight={640} overflowY="auto" fontSize={24} p={0} minW="0" w={'120px'}>
                {list.map((menu, n) => (
                    <MenuItem
                        key={n}
                        py={0}
                        onClick={(e) => {
                            if (props.update) handleSubmit()
                            setCurHole(Number(menu.value) + 1)
                        }}
                    >
                        {menu.label}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}
