/*
    Round (Home Page)
        /Round/:rid

    - Round Data を fetch
    - button と一緒に表示

    Oct.19,2023 : database v2: s_name 対応(修正漏れ対応)
    Nov.10,2022 : PointBoard が back で元画面に戻るように変更
    Dec.08,2021 : Download を BoxDownload に分割
    Dec.07,2021 : Download ひとまず完成．Button を Icon に変更
    Dec.06,2021 : Nav fixed 対応
    Dec.03,2021 : TeamTable は member 1 以上で表示する(for CheckDB)
    Dec.02,2021 : 環境変数を config から .env に変更
    Nov.20,2021 : download 試作実装
    Sep.01,2021 : 2組目作成時 の NewParty で 1組目の input mode をパラメータ渡しする．
    Apr.26,2021 : Box の warning を fix
    Apr.22,2021 : couse data の受け渡しを course に変更(course_id, course_name をまとめた)
    Apr.21,2021 : guid は固定値を割り当てるため，Party に party数(pnum)を渡す
    Apr.14,2021 : Leaders Board 実装
    Apr.10,2021 : API_ORIGIN を config に

*/
import React, { useState, useEffect } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Button,
    Flex,
    Spacer,
    Spinner,
    Text,
} from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'

import config from './const'
//import TBD from "./pages/TBD"
//import { BrowserRouter as Router, Route } from "react-router-dom"
//import BoxRound from "../components/BoxRound"
import BoxParty from '../components/BoxParty'
import TeamTable from '../components/TeamTable'
import BoxLB from '../components/BoxLeaderBoard'
import BoxDownload from '../components/BoxDownload'

//import API_ORIGIN from  '../config'
/*
   ここでは，round & party data が必要．
   round rid, 日時，コース番号 -> コース名
   party pid, player1_name

*/

export default function Round(props) {
    /*
    Round 情報表示
    */
    const API_ORIGIN = process.env.REACT_APP_BESVR_URI_ORIGIN

    const { rid } = useParams()
    console.log('Round: rid: ', rid)

    const [defaultPlayMode, setDefaultPlayMode] = useState(config.MODE2)
    const [defaultInputMode, setDefaultInputMode] = useState(config.VOLUNTEER_MODE)
    const [defaultMembers, setDefaultMembers] = useState([]) // Member Orig
    const [members, setMembers] = useState([]) // Member(チーム組合せ)
    const [inputUid, setInputUid] = useState(sessionStorage.getItem('InputUid') ?? 0)
    const [myParty, setMyParty] = useState(sessionStorage.getItem('myParty') ?? 0)
    // Base Round DB
    //   round
    //   course data
    //   parties
    const [round, setRound] = useState({
        id: 0,
        date: null,
        course: { id: 0, course_dts: [], course_name: '' },
        parties: [],
        //parties: [{ first_half: "", second_half: "" }],
    })
    const [isLoading, setIsLoading] = useState(false)

    var mems = []
    // Get Round Data
    const fetchRound = async () => {
        setIsLoading(true)
        const response = await fetch(API_ORIGIN + '/api/round/' + rid)
        const results = await response.json()
        console.log('Round results(json):', results)
        results.parties.map((d, i) => {
            if (d.player1_name != '')
                mems.push({ pty: i, oid: d.player1_guid, name: d.player1_name })
            if (d.player2_name != '')
                mems.push({ pty: i, oid: d.player2_guid, name: d.player2_name })
            if (d.player3_name != '')
                mems.push({ pty: i, oid: d.player3_guid, name: d.player3_name })
            if (d.player4_name != '')
                mems.push({ pty: i, oid: d.player4_guid, name: d.player4_name })
        })
        console.log('Members: ', mems)
        setDefaultMembers(mems)
        if (results.parties.length > 0) {
            setDefaultPlayMode(results.parties[0].play_mode)
            setDefaultInputMode(results.parties[0].input_mode)
        }
        setRound(results)
        setIsLoading(false)
    }
    useEffect(() => {
        fetchRound()
    }, [])

    const round_path = {
        pathname: '/AddRound/' + rid,
        state: {
            date: round.date,
            course_id: round.course.id,
            course_name: round.course.course_name,
            s_name: round.s_name,
        },
    }
    const party_path = {
        // New Party
        pathname: '/Party/',
        state: {
            round_id: rid,
            course_id: round.course.id,
            course_name: round.course.course_name,
            pnum: round.parties.length,
            inputMode: defaultInputMode,
            party0: round.parties[0],
        },
    }

    const point_path = {
        pathname: '/Point/' + rid,
        state: {
            course: round.course,
            parties: round.parties,
        },
    }

    return (
        <div>
            {isLoading ? (
                <Spinner />
            ) : (
                <Box pos="relative" top="38px">
                    <Box p={1} m={1} border="1px" borderColor="gray.300">
                        <Flex m={3}>
                            <Box mx={2}> {round.date}</Box>
                            <Box mx={2}> {round.course.course_name}</Box>
                            <Link to={round_path}>
                                <Text color="tomato" mr={3}>
                                    : Edit
                                </Text>
                            </Link>
                            <Spacer />
                            <Link to={party_path}>
                                <Button colorScheme="green">NewParty</Button>
                            </Link>
                        </Flex>

                        {round.parties.length != 0 && (
                            <>
                                {/*  Pt/LB/DL 表示  */}
                                <Flex m={3}>
                                    <Link to={point_path}>
                                        <Button colorScheme="yellow">Point Board</Button>
                                    </Link>
                                    <BoxLB rid={rid} />

                                    <Spacer />
                                    <BoxDownload rid={rid} hh={10} ww={12} color="gray.600" />
                                </Flex>
                            </>
                        )}
                        {/*  Party Box 表示  */}
                        {round.parties.length != 0 && (
                            <Box>
                                {round.parties.map((pEntry, i) => {
                                    const pid = pEntry.id
                                    console.log('ROUND: pty: ', pEntry)
                                    return (
                                        <BoxParty
                                            key={i}
                                            pnum={i + 1}
                                            pid={pid}
                                            rid={round.id}
                                            course={round.course}
                                            parties={round.parties}
                                            pData={pEntry}
                                            inputUid={inputUid}
                                            setInputUid={setInputUid}
                                            myParty={myParty}
                                            setMyParty={setMyParty}
                                        />
                                    )
                                })}
                            </Box>
                        )}
                        <Accordion defaultIndex={[0]} allowMultiple>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            Teams
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    {defaultMembers.length >= 1 && (
                                        <TeamTable
                                            parties={round.parties}
                                            playMode={defaultPlayMode}
                                            members={members}
                                            defaultMembers={defaultMembers}
                                        />
                                    )}
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Box>
                </Box>
            )}
        </div>
    )
}
