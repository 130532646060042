/*

DP/NP 表示: ReadOnly Ver.

Nov.03,2024 : Layout 調整
Feb.27,2024 : DP/NP ReadOnly

*/
import React, { useContext } from 'react'
import { Button, Stack } from '@chakra-ui/react'

import { PartyContext } from '../pages/Score'

export default function BoxDPNP(props) {
    const { scoreP1, scoreP2, scoreP3, scoreP4 } = useContext(PartyContext)

    const scorePx = eval('scoreP' + props.uid)
    // const setScorePx = eval('setScoreP' + props.uid)

    const items = [
        { label: '-', value: 0 },
        { label: 'DP', value: 1 },
        { label: 'NP', value: 2 },
    ]
    /*
    const newVal =
        scorePx.dp != undefined &&
        //scorePx.dp != 0 &&  reset = 0 なので活かす
        items.find((x) => x.value == scorePx.dp).label
    */
    return (
        <Button
            textAlign="center"
            color={scorePx.dp > 0 ? 'yellow.600' : 'gray.600'}
            bg="gray.200"
            fontSize={24}
            w="100%"
            px={0}
        >
            {items.find((x) => x.value == scorePx.dp).label}
        </Button>
    )
}
