/*
**   Score:

    Feb.26,2024 : TeeShot2 を導入，tee_shot default=0 に変更
    Jul.18,2023 : plusMinusMode を導入
    Nov.08,2022 : Score 重複登録再発.回避のため, Upsert (method: PUT)に変更.POST は廃止.
    Dec.11,2021 : ws 管理見直しに伴う変更
    Dec.06,2021 : useEffect : update 送信条件の onLine を削除
    Dec.03,2021 : update に dp, ol が抜けていたのを修正
    Dec.02,2021 : 環境変数を config から .env に変更
    Nov.29,2021 : 自 Hole で Submit した時の Write Protect を TopBody に移動
    Nov.28,2021 : DB にない各端末の最後の表示を BE に持たせて，共有する dispScore を採用
    Nov.27,2021 : resume 時の fetchScore が trig がなくて出来てない．
    Nov.24,2021 : ws.py 対応
    Nov.18,2021 : OnLine の update を使って， update score を送信．resume 時の score 同期をする．
    Nov.17,2021 : POST 後の setIsUpdate が逆だったのを修正．
    Nov.16,2021 : wakeup を検出し，fetchScore する
                  個人入力モード時，メンバに定期的に score を通知することで，画面表示の
                  同期が取れるようになる
    Oct.31,2021 : コメント追加
    Oct.22,2021 : DB Err 時は reload を追加．DB 同期により2重登録防止を期待．
    Jun.28,2021 : isUpdate の初期値が不安定なのか??? POST でなく PUTでエラーになる．
                  curHole の変更時に初期化した．(hole 変更時には必ず submit されるはずなので問題ないはず．)
                  ScoreDB Write Error 時の処理を見直し．Err 時は unlock を維持するとした．
    May.27,2021 : Post/Put Error 時に Alert を出した
    Apr.29,2021 : msg chat を受けるようにしたが console 出力のみ．要改善．
    Apr.28,2021 : curHole Score の取得を uid ごとから，4人分まとめて取得に変更
    Apr.22,2021 : couse data の受け渡しを course に変更(course_id, course_name をまとめた)
    Apr.20,2021 : Browser resume 時に totalScore を fetch
    Apr.19,2021 : 2重に開こうとしたときには Alert を出して知らせるようにした．
    Apr.18,2021 : post 時に scorePn に lock=true をすることで，
                  自hole を選択したときでも WriteProtect に移行させる
    Apr.16,2021 : socket 管理を Class に戻した
                  Total Score の Update 着手したが未完成
    Apr.15,2021 : tee_shot default=1 に変更
    Apr.14,2021 : round delete 追加
    Apr.11,2021 : ws を js 標準から reconnecting-websocket に変更
    Apr.10,2021 : API_ORIGIN を config に
    Apr.09,2021 : file open 時に connection を開くように変更

    ScoreBoard
      - TopBody:  <    > ...
        - BoxHole  : cur hole Btn
      - MidBody
        - BoxScore x4
          - BoxTotal
          - BoxSt / BoxPt
          - BoxOL / BoxDPNP
          - TeeShot
      - BoxPt(OB,BK,PE)
      - BoxMemo
      - DrawExtSite
      - Btn:Point
*/
import React, { useState, useEffect, useRef, createContext } from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    Spinner,
} from '@chakra-ui/react'

import ScoreBoard from '../components/ScoreBoard'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import ReconnectingWebSocket from 'reconnecting-websocket'

import config from './const'

export const PartyContext = createContext()
/// ==============================================================
///  SCORE
/// ==============================================================
export default function Score(props) {
    const { rid, pid } = useParams()

    const API_ORIGIN = process.env.REACT_APP_BESVR_URI_ORIGIN
    const WS_ORIGIN = process.env.REACT_APP_BESVR_WS_ORIGIN

    const location = useLocation()
    //const cid = location.state.course.id;
    const course = location.state.course
    const hashHole = location.hash
    if (hashHole !== '') sessionStorage.setItem('curHole', hashHole.slice(1))

    const [curHole, setCurHole] = useState(Number(sessionStorage.getItem('curHole')) ?? 1) // curHole: 1 => Actual Hole: 1

    const [plusMinusMode, setPlusMinusMode] = useState(false)
    // --------------------------------------------------------------
    //  初期化
    // --------------------------------------------------------------
    // ブラウザが Resume したら，TTL Score を Fetch する
    // (Score入力の前であれば，メンバ全員が前ホールまでのスコアを入力済み
    // である可能性が高い．したがって，前ホールまでの Total の整合が取れるだろう
    //
    // hidden プロパティおよび可視性の変更イベントの名前を設定
    var hidden, visibilityChange
    // wakeupCount は sleep から resume したときに ws を open するトリガとなる
    const [wakeupCount, setWakeupCount] = useState(0) //
    useEffect(() => {
        //console.log("Score rid pid cid = ", rid, pid, cid)
        //console.log("HashHole: ", hashHole);
        if (typeof document.hidden !== 'undefined') {
            // Opera 12.10 や Firefox 18 以降でサポート
            hidden = 'hidden'
            visibilityChange = 'visibilitychange'
        } else if (typeof document.msHidden !== 'undefined') {
            hidden = 'msHidden'
            visibilityChange = 'msvisibilitychange'
        } else if (typeof document.webkitHidden !== 'undefined') {
            hidden = 'webkitHidden'
            visibilityChange = 'webkitvisibilitychange'
        }
        // Sleep する時と画面が隠れる時のトリガ
        function handleVisibilityChange() {
            if (document[hidden]) {
                console.log('BROWSER PAUSE')
                if (inputMode == config.INDIVISUAL_MODE && inputUid != 0) {
                    var msg = {
                        type: 'showdown',
                        uid: 'u' + inputUid,
                        place: '1',
                    }
                    console.log('>>>>>>>> SEND: SHOWDOWN(1): ', msg)
                    socketRef.current.send(JSON.stringify(msg))
                }
            } else {
                console.log('BROWSER UP & fetch Scores')
                // wakeup は debug 用．login とは関係なし
                //  Dec.11,2021 Tab が切り替わったときの OnLine に必要
                if (inputMode == config.INDIVISUAL_MODE && inputUid != 0) {
                    var msg = {
                        type: 'showup',
                        //hole: curHole,
                        uid: 'u' + inputUid,
                        place: '1',
                    }
                    console.log('>>>>>>>> SEND: SHOWUP(1): ', msg)
                    socketRef.current.send(JSON.stringify(msg))
                }
                // 画面on時，画面off の間に入力された score update のためのトリガ
                // DB から curHole 以外の score を取得，+ curHole Score で TTL を算出
                console.log('xxxxxxxxxx fetchShotScores Trig: (1)')
                setWakeupCount((c) => c + 1) // useEffect で fetchShotScores() する
            }
        }
        // ブラウザーが addEventListener または Page Visibility API をサポートしない場合に警告
        if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
            console.log(
                'This requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.'
            )
        } else {
            // Page Visibility の変更を扱う
            document.addEventListener(visibilityChange, handleVisibilityChange, false)
        }
        /*
        var lastTime = (new Date()).getTime();
        setInterval(function () {
            // Sleep 検出
            var currentTime = (new Date()).getTime();
            if (currentTime > (lastTime + 2000 * 2)) {  //ignore small delays
                setTimeout(function () {
                    //
                    // WAKE UP 後の処理
                    //
                    //fetchShotScores()
                    //setWakeupCount(wakeupCount + 1)
                    //console.log("WAKE UP: ", wakeupCount + 1)
                }, 2000);
            }
            lastTime = currentTime;
        }, 2000); */
    }, [])

    // Context 用 編集データ
    var curHoleHalfName = ''
    var curHoleHalfNum = 0

    // Context 用 元データ
    //const [parties, setParties] = useState(location.state.parties) // すべての Pty (for Point)
    const parties = location.state.parties // すべての Pty (for Point)
    const [partyData, setPartyData] = useState([])
    const inputMode = location.state.input_mode
    //console.log("location Inputmode: ", inputMode)
    const inputUid = sessionStorage.getItem('InputUid')
    //const myParty = sessionStorage.getItem('myParty')
    const [courseData, setCourseData] = useState() // 初期値はなしで動いた
    const [isLoading, setIsLoading] = useState(true)
    const [isUpdate, setIsUpdate] = useState(false) // On Update Operation
    //const [WriteProtected, setWriteProtected] = useState({
    //    p1: false, p2: false, p3: false, p4: false
    //});
    const [playMode, setPlayMode] = useState(props.playMode)
    const colorZone1 = 'green.600'
    const colorZone2 = 'blue.500'
    const colorZone3 = 'gray.500'

    //console.log("SCORE: PartyData: ", partyData)
    //console.log("INIT SCORE: ", inputMode, inputUid)
    // 各uid の Score Buffer
    const initScore = {
        party_id: Number(pid),
        hole_order: 1,
        half_name: '',
        hole_num: 1,
        guid: 0,
        p_id: 0,
        stroke: 0,
        st_delta: 0,
        putt: 0,
        ol: 0,
        dp: 0,
        tee_shot: 0,
        banker: 0,
        ob: 0,
        penalty: 0,
        memo: '',
        lock: false,
    }
    const [scoreP1, setScoreP1] = useState(initScore)
    const [scoreP2, setScoreP2] = useState(initScore)
    const [scoreP3, setScoreP3] = useState(initScore)
    const [scoreP4, setScoreP4] = useState(initScore)
    const [isOnLine, setIsOnLine] = useState({ u1: false, u2: false, u3: false, u4: false })
    /*
    const [scoreUpdate, setScoreUpdate] = useState(0);
    useEffect(() => {
        let n = 0
        const score_timer = setInterval(() => {
            // メンバに定期的に score を通知するため，timer で scoreUpdate を c'up する．
            // 通知の条件は, 個別入力モードで lock = false && stroke > 0
            //console.log("SCORE_TIMER: ", inputMode, inputUid, scoreP1.stroke, scoreP2.stroke, scoreP3.stroke, scoreP4.stroke)
            if (inputMode == config.INDIVISUAL_MODE) {
                if ((inputUid == 1 && scoreP1.lock == false && scoreP1.stroke > 0) ||
                    (inputUid == 2 && scoreP2.lock == false && scoreP2.stroke > 0) ||
                    (inputUid == 3 && scoreP3.lock == false && scoreP3.stroke > 0) ||
                    (inputUid == 4 && scoreP4.lock == false && scoreP4.stroke > 0)) {
                    n++
                    setScoreUpdate(c => c + 1)
                    console.log("UPDATE TIMER", n)
                }
            }
        }, 5000);
        return () => clearInterval(score_timer);
    }, [scoreP1.stroke, scoreP2.stroke, scoreP3.stroke, scoreP4.stroke,
    scoreP1.lock, scoreP2.lock, scoreP3.lock, scoreP4.lock])
    */
    var scoreP1tmp = {}
    var scoreP2tmp = {}
    var scoreP3tmp = {}
    var scoreP4tmp = {}

    //const [totalScore, setTotalScore] = useState([]);
    const [isLoadingScore, setIsLoadingScore] = useState(false)
    //const [isLoadingTtl, setIsLoadingTtl] = useState(false);
    const [shotScores, setShotScores] = useState([])
    const [isLoadingShot, setIsLoadingShot] = useState(false)
    // Party: member, input_mode, 1st_half, 2nd_half
    // Hole Data: hole_order, half_name, half_num
    //
    // Fetch All Scores
    //
    const scoresRef = useRef()
    scoresRef.current = shotScores
    const fetchShotScores = async () => {
        /*
            Hole の移動，resume 時に
            party=pid の score を全部取得
        */
        setIsLoadingShot(true)
        //const response = await fetch(API_ORIGIN + "/api/point/shot/" + pid)
        const response = await fetch(API_ORIGIN + '/api/score/' + pid)

        if (response.ok) {
            const scoresTmp = await response.json()
            if (scoresTmp.score != 'NoResult') {
                // -------------------------
                // party 全Hole の Score 保存
                // -------------------------
                setShotScores(scoresTmp)
                //******scoresRef.current = scoresTmp
                //console.log("Shot Scores :", totalTmp)

                // --------------------
                // curHole の Score 算出
                // --------------------
                // まずは，DB の data で置き換える
                // (これはまだ，自分の score  -> lock でなければそのまま?
                //  他者 & OffLine -> DB の score に置き換え
                //  他者 & OnLine -> update routine で置き換え
                //  --> Hole 移動すると update が出ないと合わない->  dispScores から取ってくる )
                scoreP1tmp = initScore
                scoreP2tmp = initScore
                scoreP3tmp = initScore
                scoreP4tmp = initScore

                console.log(
                    '<<@@ fetchShotScores: ',
                    scoresTmp.filter((v) => v.hole_order === curHole)
                )
                scoresTmp
                    .filter((v) => v.hole_order === curHole)
                    .map((sc, n) => {
                        //console.log("SCORE: sc: ", n, sc)
                        switch (sc.p_id) {
                            case 1:
                                scoreP1tmp = sc
                                break
                            case 2:
                                scoreP2tmp = sc
                                break
                            case 3:
                                scoreP3tmp = sc
                                break
                            case 4:
                                scoreP4tmp = sc
                                break
                            default:
                                console.log('SCORE: Switch Invalid p_id')
                        }
                    })
                setScoreP1(scoreP1tmp)
                setScoreP2(scoreP2tmp)
                setScoreP3(scoreP3tmp)
                setScoreP4(scoreP4tmp)
                sessionStorage.setItem('scoreP1tmp', JSON.stringify(scoreP1tmp))
                sessionStorage.setItem('scoreP2tmp', JSON.stringify(scoreP2tmp))
                sessionStorage.setItem('scoreP3tmp', JSON.stringify(scoreP3tmp))
                sessionStorage.setItem('scoreP4tmp', JSON.stringify(scoreP4tmp))
            } else console.log('Score Total: NoResult')

            if (inputMode == config.INDIVISUAL_MODE && inputUid != 0) {
                var msg = {
                    type: 'getscore',
                    uid: 'u' + inputUid,
                }
                console.log('>>>>>>>> SEND: getscore: ', msg)
                socketRef.current.send(JSON.stringify(msg))
            }
        }
        setIsLoadingShot(false)
    }
    useEffect(() => {
        fetchShotScores()
    }, [curHole, wakeupCount]) // 他にも必要なタイミングあるかも
    /*
    useEffect(() => {
        async function fetchScore() {
        // Hole 移動時に curHole の Score を DB から get する
        // Score が DB に無いときは初期値(initScore)を入れておく
        // Score は useState では即反映が出来ないケースがあり(?)，local storage に入れておく
        setIsLoadingScore(true)
        var scoreResp = {}
        const response = await fetch(`${scoreEndpoint}/${pid}/${curHole}`)
        if (response.ok) {
            scoreResp = await response.json()
            //console.log("SCORE: fetchScore: ", scoreResp)
            scoreP1tmp = initScore
            scoreP2tmp = initScore
            scoreP3tmp = initScore
            scoreP4tmp = initScore

            scoreResp.map((sc, n) => {
                //console.log("SCORE: sc: ", n, sc)
                switch (sc.p_id) {
                    case 1:
                        scoreP1tmp = sc;
                        break;
                    case 2:
                        scoreP2tmp = sc;
                        break;
                    case 3:
                        scoreP3tmp = sc;
                        break;
                    case 4:
                        scoreP4tmp = sc;
                        break;
                    default:
                        console.log("SCORE: Switch Invalid p_id")
                }
            })
            setScoreP1(scoreP1tmp);
            setScoreP2(scoreP2tmp);
            setScoreP3(scoreP3tmp);
            setScoreP4(scoreP4tmp);
            sessionStorage.setItem('scoreP1tmp', JSON.stringify(scoreP1tmp))
            sessionStorage.setItem('scoreP2tmp', JSON.stringify(scoreP2tmp))
            sessionStorage.setItem('scoreP3tmp', JSON.stringify(scoreP3tmp))
            sessionStorage.setItem('scoreP4tmp', JSON.stringify(scoreP4tmp))
            //console.log("Fetch OK : uid: ", uid, scoreResp)
        }
        setIsLoadingScore(false)
        //console.log("fetchScore SCOREPxTMP INIT:", scoreP1tmp)
    }
    fetchScore()
    setIsUpdate(false)              // 初期化を入れた : unlock 時のみ true
    //setModified({ uid1: false, uid2: false, uid3: false, uid4: false })
}, [curHole, inputUid])
*/
    /*
        Alert Dialog: Database Error
    */
    const [isOpenAltDB, setIsOpenAltDB] = React.useState(false)

    function DatabaseAlert(props) {
        const onClose = () => {
            setIsOpenAltDB(false)
            window.location.reload() // エラーリカバリで reload を試みる
        }
        const cancelRef = React.useRef()

        return (
            <AlertDialog isOpen={isOpenAltDB} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader color="red" fontSize="lg" fontWeight="bold">
                            Database Error
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Database の書き込みに失敗した可能性があります．DB から reload
                            しますので内容を確認ください．
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                OK{' '}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        )
    }
    /*
        Alert Dialog: Socket Connection Error
    const [isOpenAlt, setIsOpenAlt] = React.useState(false)

    function ConnAlert(props) {
        const onClose = () => setIsOpenAlt(false)
        const cancelRef = React.useRef()

        return (
            <AlertDialog
                isOpen={isOpenAlt}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader color="red" fontSize="lg" fontWeight="bold">
                            Socket Connection Error
                                    </AlertDialogHeader>
                        <AlertDialogBody>
                            Socket が開けません．通信状態が良いのであれば，すでに繋がっている 他の Tab がないか確認ください．
                                    </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                OK </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        )
    }
    */
    //
    //  Socket
    //
    //
    //    Update Msg Handler : triggered by WebSocket
    //
    function updateScore(curHole, hole, uid, stroke, st_delta, putt, dp = 0, ol = 0) {
        // updateScore:
        //   socket の update msg で受信したメンバーの Score は
        //   Local Stor のデータと merge し, ScorePx に set して rendering する．
        //   Nov.28,2021 : 外部の state(shotScores) がなぜか見えない．
        //                 scoresRef を代用にする．
        console.log('===== updateScore: ', scoresRef.current)

        if (curHole == hole) {
            switch (uid) {
                case '1':
                    // curHole の他者の score なら
                    // 現在の表示を入替える
                    scoreP1tmp = JSON.parse(sessionStorage.getItem('scoreP1tmp'))
                    scoreP1tmp = { stroke: stroke, st_delta: st_delta, putt: putt, dp: dp, ol: ol }
                    sessionStorage.setItem('scoreP1tmp', JSON.stringify(scoreP1tmp))
                    //console.log("setScore-----SCOREP1: ", scoreP1tmp);
                    setScoreP1(scoreP1tmp)
                    break
                case '2':
                    scoreP2tmp = JSON.parse(sessionStorage.getItem('scoreP2tmp'))
                    scoreP2tmp = { stroke: stroke, st_delta: st_delta, putt: putt, dp: dp, ol: ol }
                    sessionStorage.setItem('scoreP2tmp', JSON.stringify(scoreP2tmp))
                    //console.log("setScore-----SCOREP2: ", scoreP2tmp);
                    setScoreP2(scoreP2tmp)
                    break
                case '3':
                    scoreP3tmp = JSON.parse(sessionStorage.getItem('scoreP3tmp'))
                    scoreP3tmp = { stroke: stroke, st_delta: st_delta, putt: putt, dp: dp, ol: ol }
                    sessionStorage.setItem('scoreP3tmp', JSON.stringify(scoreP3tmp))
                    //console.log("setScore-----SCOREP3: ", scoreP3tmp);
                    setScoreP3(scoreP3tmp)
                    break
                case '4':
                    scoreP4tmp = JSON.parse(sessionStorage.getItem('scoreP4tmp'))
                    scoreP4tmp = { stroke: stroke, st_delta: st_delta, putt: putt, dp: dp, ol: ol }
                    sessionStorage.setItem('scoreP4tmp', JSON.stringify(scoreP4tmp))
                    //console.log("setScore-----SCOREP4: ", scoreP4tmp);
                    setScoreP4(scoreP4tmp)
                    break
            }
        } else {
            // curHole ではない score で OffLine なら
            // shotScores を map して，uid, hole が一致しているものを入れ替える
            // shotScores の cp を取る
            let shotScoresTmp = []
            let match = 0
            console.log('Update: uid: ' + uid + ', hole: ' + hole)
            scoresRef.current.map((sc, n) => {
                //console.log("SC: p_id: " + sc.p_id + ", sc.hole_order: " + sc.hole_order)
                if (sc.p_id == uid && sc.hole_order == hole) {
                    // sc を新規の st に入れ替える
                    sc.stroke = stroke
                    sc.putt = putt
                    sc.st_delta = st_delta
                    sc.dp = dp
                    sc.ol = ol
                    match++
                    console.log('scoreChange: ', sc, n, match)
                }
                shotScoresTmp[n] = sc
            })
            if (match == 0) {
                // 新規score なら追加
                console.log('scoreAdded ')
                shotScoresTmp.push({
                    p_id: uid,
                    hole_order: hole,
                    stroke: stroke,
                    st_delta: st_delta,
                    putt: putt,
                    dp: dp,
                    ol: ol,
                })
            }
            console.log('UPDATE: shotScoresTmp: ', shotScoresTmp)
            // shotScores に新しい値を入れる
            setShotScores(shotScoresTmp)
            //scoresRef.current = shotScoresTmp
        }
    }
    //
    //  WebSocket Manager
    //
    const socketRef = useRef()
    useEffect(() => {
        if (inputMode == config.INDIVISUAL_MODE && inputUid != 0) {
            console.log('>>>>>>>>>>>>>>>>>>>>>>>> Connecting...')
            //socketRef.current = new WebSocket(WS_ORIGIN + pid + '/' + inputUid)
            socketRef.current = new ReconnectingWebSocket(
                WS_ORIGIN + 'r' + rid + '/p' + pid + '/u' + inputUid,
                null,
                { debug: true }
            )

            socketRef.current.onopen = (e) => {
                console.log('---------- CONNECTED ----')

                if (document.hidden == false) {
                    // I'm In
                    var msg = { type: 'showup', uid: 'u' + inputUid, place: '0' }
                    console.log('>>>>>>>> SEND: SHOWUP(0): ', msg)
                    // P-on時，P-off の間に入力された score update のためのトリガ
                    // DB から curHole 以外の score を取得，+ curHole Score で TTL を算出
                    console.log('xxxxxxxxxx fetchShotScores Trig: (0)')
                    setWakeupCount((c) => c + 1) // useEffect で fetchShotScores() する
                } else {
                    // tab が隠れると，ShowDown DISCON となるが，すぐに再接続するので，
                    // このときには fetchShotScore しない Dec.11,2021
                    var msg = { type: 'hiddenup', uid: 'u' + inputUid }
                    console.log('>>>>>>>> SEND: hiddenup(0): ', msg)
                }
                socketRef.current.send(JSON.stringify(msg))
            }

            socketRef.current.onerror = (error) => {
                console.log('SOCKET ERROR: ', error)
                //setIsOpenAlt(true)
            }

            socketRef.current.onmessage = (e) => {
                var msg = JSON.parse(e.data)
                console.log('<<<<<<<< WS RECEIVE MSG : ', msg)

                // msg format
                // {type: , uid: , hole: , stroke:, putt: , dp: , ol:  }
                const cHole = sessionStorage.getItem('curHole') ?? 1
                switch (msg.type) {
                    case 'hiddenup': // p-on someone else
                        console.log('<<< Rcv: Hidden Up...', isOnLine)
                        break
                    case 'online':
                        // return my join : inform me curr connected usr
                        // 誰かが showup したので，isOnLine が更新されると，useEffect を使って，update を発行，自分の Score を通知する
                        var newOnLine = { u1: false, u2: false, u3: false, u4: false }
                        msg.isOnLine.map((p) => {
                            newOnLine[p] = true
                        })
                        console.log('<<< Rcv: OnLine. Cur Sts: ', newOnLine)
                        setIsOnLine(newOnLine) // OnLine Flag
                        break
                    case 'showdown':
                        // 誰かが left したら，OnLine を Update
                        console.log('<<< Rcv: ShowDown / Left...')
                        setIsOnLine((state) => ({ ...state, [msg.uid]: false }))
                        break
                    case 'left': // OBSOLETE
                        // 誰かが left したら，OnLine を Update
                        console.log('<<< Rcv: Left...')
                        setIsOnLine((state) => ({ ...state, [msg.uid]: false }))
                        break
                    case 'update':
                        // 他人のスコアを受けたらUpdate
                        if (inputUid != msg.uid) {
                            console.log(
                                '<<< Rcv: UPDATE Score : uid: ' + msg.uid + ' hole: ' + msg.hole
                            )
                            updateScore(
                                cHole,
                                msg.hole,
                                msg.uid,
                                msg.stroke,
                                msg.st_delta,
                                msg.putt,
                                msg.dp,
                                msg.ol
                            )
                        }
                        break
                    case 'dispscores':
                        // 各端末の最新表示データを取得
                        console.log(
                            '<<< Rcv: DispScores UPDATE : scores: ' + Object.keys(msg.scores)
                        )
                        for (let key in msg.scores) {
                            if (key !== 'u' + inputUid)
                                // 自分の表示データは不要なので skip．それ以外が対象
                                switch (key) {
                                    case 'u1':
                                        updateScore(
                                            cHole,
                                            msg.scores.u1.hole,
                                            '1',
                                            msg.scores.u1.stroke,
                                            msg.scores.u1.st_delta,
                                            msg.scores.u1.putt,
                                            msg.scores.u1.dp,
                                            msg.scores.u1.ol
                                        )
                                        break
                                    case 'u2':
                                        updateScore(
                                            cHole,
                                            msg.scores.u2.hole,
                                            '2',
                                            msg.scores.u2.stroke,
                                            msg.scores.u2.st_delta,
                                            msg.scores.u2.putt,
                                            msg.scores.u2.dp,
                                            msg.scores.u2.ol
                                        )
                                        break
                                    case 'u3':
                                        updateScore(
                                            cHole,
                                            msg.scores.u3.hole,
                                            '3',
                                            msg.scores.u3.stroke,
                                            msg.scores.u3.st_delta,
                                            msg.scores.u3.putt,
                                            msg.scores.u3.dp,
                                            msg.scores.u3.ol
                                        )
                                        break
                                    case 'u4':
                                        updateScore(
                                            cHole,
                                            msg.scores.u4.hole,
                                            '4',
                                            msg.scores.u4.stroke,
                                            msg.scores.u4.st_delta,
                                            msg.scores.u4.putt,
                                            msg.scores.u4.dp,
                                            msg.scores.u4.ol
                                        )
                                        break
                                }
                        }
                        //updateScore(cHole, msg.hole, msg.uid, msg.stroke, msg.st_delta, msg.putt, msg.dp, msg.ol);
                        break
                    /*
                    case "reqUpdate": // curHole をつけて Req Update Total
                        console.log('>>>>>>>>>>>>>>>>>>>>>>>> UPDATE Total....', msg.hole, msg.uid);
                        // 現在表示中Hole 以外の他人 Request を受けたら Total を Update
                        break;
                    case "chat":   // Message to ALL Members
                        console.log('>>>>>>>>>>>>>>>>>>>>>>>> Message ....', msg.msg);
                        // 現在表示中Hole 以外の他人 Request を受けたら Total を Update
                        break;
                    */
                }
            }
        }
        return () => {
            if (inputMode == config.INDIVISUAL_MODE && inputUid != 0) {
                var msg = {
                    type: 'exit',
                    uid: 'u' + inputUid,
                }
                console.log('>>>>>>>> SEND: Exit/DISCONN & ws Close : input_mode: ', inputMode)
                socketRef.current.send(JSON.stringify(msg))
                socketRef.current.close() // Going Away
            }
        }
    }, []) // load時に ws を open する． wakeup 時は要検討
    /*
    useEffect(() => {
        console.log('*** isOnLine STS: ', isOnLine)
    }, [isOnLine])
    */
    //
    // Get Party & Course Data
    //
    useEffect(() => {
        async function fetchPartyData() {
            setIsLoading(true)
            const response = await fetch(API_ORIGIN + '/api/party_plus/' + pid + '/' + course.id)
            const [pdata, cdata] = await response.json()
            setPartyData(pdata)
            //setInputMode(pdata.input_mode)
            setCourseData(cdata)
            //console.log("Score.js CDATA :", cdata)  // cdata は順番が保証されている
            console.log('<<@@ fetchPartyData: ', pdata, cdata)
            setIsLoading(false)
        }
        fetchPartyData()
    }, [])

    useEffect(() => {
        console.log('HOLE CHANGE----------------------curHole :', curHole)
        sessionStorage.setItem('curHole', curHole)
        // Hole 変更時 isUpdate=false (Post mode) だが，Lock 解除で PUT mode になる．
        setIsUpdate(false)
    }, [curHole])

    useEffect(() => {
        // 個別入力モード時の 他の端末への Score Update 通知
        // - 自分のスコアの入力の Update を socket でメンバに通知する．
        // - 他の端末が立ち上がると OnLine の状態が変わるので，これをトリガに
        //   自分のデータを立ち上がった端末に通知する．
        if (inputMode == config.INDIVISUAL_MODE && inputUid == '1') {
            let wsState = socketRef.current.readyState
            if (
                wsState == config.WS_OPEN &&
                !(scoreP1.stroke == 0 && scoreP1.putt == 0 && scoreP1.dp == 0 && scoreP1.ol == 0)
            ) {
                var msg = {
                    type: 'update',
                    hole: curHole,
                    uid: inputUid,
                    stroke: scoreP1.stroke,
                    st_delta: scoreP1.st_delta,
                    putt: scoreP1.putt,
                    dp: scoreP1.dp,
                    ol: scoreP1.ol,
                }
                console.log('>>>>>>>> SEND: UPDATE u1: ', msg)
                socketRef.current.send(JSON.stringify(msg))
            } else {
                console.log('Score: WS status@scoreP1: ', wsState)
            }
        }
    }, [scoreP1])
    useEffect(() => {
        if (inputMode == config.INDIVISUAL_MODE && inputUid == '2') {
            let wsState = socketRef.current.readyState
            if (
                wsState == config.WS_OPEN &&
                !(scoreP2.stroke == 0 && scoreP2.putt == 0 && scoreP2.dp == 0 && scoreP2.ol == 0)
            ) {
                var msg = {
                    type: 'update',
                    hole: curHole,
                    uid: inputUid,
                    stroke: scoreP2.stroke,
                    st_delta: scoreP2.st_delta,
                    putt: scoreP2.putt,
                    dp: scoreP2.dp,
                    ol: scoreP2.ol,
                }
                console.log('>>>>>>>> SEND: UPDATE u2: ', msg)
                socketRef.current.send(JSON.stringify(msg))
            } else {
                console.log('Score: WS status@scoreP2: ', wsState)
            }
        }
    }, [scoreP2])
    useEffect(() => {
        if (inputMode == config.INDIVISUAL_MODE && inputUid == '3') {
            let wsState = socketRef.current.readyState
            if (
                wsState == config.WS_OPEN &&
                !(scoreP3.stroke == 0 && scoreP3.putt == 0 && scoreP3.dp == 0 && scoreP3.ol == 0)
            ) {
                var msg = {
                    type: 'update',
                    hole: curHole,
                    uid: inputUid,
                    stroke: scoreP3.stroke,
                    st_delta: scoreP3.st_delta,
                    putt: scoreP3.putt,
                    dp: scoreP3.dp,
                    ol: scoreP3.ol,
                }
                console.log('>>>>>>>> SEND: UPDATE u3: ', msg)
                socketRef.current.send(JSON.stringify(msg))
            } else {
                console.log('Score: WS status@scoreP3: ', wsState)
            }
        }
    }, [scoreP3])
    useEffect(() => {
        if (inputMode == config.INDIVISUAL_MODE && inputUid == '4') {
            let wsState = socketRef.current.readyState
            if (
                wsState == config.WS_OPEN &&
                !(scoreP4.stroke == 0 && scoreP4.putt == 0 && scoreP4.dp == 0 && scoreP4.ol == 0)
            ) {
                var msg = {
                    type: 'update',
                    hole: curHole,
                    uid: inputUid,
                    stroke: scoreP4.stroke,
                    st_delta: scoreP4.st_delta,
                    putt: scoreP4.putt,
                    dp: scoreP4.dp,
                    ol: scoreP4.ol,
                }
                console.log('>>>>>>>> SEND: UPDATE u4: ', msg)
                socketRef.current.send(JSON.stringify(msg))
            } else {
                console.log('Score: WS status@scoreP4: ', wsState)
            }
        }
    }, [scoreP4])

    const handleSubmit = async (e) => {
        /*
            Submit SCORE : POST/PUT
        */
        // param は data-xx で渡せる
        // var uid =  Number(e.currentTarget.getAttribute('data-uid'))
        var scorePost = []
        if (inputMode == config.VOLUNTEER_MODE || inputUid == '1') {
            scorePost.push({
                ...scoreP1,
                hole_order: curHole,
                half_name: courseData ? courseData[curHole - 1].half_name : '',
                hole_num: courseData ? courseData[curHole - 1].hole_num : '',
                guid: partyData.player1_guid,
                p_id: partyData.player1_id,
                p_name: partyData.player1_name,
            })
            //setScoreP1({ ...scoreP1, lock: true })   // 自 Hole を選んだときにはこれで Write Protect する
        }
        if (inputMode == config.VOLUNTEER_MODE || inputUid == '2') {
            scorePost.push({
                ...scoreP2,
                hole_order: curHole,
                half_name: courseData ? courseData[curHole - 1].half_name : '',
                hole_num: courseData ? courseData[curHole - 1].hole_num : '',
                guid: partyData.player2_guid,
                p_id: partyData.player2_id,
                p_name: partyData.player2_name,
            })
            //setScoreP2({ ...scoreP2, lock: true })
        }
        if (inputMode == config.VOLUNTEER_MODE || inputUid == '3') {
            scorePost.push({
                ...scoreP3,
                hole_order: curHole,
                half_name: courseData ? courseData[curHole - 1].half_name : '',
                hole_num: courseData ? courseData[curHole - 1].hole_num : '',
                guid: partyData.player3_guid,
                p_id: partyData.player3_id,
                p_name: partyData.player3_name,
            })
            //setScoreP3({ ...scoreP3, lock: true })
        }
        if (inputMode == config.VOLUNTEER_MODE || inputUid == '4') {
            scorePost.push({
                ...scoreP4,
                hole_order: curHole,
                half_name: courseData ? courseData[curHole - 1].half_name : '',
                hole_num: courseData ? courseData[curHole - 1].hole_num : '',
                guid: partyData.player4_guid,
                p_id: partyData.player4_id,
                p_name: partyData.player4_name,
            })
            //setScoreP4({ ...scoreP4, lock: true })
        }
        //console.log("SCORE: POST :", scorePost)
        //console.log("SCORE: JSON  :", JSON.stringify({ scores: scorePost } )  )

        try {
            const response = await fetch(API_ORIGIN + '/api/score', {
                // method: (isUpdate == false) ? "POST" : "PUT",
                method: 'PUT', // Upsert に変更 Nov.08,2022
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ scores: scorePost }),
            })
            /*if (inputMode == config.VOLUNTEER_MODE || inputUid == "1") {
                // 自 Hole を選んだときにはこれで Write Protect する
                setScoreP1({ ...scoreP1, lock: true })
            } else if (inputMode == config.VOLUNTEER_MODE || inputUid == "2") {
                setScoreP2({ ...scoreP2, lock: true })
            } else if (inputMode == config.VOLUNTEER_MODE || inputUid == "3") {
                setScoreP3({ ...scoreP3, lock: true })
            } else if (inputMode == config.VOLUNTEER_MODE || inputUid == "4") {
                setScoreP4({ ...scoreP4, lock: true })
            }*/
            const jsonResponse = await response.json()
            console.log('>> @@ POST/PUT Response: ', jsonResponse)
        } catch (err) {
            // fetch と response.json 両方のエラーを catch
            //respCode = response.status
            //respText = response.statusText
            //setScoreP1({ ...scoreP1, lock: false })
            //setScoreP2({ ...scoreP2, lock: false })
            //setScoreP3({ ...scoreP3, lock: false })
            //setScoreP4({ ...scoreP4, lock: false })
            // Error時には lock = false として，UnLockに戻す
            //  ---> Err時でもかけていることがあるので，DB から Reload してもらうのを正解とする
            setIsOpenAltDB(true) // Alert 表示
            console.log('POST/PUT Error Response: ', err)
        }
    }

    return (
        <>
            {/* inputUid は session storage に入っている．無いときには Drawer で入力
                (inputUid == null || myParty != pid) ?

                <DrawerInputSettings toOpen={(inputUid == null || myParty != pid )} inputMode={inputMode} setInputMode={setInputMode}
                        inputUid={inputUid} setInputUid={setInputUid} partyData={partyData} /> :
            */}

            {isLoading || isLoadingScore || isLoadingShot ? (
                <Spinner />
            ) : (
                <PartyContext.Provider
                    value={{
                        inputMode,
                        inputUid,
                        playMode,
                        plusMinusMode,
                        setPlusMinusMode,
                        parties,
                        isOnLine,
                        partyData,
                        curHole,
                        setCurHole,
                        colorZone1,
                        colorZone2,
                        colorZone3,
                        isUpdate,
                        setIsUpdate,
                        shotScores,
                        scoreP1,
                        setScoreP1,
                        scoreP2,
                        setScoreP2,
                        scoreP3,
                        setScoreP3,
                        scoreP4,
                        setScoreP4,
                        course,
                        courseData,
                        handleSubmit,
                    }}
                >
                    <ScoreBoard />
                    <DatabaseAlert />
                </PartyContext.Provider>
            )}
        </>
    )
}
