/*

Issues.js :

Oct.12,2023 : Back Btn 追加

*/
import React from 'react'
import ReactMarkdown from 'react-markdown'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer'
import { Stack, Button, Flex, Spacer } from '@chakra-ui/react'

const markdown = `
### Known Issues / Nov.12,2022: Update

#### 対応計画中


#### 対応予定なし

- Download 機能: iPhone Chrome では NG だが，iPhone Safari なら DL の Dialog Box が出て file を落とせる．Chrome 側の問題と思われるので保留．
- iOS/Chrome の Bug で，画面を横ー>縦で表示幅が狭くなる．
  - Work Around: アプリ使用中はスマホの回転ロックをしてください
- Multi Process 未対応のため，docker/gunicorn環境で Workerを増やすと websocket が誤動作する．max_worker=1 にすること．

#### 解決済み(多分)

- Nov.05,2022 : 2重登録が再発生.原因不明. Nov.12,2022 : Upsert に変更.もう出ないはず.
- Nov.05,2022 : 2重登録の削除機能が動かない.帰宅後再現せず.restart したせいか? 原因不明.
- POST/PUT が正しく出ないことがある．ロジック見直し．11/5 再発
- 個別入力モードで，Sleep -> resume したときに，reload できない browser がある．(iPhone//)
`
export default function Issues(props) {
    return (
        <Flex>
            <Stack spacing={3} m={5}>
                <ReactMarkdown
                    components={ChakraUIRenderer()}
                    children={markdown}
                    escapeHtml={false}
                />
            </Stack>
            <Spacer />
            <Button m="14" p="5" w="30" colorScheme="teal" onClick={() => window.history.back()}>
                Back
            </Button>
        </Flex>
    )
}
