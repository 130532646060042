/*
    Create New Round
        /Point/:rid
        state:_dt, first_half, second_hal

        Mar.18,2024 : ODDT 使用してないので削除,BRKR タイは 1p の説明追加
        Mar.18,2024 : Rule6:St6 の表示変更(2ヶ所) st -> std
        Feb.27,2024 : Miss を追加
        Feb.21,2024 : BrkR を追加
        Dec.13,2023 : On1 3on = 2p に格上げ，DefaultRules に NetT を追加
        Oct.16,2023 :   indivisual table Hole 2行目の default を st,sd,pt に変更
        Oct.12,2023 :   BoxTHRU と Presen を Presen に統合した
        Oct.08,2023 :   HxH の default 表示から SxS を false とした
        Jul.21,2023 :   fontSize を大きくした．個人/Team Table での 名前 Cell の colspan を算出.
        Nov.12,2022 :   Back Btn 形状を変更.
        Nov.10,2022 :   Back を history back に変更.
                        default rule を変更.HxHH fal se など
                        個人/Team/Pty/THRU Tbl で name="" は非表示に変更
        Oct.10,2022 : Info -> Point Icon に変更
        Sep.28,2022 : Team tab に隠しBtnを設定
        Sep.27,2022 : THRU tab は現行Rule での全体を表示するのみに変更(checkedRules を変更しない)
                        Tab を開いたときの default 表示を，個人は st,ptのみ，Team は HxH に変更
        Sep.26,2022 : 最初描画で sessionStorage の TodaysRules が初期設定が無かった．
        Jul.21,2022 : DrawerPointSettings -> DrawerRegulationSettings
                        DefaultRules の変更
                        checkedRules を sessionStorage に TodaysRules とし保存
        Jul.19,2022 : 表示変更: BiP -> BKiP
        May.28,2022 : memo 表示を個人tbl に追加．Par Lbl clik での表示Reset を表示All に変更
        Dec.14,2021 : Presen と共通に使用するため，fetch data の計算部分を calcPoint.js に関数化
        Dec.12,2021 : Table 文字の大きさ調整
                        表示カスタムボタン埋込み
        Dec.08,2021 : プレゼン Mode 追加
                        SD Only 表示を可能に変更
        Dec.07,2021 : 表示カスタマイズに機能追加．
        Dec.06,2021 : Nav fixed 対応
                        Score を ◯△表示に強化
                        Nav Button を icon に変更
                        個人 Hole を click で Pty#0 へ, Par を Click で Pty#1 の Score へ移動
                        iPhone/Chrome では table 2行目の zIndex が効かない bug があるようだ．
        Dec.02,2021 :   環境変数を config から .env に変更
        Aug.            input_mode を reen から取得するに変更
        Nov.14,2021 : typo 対応
        Nov.12,2021 : DP パー縛り 2p，NP 消ゴムあり対応
        Jun.27,2021 : BiP のパラメータ Typo fix (s.bk -> s.banker)
        Jun.25,2021 : fetch dataが NoResult 時に setIsLoading を false  0 data のテーブルを書くようにした．
        May.17,2021 : str が null or "" の判定は !str で良い．
        May.10,2021 : memo 表示機能を pty tab に追加した．
        Apr.27,2021 : updateVal でようやく強制的に rendering を Update できるようになった．
        Apr.22,2021 : PoP: 3人の party 時に St = 0 に反応するので St > 0 の条件を追加．
        Apr.21,2021 : 1 party 表示対応．修正したいHoleへjump可とした.
        Apr.20,2021 : Table に "" は NG． ?? ではなく，&& に変更．
        Apr.18,2021 : PoP は w/o Short Hole に修正, team 個人の St 合計 Typo
        Apr.13,2021 : 前半後半の data slice のパラメータミスで 9,18 hole目が抜けいた bug fix
        Apr.10,2021 : API_ORIGIN を config に
        Apr.10,2021 : Point計算で 未入力 0 が min で勝利となるのを除外した.Table align center に
        Apr.05,2021 : OnP 仕様ミス修正, Regulation Drawer 文字圧縮, THRU header zIndex bug fix,
        Apr.04,2021 : On1 Flag Drawer から抜けていたのを fix
*/
import React, { useState, useEffect } from 'react'
import {
    Box,
    Button,
    Checkbox,
    Text,
    Flex,
    IconButton,
    Spacer,
    Stack,
    Spinner,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Tooltip,
    Image,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    useDisclosure,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import { useParams, useLocation, Link } from 'react-router-dom'
import {
    ArrowBackIcon,
    CheckIcon,
    SettingsIcon,
    StarIcon,
    ViewIcon,
    InfoIcon,
} from '@chakra-ui/icons'

import config from './const'
import calcPoint from './calcPoint'

function DrawerDispSettings(props) {
    // 個人Tab 時の DispSettings
    //
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    return (
        <>
            <IconButton
                h={6}
                w={12}
                ref={btnRef}
                color="orange.600"
                onClick={onOpen}
                icon={<ViewIcon />}
            />
            <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef}>
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Display Settings</DrawerHeader>

                        <DrawerBody>
                            <Stack spacing={1} direction="column">
                                <Text fontSize="lg" color="green.600">
                                    各Hole 個人
                                </Text>
                                <Checkbox
                                    defaultIsChecked
                                    value="st"
                                    isChecked={props.checkedItems.st}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            st: e.target.checked,
                                        })
                                    }
                                >
                                    ST
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="sd"
                                    isChecked={props.checkedItems.sd}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            sd: e.target.checked,
                                        })
                                    }
                                >
                                    SD
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="pt"
                                    isChecked={props.checkedItems.pt}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            pt: e.target.checked,
                                        })
                                    }
                                >
                                    PT
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="ol"
                                    colorScheme="green"
                                    isChecked={props.checkedItems.ol}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            ol: e.target.checked,
                                        })
                                    }
                                >
                                    OL
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="dp"
                                    colorScheme="green"
                                    isChecked={props.checkedItems.dp}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            dp: e.target.checked,
                                        })
                                    }
                                >
                                    DP/NP
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="fw"
                                    colorScheme="green"
                                    isChecked={props.checkedItems.fw}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            fw: e.target.checked,
                                        })
                                    }
                                >
                                    FW
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="bk"
                                    colorScheme="green"
                                    isChecked={props.checkedItems.bk}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            bk: e.target.checked,
                                        })
                                    }
                                >
                                    Banker
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="ob"
                                    colorScheme="green"
                                    isChecked={props.checkedItems.ob}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            ob: e.target.checked,
                                        })
                                    }
                                >
                                    OB
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="pe"
                                    colorScheme="green"
                                    isChecked={props.checkedItems.pe}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            pe: e.target.checked,
                                        })
                                    }
                                >
                                    Penalty
                                </Checkbox>

                                <Text fontSize="lg" color="green.600">
                                    各Hole Team
                                </Text>
                                <Checkbox
                                    defaultIsChecked
                                    value="SxS"
                                    colorScheme="green"
                                    isChecked={props.checkedItems.SxS}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            SxS: e.target.checked,
                                        })
                                    }
                                >
                                    SxS: St x St
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="SDpSD"
                                    colorScheme="green"
                                    isChecked={props.checkedItems.SDpSD}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            SDpSD: e.target.checked,
                                        })
                                    }
                                >
                                    SDpSD: Std + Std
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="PpP"
                                    colorScheme="green"
                                    isChecked={props.checkedItems.PpP}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            PpP: e.target.checked,
                                        })
                                    }
                                >
                                    PpP: Pt + Pt
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="OLt"
                                    colorScheme="green"
                                    isChecked={props.checkedItems.OLt}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            OLt: e.target.checked,
                                        })
                                    }
                                >
                                    OLt
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="PoP"
                                    isChecked={props.checkedItems.PoP}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            PoP: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    PoP: パーオンパー
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="BiP"
                                    isChecked={props.checkedItems.BiP}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            BiP: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    BKiP: バンカーインパー
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="BDy"
                                    isChecked={props.checkedItems.BDy}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            BDy: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    BDy: バーディ
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="DPt"
                                    isChecked={props.checkedItems.DPt}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            DPt: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    DPt: ドラコンポイント
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="NPt"
                                    isChecked={props.checkedItems.NPt}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            NPt: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    NPt: ニアピンポイント
                                </Checkbox>
                                {/* <Checkbox defaultIsChecked value="On1" isChecked={props.checkedItems.On1} onChange={(e) => props.setCheckedItems({ ...props.checkedItems, On1: e.target.checked })} size="sm">On1: ワンオン</Checkbox> */}

                                <Text fontSize="lg" color="green.600">
                                    各Party
                                </Text>
                                <Checkbox
                                    defaultIsChecked
                                    value="Ho3"
                                    isChecked={props.checkedItems.Ho3}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            Ho3: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    Ho3: Honer/Hole勝利
                                </Checkbox>

                                <Text fontSize="lg" color="green.600">
                                    各Hole 個人対抗(誰と？？)
                                </Text>
                                <Checkbox
                                    isDisabled
                                    defaultIsChecked
                                    value="StD"
                                    isChecked={props.checkedItems.StD}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            StD: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    StD: 個人 St 差 3以上
                                </Checkbox>
                                <Checkbox
                                    isDisabled
                                    defaultIsChecked
                                    value="PtD"
                                    isChecked={props.checkedItems.PtD}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            PtD: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    PtD: 個人 Pt 差 3以上
                                </Checkbox>

                                <Text fontSize="lg" color="green.600">
                                    各Hole ペア対抗
                                </Text>
                                <Checkbox
                                    defaultIsChecked
                                    value="HxH"
                                    isChecked={props.checkedItems.HxH}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            HxH: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    HxH: Hole x Hole(掛算)
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="St6"
                                    isChecked={props.checkedItems.St6}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            St6: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    St6: Rule 6/std over 6)
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="Pt6"
                                    isChecked={props.checkedItems.Pt6}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            Pt6: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    Pt6: Rule 6 (putt)
                                </Checkbox>

                                <Text fontSize="lg" color="green.600">
                                    Through 個人
                                </Text>
                                <Checkbox
                                    defaultIsChecked
                                    value="On1"
                                    isChecked={props.checkedItems.On1}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            On1: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    On1: One On
                                </Checkbox>

                                <Text fontSize="lg" color="green.600">
                                    Half or Through Team対抗
                                </Text>
                                <Checkbox
                                    defaultIsChecked
                                    value="OLt"
                                    isChecked={props.checkedItems.OLt}
                                    onChange={(e) =>
                                        props.setCheckedItems({
                                            ...props.checkedItems,
                                            OLt: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    OLt: オリンピック(縛りあり)
                                </Checkbox>
                            </Stack>
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    )
}
function DrawerDispQuickSettings0({ checkedItems, setCheckedItems }) {
    //
    // 個人Tab時 の QuickSettings
    //
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    return (
        <>
            <IconButton
                h={6}
                w={12}
                ref={btnRef}
                color="red.700"
                onClick={onOpen}
                icon={<StarIcon />}
            />
            <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef}>
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Display Quick Settings</DrawerHeader>

                        <DrawerBody>
                            <Stack spacing={1} direction="column">
                                <Text fontSize="sm" color="gray.600">
                                    各 Tab で目的の表示を 1Click で表示します．
                                </Text>
                                <Text fontSize="lg" color="green.600">
                                    個人 Tab
                                </Text>
                                <Flex>
                                    <IconButton
                                        h={6}
                                        w={12}
                                        ref={btnRef}
                                        color="gray.700"
                                        icon={<CheckIcon />}
                                        onClick={(e) => {
                                            setCheckedItems({
                                                ...checkedItems,
                                                st: true,
                                                sd: false,
                                                pt: true,
                                                ol: false,
                                                dp: false,
                                                fw: false,
                                                ob: false,
                                                bk: false,
                                                pe: false,
                                                ho: false,
                                                SxS: false,
                                                SDpSD: false,
                                                PpP: false,
                                                PoP: false,
                                                BiP: false,
                                                BDy: false,
                                                DPt: false,
                                                NPt: false,
                                                Ho3: false,
                                                StD: false,
                                                PtD: false,
                                                On1: false,
                                                HxH: false,
                                                Pt6: false,
                                                St6: false,
                                                OLt: false,
                                            })
                                            onClose()
                                        }}
                                    />
                                    <Text>Stroke/Putt のみ一覧</Text>
                                </Flex>
                                <Flex>
                                    <IconButton
                                        h={6}
                                        w={12}
                                        ref={btnRef}
                                        color="gray.700"
                                        icon={<CheckIcon />}
                                        onClick={(e) => {
                                            setCheckedItems({
                                                ...checkedItems,
                                                st: false,
                                                sd: true,
                                                pt: false,
                                                ol: false,
                                                dp: false,
                                                fw: false,
                                                ob: false,
                                                bk: false,
                                                pe: false,
                                                ho: false,
                                                SxS: false,
                                                SDpSD: true,
                                                PpP: true,
                                                PoP: true,
                                                BiP: true,
                                                BDy: true,
                                                DPt: false,
                                                NPt: false,
                                                Ho3: false,
                                                StD: false,
                                                PtD: false,
                                                On1: false,
                                                HxH: false,
                                                Pt6: false,
                                                St6: false,
                                                OLt: false,
                                            })
                                            onClose()
                                        }}
                                    />
                                    <Text>Par On Par, Banker in Par, Birdy</Text>
                                </Flex>
                                <Flex>
                                    <IconButton
                                        h={6}
                                        w={12}
                                        ref={btnRef}
                                        color="gray.700"
                                        icon={<CheckIcon />}
                                        onClick={(e) => {
                                            setCheckedItems({
                                                ...checkedItems,
                                                st: true,
                                                sd: true,
                                                pt: false,
                                                ol: false,
                                                dp: true,
                                                fw: false,
                                                ob: false,
                                                bk: false,
                                                pe: false,
                                                ho: false,
                                                SxS: false,
                                                SDpSD: false,
                                                PpP: false,
                                                PoP: false,
                                                BiP: false,
                                                BDy: false,
                                                DPt: true,
                                                NPt: true,
                                                Ho3: false,
                                                StD: false,
                                                PtD: false,
                                                On1: true,
                                                HxH: false,
                                                Pt6: false,
                                                St6: false,
                                                OLt: false,
                                            })
                                            onClose()
                                        }}
                                    />
                                    <Text>DP, NP, 1-On</Text>
                                </Flex>

                                <Box>
                                    <Text fontSize="lg" color="red.500">
                                        表示設定Reset
                                    </Text>
                                    <Button
                                        colorScheme="teal"
                                        onClick={(e) => {
                                            setCheckedItems({
                                                ...checkedItems,
                                                st: true,
                                                sd: true,
                                                pt: true,
                                                ol: true,
                                                dp: true,
                                                fw: false,
                                                ob: false,
                                                bk: false,
                                                pe: false,
                                                ho: false,
                                                SxS: true,
                                                SDpSD: true,
                                                PpP: true,
                                                PoP: true,
                                                BiP: true,
                                                BDy: true,
                                                DPt: true,
                                                NPt: true,
                                                Ho3: true,
                                                StD: true,
                                                PtD: true,
                                                On1: true,
                                                HxH: true,
                                                Pt6: true,
                                                St6: true,
                                                OLt: true,
                                            })
                                            onClose()
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </Box>
                            </Stack>
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    )
}
function DrawerDispQuickSettings1({ checkedItems, setCheckedItems }) {
    //
    // TEAM Tab の QuickSettings
    //
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    return (
        <>
            <IconButton
                h={6}
                w={12}
                ref={btnRef}
                color="red.700"
                onClick={onOpen}
                icon={<StarIcon />}
            />
            <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef}>
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Display quick Settings</DrawerHeader>

                        <DrawerBody>
                            <Stack spacing={1} direction="column">
                                <Text fontSize="sm" color="gray.600">
                                    各 Tab で目的の表示を 1Click で表示します．
                                </Text>

                                <Text fontSize="lg" color="green.600">
                                    Team Tab
                                </Text>
                                <Flex>
                                    <IconButton
                                        h={6}
                                        w={12}
                                        ref={btnRef}
                                        color="gray.700"
                                        icon={<CheckIcon />}
                                        onClick={(e) => {
                                            setCheckedItems({
                                                ...checkedItems,
                                                st: true,
                                                sd: false,
                                                pt: false,
                                                ol: false,
                                                dp: false,
                                                fw: false,
                                                ob: false,
                                                bk: false,
                                                pe: false,
                                                ho: false,
                                                SxS: false,
                                                SDpSD: false,
                                                PpP: false,
                                                PoP: false,
                                                BiP: false,
                                                BDy: false,
                                                DPt: false,
                                                NPt: false,
                                                Ho3: false,
                                                StD: false,
                                                PtD: false,
                                                On1: false,
                                                HxH: true,
                                                Pt6: false,
                                                St6: false,
                                                OLt: false,
                                            })
                                            onClose()
                                        }}
                                    />
                                    <Text>Hole x Hole</Text>
                                </Flex>
                                <Flex>
                                    <IconButton
                                        h={6}
                                        w={12}
                                        ref={btnRef}
                                        color="gray.700"
                                        icon={<CheckIcon />}
                                        onClick={(e) => {
                                            setCheckedItems({
                                                ...checkedItems,
                                                st: false,
                                                sd: true,
                                                pt: true,
                                                ol: true,
                                                dp: false,
                                                fw: false,
                                                ob: false,
                                                bk: false,
                                                pe: false,
                                                ho: false,
                                                SxS: false,
                                                SDpSD: false,
                                                PpP: false,
                                                PoP: false,
                                                BiP: false,
                                                BDy: false,
                                                DPt: false,
                                                NPt: false,
                                                Ho3: false,
                                                StD: false,
                                                PtD: false,
                                                On1: false,
                                                HxH: false,
                                                Pt6: false,
                                                St6: false,
                                                OLt: true,
                                            })
                                            onClose()
                                        }}
                                    />
                                    <Text>OL</Text>
                                </Flex>
                                <Flex>
                                    <IconButton
                                        h={6}
                                        w={12}
                                        ref={btnRef}
                                        color="gray.700"
                                        icon={<CheckIcon />}
                                        onClick={(e) => {
                                            setCheckedItems({
                                                ...checkedItems,
                                                st: false,
                                                sd: true,
                                                pt: true,
                                                ol: false,
                                                dp: false,
                                                fw: false,
                                                ob: false,
                                                bk: false,
                                                pe: false,
                                                ho: false,
                                                SxS: false,
                                                SDpSD: true,
                                                PpP: true,
                                                PoP: false,
                                                BiP: false,
                                                BDy: false,
                                                DPt: false,
                                                NPt: false,
                                                Ho3: false,
                                                StD: false,
                                                PtD: false,
                                                On1: false,
                                                HxH: false,
                                                Pt6: true,
                                                St6: true,
                                                OLt: false,
                                            })
                                            onClose()
                                        }}
                                    />
                                    <Text>Rule 6: StD/Putt 6以上</Text>
                                </Flex>

                                <Box>
                                    <Text fontSize="lg" color="red.500">
                                        表示設定Reset
                                    </Text>
                                    <Button
                                        colorScheme="teal"
                                        onClick={(e) => {
                                            setCheckedItems({
                                                ...checkedItems,
                                                st: true,
                                                sd: true,
                                                pt: true,
                                                ol: true,
                                                dp: true,
                                                fw: false,
                                                ob: false,
                                                bk: false,
                                                pe: false,
                                                ho: false,
                                                SxS: true,
                                                SDpSD: true,
                                                PpP: true,
                                                PoP: true,
                                                BiP: true,
                                                BDy: true,
                                                DPt: true,
                                                NPt: true,
                                                Ho3: true,
                                                StD: true,
                                                PtD: true,
                                                On1: true,
                                                HxH: true,
                                                Pt6: true,
                                                St6: true,
                                                OLt: true,
                                            })
                                            onClose()
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </Box>
                            </Stack>
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    )
}

function DrawerRegulationSettings(props) {
    //
    // Regulation Settings : Tab を閉じたときに，SessionStorage に設定を保存
    //
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    //const [tmpRules, setTmpRules] = useState(
    //    sessionStorage.getItem('TodaysRules') ?
    //        JSON.parse(sessionStorage.getItem('TodaysRules')) : props.checkedRules)

    //console.log("tmpRules: ", tmpRules)
    //    useEffect(() => {
    //        tmpRules = sessionStorage.getItem('TodaysRules') ?
    //            JSON.parse(sessionStorage.getItem('TodaysRules')) : props.checkedRules
    //    }, [])

    function onCloseMine() {
        console.log('onCloseMine()')
        sessionStorage.setItem('TodaysRules', JSON.stringify(props.checkedRules))
        onClose()
    }
    /*
    const toSave = (e) => {
        console.log("Save Todays Regulation: ",)
        sessionStorage.setItem('TodaysRules', JSON.stringify(tmpRules))
        onClose()
    }
    const toCancel = (e) => {
        onClose()
        //window.history.back()
    }
    */

    return (
        <>
            <IconButton
                h={6}
                w={12}
                ref={btnRef}
                color="gray.700"
                onClick={onOpen}
                icon={<SettingsIcon />}
            />
            <Drawer
                size="md"
                isOpen={isOpen}
                placement="right"
                onClose={onCloseMine}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Regulation Settings</DrawerHeader>

                        <DrawerBody>
                            <Stack spacing={1} direction="column">
                                <Text fontSize="lg" color="green.600">
                                    各Hole 個人
                                </Text>
                                <Checkbox
                                    defaultIsChecked
                                    value="PoP"
                                    isChecked={props.checkedRules.PoP}
                                    onChange={(e) =>
                                        props.setCheckedRules({
                                            ...props.checkedRules,
                                            PoP: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    PoP: パーオンパー(1p)
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="BiP"
                                    isChecked={props.checkedRules.BiP}
                                    onChange={(e) =>
                                        props.setCheckedRules({
                                            ...props.checkedRules,
                                            BiP: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    BKiP: バンカーインパー(1p)
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="BDy"
                                    isChecked={props.checkedRules.BDy}
                                    onChange={(e) =>
                                        props.setCheckedRules({
                                            ...props.checkedRules,
                                            BDy: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    BDy: バーディ(2p): イーグル(3p)
                                </Checkbox>
                                <Flex>
                                    <Checkbox
                                        defaultIsChecked
                                        value="DPt"
                                        isChecked={props.checkedRules.DPt}
                                        onChange={(e) =>
                                            props.setCheckedRules({
                                                ...props.checkedRules,
                                                DPt: e.target.checked,
                                            })
                                        }
                                        size="sm"
                                    >
                                        DPt: ドラコンポイント(2p x 14H) :{' '}
                                    </Checkbox>
                                    <Checkbox
                                        defaultIsChecked
                                        value="reqDPtPar"
                                        isChecked={props.checkedRules.reqDPtPar}
                                        colorScheme="green"
                                        onChange={(e) =>
                                            props.setCheckedRules({
                                                ...props.checkedRules,
                                                reqDPtPar: e.target.checked,
                                            })
                                        }
                                        size="sm"
                                    >
                                        パー締り
                                    </Checkbox>
                                    <Checkbox
                                        defaultIsChecked
                                        value="reqDPtBgi"
                                        isChecked={props.checkedRules.reqDPtBgi}
                                        colorScheme="green"
                                        onChange={(e) =>
                                            props.setCheckedRules({
                                                ...props.checkedRules,
                                                reqDPtBgi: e.target.checked,
                                            })
                                        }
                                        size="sm"
                                    >
                                        ボギー締り
                                    </Checkbox>
                                </Flex>
                                <Flex>
                                    <Checkbox
                                        defaultIsChecked
                                        value="NPt"
                                        isChecked={props.checkedRules.NPt}
                                        onChange={(e) =>
                                            props.setCheckedRules({
                                                ...props.checkedRules,
                                                NPt: e.target.checked,
                                            })
                                        }
                                        size="sm"
                                    >
                                        NPt: ニアピンポイント(2p x4H) :{' '}
                                    </Checkbox>
                                    <Checkbox
                                        defaultIsChecked
                                        value="reqNPt"
                                        isChecked={props.checkedRules.reqNPt}
                                        colorScheme="green"
                                        onChange={(e) =>
                                            props.setCheckedRules({
                                                ...props.checkedRules,
                                                reqNPt: e.target.checked,
                                            })
                                        }
                                        size="sm"
                                    >
                                        パー締り
                                    </Checkbox>
                                    <Checkbox
                                        defaultIsChecked
                                        value="reqNPtXon"
                                        isChecked={props.checkedRules.reqNPtXon}
                                        colorScheme="green"
                                        onChange={(e) =>
                                            props.setCheckedRules({
                                                ...props.checkedRules,
                                                reqNPtXon: e.target.checked,
                                            })
                                        }
                                        size="sm"
                                    >
                                        消ゴムあり
                                    </Checkbox>
                                </Flex>

                                <Text fontSize="lg" color="green.600">
                                    各Party
                                </Text>
                                <Checkbox
                                    defaultIsChecked
                                    value="Ho3"
                                    isChecked={props.checkedRules.Ho3}
                                    onChange={(e) =>
                                        props.setCheckedRules({
                                            ...props.checkedRules,
                                            Ho3: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    Ho3: 3H以上連続 単独勝利 (1p):(くじ含まず)(表示のみで計算未実装)
                                </Checkbox>

                                <Text fontSize="lg" color="green.600">
                                    各Hole 個人対抗(誰と？？)
                                </Text>
                                <Checkbox
                                    isDisabled
                                    defaultIsChecked
                                    value="StD"
                                    isChecked={props.checkedRules.StD}
                                    onChange={(e) =>
                                        props.setCheckedRules({
                                            ...props.checkedRules,
                                            StD: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    StD: 個人 St 差 3(+1p), 4以上(+2p)
                                </Checkbox>
                                <Checkbox
                                    isDisabled
                                    defaultIsChecked
                                    value="PtD"
                                    isChecked={props.checkedRules.PtD}
                                    onChange={(e) =>
                                        props.setCheckedRules({
                                            ...props.checkedRules,
                                            PtD: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    PtD: 個人 Pt 差 3(+1p), 4以上(+2p)
                                </Checkbox>

                                <Text fontSize="lg" color="green.600">
                                    各Hole ペア対抗
                                </Text>
                                <Checkbox
                                    defaultIsChecked
                                    value="HxH"
                                    isChecked={props.checkedRules.HxH}
                                    onChange={(e) =>
                                        props.setCheckedRules({
                                            ...props.checkedRules,
                                            HxH: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    HxH: Hole x Hole(掛算)(1p x 18Hole)
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="St6"
                                    isChecked={props.checkedRules.St6}
                                    onChange={(e) =>
                                        props.setCheckedRules({
                                            ...props.checkedRules,
                                            St6: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    St6: Rule 6 (score): ペアでスコア差 6以上(-1p)
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="Pt6"
                                    isChecked={props.checkedRules.Pt6}
                                    onChange={(e) =>
                                        props.setCheckedRules({
                                            ...props.checkedRules,
                                            Pt6: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    Pt6: Rule 6 (putt): ペアで 6 Pt以上(-1p)
                                </Checkbox>

                                <Text fontSize="lg" color="green.600">
                                    Through 個人
                                </Text>
                                <Checkbox
                                    defaultIsChecked
                                    value="On1"
                                    isChecked={props.checkedRules.On1}
                                    onChange={(e) =>
                                        props.setCheckedRules({
                                            ...props.checkedRules,
                                            On1: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    On1: One On(4H:3p, 3H:2p, 2H:1p, 1H:0p, No-On: -1p)
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="BrkR"
                                    isChecked={props.checkedRules.BrkR}
                                    onChange={(e) =>
                                        props.setCheckedRules({
                                            ...props.checkedRules,
                                            BrkR: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    BrkR: Best更新(3p),タイ(1p)
                                </Checkbox>

                                <Text fontSize="lg" color="green.600">
                                    Half or Through Team対抗
                                </Text>
                                <Flex>
                                    <Checkbox
                                        defaultIsChecked
                                        value="OLt"
                                        isChecked={props.checkedRules.OLt}
                                        onChange={(e) =>
                                            props.setCheckedRules({
                                                ...props.checkedRules,
                                                OLt: e.target.checked,
                                            })
                                        }
                                        size="sm"
                                    >
                                        OLt:Olympic(Team計-最下位計)x0.5p :
                                    </Checkbox>
                                    <Checkbox
                                        defaultIsChecked
                                        value="reqOLt"
                                        isChecked={props.checkedRules.reqOLt}
                                        colorScheme="green"
                                        onChange={(e) =>
                                            props.setCheckedRules({
                                                ...props.checkedRules,
                                                reqOLt: e.target.checked,
                                            })
                                        }
                                        size="sm"
                                    >
                                        ダボ締り
                                    </Checkbox>
                                </Flex>
                                <Checkbox
                                    defaultIsChecked
                                    value="HxHH"
                                    isChecked={props.checkedRules.HxHH}
                                    onChange={(e) =>
                                        props.setCheckedRules({
                                            ...props.checkedRules,
                                            HxHH: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    HxHH: Hole x Hole Half計(3p x2H)
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="NetT"
                                    isChecked={props.checkedRules.NetT}
                                    onChange={(e) =>
                                        props.setCheckedRules({
                                            ...props.checkedRules,
                                            NetT: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    NetT: Team NET (1位:3p)
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="GrsT"
                                    isChecked={props.checkedRules.GrsT}
                                    onChange={(e) =>
                                        props.setCheckedRules({
                                            ...props.checkedRules,
                                            GrsT: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    GrsT: チームGross (1位:3p)
                                </Checkbox>
                                <Checkbox
                                    defaultIsChecked
                                    value="AL"
                                    isChecked={props.checkedRules.AL}
                                    onChange={(e) =>
                                        props.setCheckedRules({
                                            ...props.checkedRules,
                                            AL: e.target.checked,
                                        })
                                    }
                                    size="sm"
                                >
                                    AL: 朝アル(1p/1杯)
                                </Checkbox>
                            </Stack>
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    )
}

export default function Point(props) {
    const { rid } = useParams()
    const location = useLocation()
    const course_name = location.state.course.course_name
    const course_dts = location.state.course.course_dts
    const first_half = location.state.parties[0].first_half
    const second_half = location.state.parties[0].second_half

    const API_ORIGIN = process.env.REACT_APP_BESVR_URI_ORIGIN

    const [tabId, setTabId] = useState(0)

    const [origData, setOrigData] = useState()
    const [updateVal, setUpdateVal] = useState(0) // Force Rendering Update Dummy State
    // Play Mode Settings for Drawer
    //const [playMode, setPlayMode] = useState(location.state.parties[0].play_mode)
    const playMode = location.state.parties[0].play_mode
    //------const [members, setMembers] = useState([{ pty: 0, oid: 0, name: "", odds: 0, pbest: 0, al: 0,  }]) // List of Member Name// [{ guid: , name: }, ... ]
    // -------------const [parties, setParties] = useState([])
    //const [teamMemberOrder, setTeamMemberOrder] = useState([])  // Member Order(チーム組合せ)
    // --------const [parData, setParData] = useState([])

    console.log(
        'POINT: parties: ',
        location.state.parties,
        location.state.parties[location.state.parties.length - 1].id
    )

    // DISP Flag

    const defaultCheckedItems = {
        st: true,
        sd: false,
        pt: true,
        ol: false,
        dp: false,
        fw: false,
        ob: false,
        bk: false,
        pe: false,
        ho: false,
        SxS: false,
        SDpSD: false,
        PpP: false,
        PoP: false,
        BiP: false,
        BDy: false,
        DPt: false,
        NPt: false,
        Ho3: false,
        StD: false,
        PtD: false,
        On1: false,
        HxH: true,
        Pt6: false,
        St6: false,
        OLt: false,
    }

    const [checkedItems, setCheckedItems] = useState(defaultCheckedItems)

    // Default Rules の設定は以下
    const defaultRules = {
        PoP: true,
        BiP: false,
        BDy: true,
        DPt: true,
        NPt: true,
        Ho3: false,
        StD: false,
        PtD: false,
        On1: true,
        HxH: true,
        Pt6: true,
        St6: true,
        OLt: true,
        HxHH: false,
        NetT: true,
        GrsT: false,
        Rslt: true,
        BrkR: true,
        AL: true,
        reqDPtPar: true, // DP Par 縛り
        reqDPtBgi: false, // DP Bogey 縛り
        reqNPt: true, // NP Par 縛り
        reqNPtXon: true, // NP 消しゴムあり
        reqOLt: true, // OL ダボ縛り
    }
    // Regulation Flag
    const [checkedRules, setCheckedRules] = useState(defaultRules)
    //    (sessionStorage.getItem('TodaysRules') ?
    //        JSON.parse(sessionStorage.getItem('TodaysRules')) : defaultRules)

    var mems = []
    var pties = []
    //var ptymems = []
    location.state.parties.map((party, n) => {
        pties.push(party.id)
        mems.push({
            pty: n,
            oid: party.player1_guid,
            name: party.player1_name,
            odds: party.player1_odds,
            pbest: party.player1_pbest,
            al: party.player1_al,
        })
        mems.push({
            pty: n,
            oid: party.player2_guid,
            name: party.player2_name,
            odds: party.player2_odds,
            pbest: party.player2_pbest,
            al: party.player2_al,
        })
        mems.push({
            pty: n,
            oid: party.player3_guid,
            name: party.player3_name,
            odds: party.player3_odds,
            pbest: party.player3_pbest,
            al: party.player3_al,
        })
        mems.push({
            pty: n,
            oid: party.player4_guid,
            name: party.player4_name,
            odds: party.player4_odds,
            pbest: party.player4_pbest,
            al: party.player4_al,
        })
    })
    const parties = pties
    const pnum = pties.length
    const members = mems

    const teamMemberOrder =
        pties.length == 1
            ? [0, 1, 2, 3]
            : playMode == config.MODE2
            ? [0, 4, 1, 5, 2, 6, 3, 7]
            : [0, 1, 2, 3, 4, 5, 6, 7]
    const teamName = ['A', 'B', 'C', 'D']

    console.log('members: ', members, teamMemberOrder, pnum)
    console.log(
        'members:  p: ',
        members.filter((m) => {
            return m.pty == 0
        })
    )
    // Parsonal Score & Result
    //     2次元配列の初期化  18 hole x 8 人分  (初期値がないと Rendering でエラーするので)
    const [scores, setScores] = useState(
        Array(18)
            .fill()
            .map(() =>
                Array(pnum * 4).fill({
                    half_name: '',
                    hole_num: 0,
                    stroke: 0,
                    st_delta: 0,
                    putt: 0,
                    ol: 0,
                    dp: 0,
                    fw: 0,
                    ob: 0,
                    bk: 0,
                    pe: 0,
                    memo: '',
                    ho: 0,
                    PoP: 0,
                    BiP: 0,
                    BDy: 0,
                    DPt: 0,
                    NPt: 0,
                    OLt: 0,
                    Ho3: 0,
                    StD: 0,
                    PtD: 0,
                    On1: 0,
                    HxH: 0,
                    Pt6: 0,
                    St6: 0,
                })
            )
    )
    // sum  (H1, H2, H1+H2) x 8人
    const [sumScores, setSumScores] = useState(
        Array(8)
            .fill()
            .map(() =>
                Array(3).fill({
                    stroke: 0,
                    st_delta: 0,
                    putt: 0,
                    ol: 0,
                    dp: 0,
                    fw: 0,
                    ob: 0,
                    bk: 0,
                    pe: 0,
                    memo: '',
                    ho: 0,
                    PoP: 0,
                    BiP: 0,
                    BDy: 0,
                    DPt: 0,
                    NPt: 0,
                    OLt: 0,
                    Ho3: 0,
                    StD: 0,
                    PtD: 0,
                    On1: 0,
                    HxH: 0,
                    Pt6: 0,
                    St6: 0,
                    odd_st: 0,
                    BrkR: 0, // Half は意味ないので，[2]の Round TTL のみ使用
                })
            )
    )
    // 18 hole x 4 pair
    const [teamScores, setTeamScores] = useState(
        Array(18)
            .fill()
            .map(() =>
                Array(pnum * 2).fill({
                    SxS: 0,
                    SpS: 0,
                    SDpSD: 0,
                    PpP: 0,
                    OLP: 0,
                    HxH: 0,
                    StD: 0,
                    PtD: 0,
                    St6: 0,
                    Pt6: 0,
                })
            )
    )
    // sum  2 Pair/Pty x (H1, H2, H1+H2)
    const [sumTeamScores, setSumTeamScores] = useState(
        Array(pnum * 2)
            .fill()
            .map(() =>
                Array(3).fill({
                    SpS: 0,
                    SDpSD: 0,
                    HxH: 0,
                    St6: 0,
                    Pt6: 0,
                    OLP: 0,
                    OLM: 0,
                    // followings are TTL [2] Only
                    PoP: 0,
                    BiP: 0,
                    BDy: 0,
                    DPt: 0,
                    NPt: 0,
                    GrsT: 0,
                    SpSO: 0,
                    PpPO: 0,
                    ODDS: 0,
                    //ODPT: 0,
                })
            )
    )
    // Parsonal & Team Result
    // HoleOrder.MemberID.{ HxHSt, HxHPt ... }
    const [pResult, setPResult] = useState(
        Array(18)
            .fill()
            .map(() =>
                Array(pnum * 4).fill({
                    PoP: 0,
                    BiP: 0,
                    BDy: 0,
                    DPt: 0,
                    NPt: 0,
                    Ho3: 0,
                    StD: 0,
                    PtD: 0,
                    On1: 0,
                    HxH: 0,
                    Pt6: 0,
                    St6: 0,
                })
            )
    )
    const [isLoading, setIsLoading] = useState(false)

    console.log('Point: course_dts', course_dts)

    useEffect(() => {
        console.log('CheckedItems: ', checkedItems)
    }, [checkedItems])
    useEffect(() => {
        console.log('CheckedRules: ', checkedRules)
    }, [checkedRules])

    var parData0 = Array(18).fill(0)
    //var yardData = Array(18).fill(0)
    // course_dts.map((dts, i) => {
    for (let i = 0; i < 18; i++) {
        let ip = (i % 9) + 1
        const half = i < 9 ? first_half : second_half
        //console.log("Point: half: ", i, ip, half, first_half, second_half)
        parData0[i] = course_dts
            .filter((dts) => dts.half_name == half && dts.half_num == ip)
            .shift().par
        //yardData[i] = course_dts.filter(dts => (dts.half_name == half && dts.half_num == ip)).shift().yard
    }
    const parData = parData0
    console.log('Point: course_dts: par ', parData)

    const thru_path = {
        pathname: '/Presen/',
        state: {
            presenMode: false,
            playMode: playMode,
            members: members,
            rid: rid,
            pnum: pnum,
            parData: parData,
            teamName: teamName,
            teamMemberOrder: teamMemberOrder,
            checkedRules: checkedRules,
            baseData: origData,
            scores: scores,
            teamScores: teamScores,
            //setCheckedRules: setCheckedRules,
            sumScores: sumScores,
            sumTeamScores: sumTeamScores,
        },
    }

    const presen_path = {
        pathname: '/Presen/',
        state: {
            presenMode: true,
            playMode: playMode,
            members: members,
            rid: rid,
            pnum: pnum,
            parData: parData,
            teamName: teamName,
            teamMemberOrder: teamMemberOrder,
            checkedRules: checkedRules,
            baseData: origData,
            scores: scores,
            teamScores: teamScores,
            //setCheckedRules: setCheckedRules,
            sumScores: sumScores,
            sumTeamScores: sumTeamScores,
        },
    }

    //
    // Get All Score
    //
    const fetchScores = async () => {
        setIsLoading(true)
        //console.log("from fetchScore: parData: ", parData)
        const response = await fetch(API_ORIGIN + '/api/point/all/' + rid)
        if (response.ok) {
            const resps = await response.json()
            if (resps.point == 'NoResult' || resps[0].sc.length == 0) {
                console.log('score : NoResult')
            } else {
                console.log('resps :', resps)
                setOrigData(resps)
            }
        }
        setIsLoading(false)
    }
    useEffect(() => {
        fetchScores()

        // TodaysRules が無ければ作成(初期値)
        if (sessionStorage.getItem('TodaysRules') == null) {
            sessionStorage.setItem('TodaysRules', JSON.stringify(defaultRules))
        }
        setCheckedRules(JSON.parse(sessionStorage.getItem('TodaysRules')))
    }, [])

    useEffect(() => {
        if (origData !== undefined) {
            var { sc, teamSc, sumSc, sumTeamSc } = calcPoint(
                playMode,
                origData,
                parData,
                scores,
                sumScores,
                teamScores,
                sumTeamScores,
                pnum,
                members,
                teamMemberOrder,
                checkedRules
            )

            setSumTeamScores(sumTeamSc)
            setSumScores(sumSc)
            setTeamScores(teamSc)
            setScores(sc)
        }
        setUpdateVal((updateVal) => ++updateVal) // Force Rendering Update
    }, [origData, checkedRules]) // 他にも必要なタイミングあるかも

    function IndivisualPtTable() {
        return (
            <Table variant="simple" colorScheme="facebook" size="sm">
                <Thead>
                    <Tr>
                        <Th {...level3} left="0" top="0" color="white" bg="green.800">
                            <Box
                                onClick={() =>
                                    setCheckedItems({
                                        ...checkedItems,
                                        st: true,
                                        sd: false,
                                        pt: true,
                                        ol: false,
                                        dp: false,
                                        fw: false,
                                        ob: false,
                                        bk: false,
                                        pe: false,
                                        ho: false,
                                        SxS: false,
                                        SDpSD: false,
                                        PpP: false,
                                        PoP: false,
                                        BiP: false,
                                        BDy: false,
                                        DPt: false,
                                        NPt: false,
                                        Ho3: false,
                                        StD: false,
                                        PtD: false,
                                        On1: false,
                                        HxH: false,
                                        Pt6: false,
                                        St6: false,
                                        OLt: false,
                                    })
                                }
                            >
                                <Tooltip label="st,pt">Hole</Tooltip>
                            </Box>{' '}
                        </Th>
                        <Th {...level3} left="45px" top="0" color="white" bg="green.700">
                            <Box
                                onClick={() =>
                                    setCheckedItems({
                                        ...checkedItems,
                                        st: true,
                                        sd: true,
                                        pt: true,
                                        ol: true,
                                        dp: true,
                                        fw: true,
                                        ob: true,
                                        bk: true,
                                        pe: true,
                                        ho: false,
                                        SxS: false,
                                        SDpSD: false,
                                        PpP: false,
                                        PoP: false,
                                        BiP: false,
                                        BDy: false,
                                        DPt: false,
                                        NPt: false,
                                        Ho3: false,
                                        StD: false,
                                        PtD: false,
                                        On1: false,
                                        HxH: false,
                                        Pt6: false,
                                        St6: false,
                                        OLt: false,
                                    })
                                }
                            >
                                {' '}
                                <Tooltip label="入力確認">Par</Tooltip>
                            </Box>{' '}
                        </Th>
                        {members.map((member) => {
                            if (member.name != '')
                                return (
                                    <>
                                        {/* 名前の colspan を算出 */}
                                        <Th
                                            {...styleName}
                                            colspan={[
                                                checkedItems.st,
                                                checkedItems.sd,
                                                checkedItems.pt,
                                                checkedItems.ol,
                                                checkedItems.dp,
                                                checkedItems.fw,
                                                checkedItems.bk,
                                                checkedItems.ob,
                                                checkedItems.pe,
                                                checkedItems.ho,
                                                checkedItems.PoP,
                                                checkedItems.BiP,
                                                checkedItems.BDy,
                                                checkedItems.DPt,
                                                checkedItems.NPt,
                                                checkedItems.On1,
                                            ].reduce((cnt, v) => (v == true ? cnt + 1 : cnt), 0)}
                                            textAlign="left"
                                            left="0"
                                        >
                                            {member.name}
                                        </Th>
                                    </>
                                )
                        })}
                    </Tr>
                    <Tr>
                        <Th {...level3} left="0" top="40px" color="white" bg="green.800">
                            <Box
                                onClick={() =>
                                    setCheckedItems({
                                        ...checkedItems,
                                        st: true,
                                        sd: true,
                                        pt: true,
                                        ol: false,
                                        dp: false,
                                        fw: false,
                                        ob: false,
                                        bk: false,
                                        pe: false,
                                        ho: false,
                                        SxS: false,
                                        SDpSD: false,
                                        PpP: false,
                                        PoP: false,
                                        BiP: false,
                                        BDy: false,
                                        DPt: false,
                                        NPt: false,
                                        Ho3: false,
                                        StD: false,
                                        PtD: false,
                                        On1: false,
                                        HxH: false,
                                        Pt6: false,
                                        St6: false,
                                        OLt: false,
                                    })
                                }
                            >
                                <Tooltip label="sd,pt">Hole</Tooltip>
                            </Box>
                        </Th>
                        <Th {...level3} left="45px" top="40px" color="white" bg="green.700">
                            <Box
                                onClick={() =>
                                    setCheckedItems({
                                        ...checkedItems,
                                        st: true,
                                        sd: true,
                                        pt: true,
                                        ol: true,
                                        dp: true,
                                        fw: false,
                                        ob: false,
                                        bk: false,
                                        pe: false,
                                        ho: false,
                                        SxS: true,
                                        SDpSD: true,
                                        PpP: true,
                                        PoP: true,
                                        BiP: true,
                                        BDy: true,
                                        DPt: true,
                                        NPt: true,
                                        Ho3: true,
                                        StD: true,
                                        PtD: true,
                                        On1: true,
                                        HxH: true,
                                        Pt6: true,
                                        St6: true,
                                        OLt: true,
                                    })
                                }
                            >
                                <Tooltip label="個人Point">Par</Tooltip>
                            </Box>
                        </Th>
                        {members.map((member, nn) => {
                            if (member.name != '')
                                return (
                                    <>
                                        {checkedItems.st && (
                                            <Th {...styleTop} left="0">
                                                ST
                                            </Th>
                                        )}
                                        {checkedItems.sd && (
                                            <Th {...styleTop} left="0">
                                                <Tooltip label="SD=Stroke-Par">SD</Tooltip>
                                            </Th>
                                        )}
                                        {checkedItems.pt && (
                                            <Th {...styleTop} left="0">
                                                PT
                                            </Th>
                                        )}
                                        {checkedItems.ol && (
                                            <Th {...styleTop} left="0">
                                                OL
                                            </Th>
                                        )}
                                        {checkedItems.dp && (
                                            <Th {...styleTop} left="0">
                                                DP
                                            </Th>
                                        )}
                                        {checkedItems.fw && (
                                            <Th {...styleTop} left="0">
                                                FW
                                            </Th>
                                        )}
                                        {checkedItems.bk && (
                                            <Th {...styleTop} left="0">
                                                BK
                                            </Th>
                                        )}
                                        {checkedItems.ob && (
                                            <Th {...styleTop} left="0">
                                                OB
                                            </Th>
                                        )}
                                        {checkedItems.pe && (
                                            <Th {...styleTop} left="0">
                                                PE
                                            </Th>
                                        )}
                                        {checkedItems.ho && (
                                            <Th {...styleTop} left="0">
                                                Hor
                                            </Th>
                                        )}

                                        {checkedItems.PoP && (
                                            <Th {...styleTopResult} left="0">
                                                <Tooltip label="Par on Par">PoP</Tooltip>
                                            </Th>
                                        )}
                                        {checkedItems.BiP && (
                                            <Th {...styleTopResult} left="0">
                                                <Tooltip label="Banker in Par">BiP</Tooltip>
                                            </Th>
                                        )}
                                        {checkedItems.BDy && (
                                            <Th {...styleTopResult} left="0">
                                                <Tooltip label="Birdy/Eagle">BDy</Tooltip>
                                            </Th>
                                        )}
                                        {checkedItems.DPt && (
                                            <Th {...styleTopResult} left="0">
                                                DPt
                                            </Th>
                                        )}
                                        {checkedItems.NPt && (
                                            <Th {...styleTopResult} left="0">
                                                NPt
                                            </Th>
                                        )}
                                        {checkedItems.On1 && (
                                            <Th {...styleTopResult} left="0">
                                                <Tooltip label="One On">On1</Tooltip>
                                            </Th>
                                        )}
                                    </>
                                )
                        })}
                    </Tr>
                </Thead>
                <Tbody>
                    {scores.map((hole_score, i) => {
                        let ip = (i % 9) + 1
                        const half = i < 9 ? first_half : second_half
                        const pa = course_dts
                            .filter((dts) => dts.half_name == half && dts.half_num == ip)
                            .shift().par
                        const hole = course_dts
                            .filter((dts) => dts.half_name == half && dts.half_num == ip)
                            .shift().hole_num

                        return (
                            <>
                                <Tr>
                                    <Td {...styleHole}>
                                        {/* Score へ Jump */}
                                        <Link
                                            to={{
                                                pathname:
                                                    '/Score/' +
                                                    rid +
                                                    '/' +
                                                    location.state.parties[0].id,
                                                hash: '#' + (i + 1),
                                                state: {
                                                    course: location.state.course,
                                                    parties: location.state.parties,
                                                    input_mode:
                                                        location.state.parties[0].input_mode,
                                                },
                                            }}
                                        >
                                            {hole}
                                        </Link>
                                    </Td>
                                    <Td {...stylePar}>
                                        {/* Score へ Jump */}
                                        <Link
                                            to={{
                                                pathname:
                                                    '/Score/' +
                                                    rid +
                                                    '/' +
                                                    location.state.parties[
                                                        location.state.parties.length - 1
                                                    ].id,
                                                hash: '#' + (i + 1),
                                                state: {
                                                    course: location.state.course,
                                                    parties: location.state.parties,
                                                    input_mode:
                                                        location.state.parties[0].input_mode,
                                                },
                                            }}
                                        >
                                            {pa}
                                        </Link>
                                    </Td>
                                    {hole_score.map((member_score, k) => {
                                        if (members[k].name != '')
                                            return (
                                                <>
                                                    {checkedItems.st && (
                                                        <Tooltip label={member_score.memo}>
                                                            {member_score.memo ? (
                                                                <Td {...styleDt} color="green.500">
                                                                    {member_score.stroke}
                                                                </Td>
                                                            ) : (
                                                                <Td {...styleDt}>
                                                                    {member_score.stroke}
                                                                </Td>
                                                            )}
                                                        </Tooltip>
                                                    )}
                                                    {checkedItems.sd && (
                                                        <Td {...styleDt}>
                                                            {getStDelta(member_score.st_delta)}
                                                        </Td>
                                                    )}
                                                    {checkedItems.pt && (
                                                        <Td {...styleDt}>{member_score.putt}</Td>
                                                    )}
                                                    {checkedItems.ol && (
                                                        <Td {...styleDt}>
                                                            {getOL(member_score.ol)}
                                                        </Td>
                                                    )}
                                                    {checkedItems.dp && (
                                                        <Td {...styleDt}>
                                                            {getDP(member_score.dp, pa)}
                                                        </Td>
                                                    )}
                                                    {checkedItems.fw && (
                                                        <Td {...styleDt}>
                                                            {getFW(member_score.fw, pa)}
                                                        </Td>
                                                    )}
                                                    {checkedItems.bk && (
                                                        <Td {...styleDt}>
                                                            {member_score.bk != 0
                                                                ? member_score.bk
                                                                : ''}
                                                        </Td>
                                                    )}
                                                    {checkedItems.ob && (
                                                        <Td {...styleDt}>
                                                            {member_score.ob != 0
                                                                ? member_score.ob
                                                                : ''}
                                                        </Td>
                                                    )}
                                                    {checkedItems.pe && (
                                                        <Td {...styleDt}>
                                                            {member_score.pe != 0
                                                                ? member_score.pe
                                                                : ''}
                                                        </Td>
                                                    )}
                                                    {checkedItems.ho && (
                                                        <Td {...styleDt}>
                                                            {member_score.ho != 0
                                                                ? member_score.ho
                                                                : ''}
                                                        </Td>
                                                    )}
                                                    {checkedItems.PoP && (
                                                        <Td {...styleDt}>
                                                            {member_score.PoP != 0
                                                                ? member_score.PoP
                                                                : ''}
                                                        </Td>
                                                    )}
                                                    {checkedItems.BiP && (
                                                        <Td {...styleDt}>
                                                            {member_score.BiP != 0
                                                                ? member_score.BiP
                                                                : ''}
                                                        </Td>
                                                    )}
                                                    {checkedItems.BDy && (
                                                        <Td {...styleDt}>
                                                            {member_score.BDy != 0
                                                                ? member_score.BDy
                                                                : ''}
                                                        </Td>
                                                    )}
                                                    {checkedItems.DPt && (
                                                        <Td {...styleDt}>
                                                            {member_score.DPt != 0
                                                                ? member_score.DPt
                                                                : ''}
                                                        </Td>
                                                    )}
                                                    {checkedItems.NPt && (
                                                        <Td {...styleDt}>
                                                            {member_score.NPt != 0
                                                                ? member_score.NPt
                                                                : ''}
                                                        </Td>
                                                    )}
                                                    {checkedItems.On1 && (
                                                        <Td {...styleDt}>
                                                            {member_score.On1 != 0
                                                                ? member_score.On1
                                                                : ''}
                                                        </Td>
                                                    )}
                                                </>
                                            )
                                    })}
                                </Tr>
                                {i == 8 && (
                                    <Tr>
                                        <Td {...styleHole}>{first_half}</Td>
                                        <Td {...stylePar}></Td>
                                        {members.map((member, i) => {
                                            if (member.name != '')
                                                return (
                                                    <>
                                                        {checkedItems.st && (
                                                            <Th {...styleName} left="0">
                                                                {sumScores[i][0].stroke}
                                                            </Th>
                                                        )}
                                                        {checkedItems.sd && (
                                                            <Th {...styleName} left="0">
                                                                {sumScores[i][0].st_delta}
                                                            </Th>
                                                        )}
                                                        {checkedItems.pt && (
                                                            <Th {...styleName} left="0">
                                                                {sumScores[i][0].putt}
                                                            </Th>
                                                        )}
                                                        {checkedItems.ol && (
                                                            <Th {...styleName} left="0">
                                                                {sumScores[i][0].ol}
                                                            </Th>
                                                        )}
                                                        {checkedItems.dp && (
                                                            <Th {...styleName} left="0">
                                                                {sumScores[i][0].dp}
                                                            </Th>
                                                        )}
                                                        {checkedItems.fw && (
                                                            <Th {...styleName} left="0">
                                                                {sumScores[i][0].ol}
                                                            </Th>
                                                        )}
                                                        {checkedItems.bk && (
                                                            <Th {...styleName} left="0">
                                                                {sumScores[i][0].bk}
                                                            </Th>
                                                        )}
                                                        {checkedItems.ob && (
                                                            <Th {...styleName} left="0">
                                                                {sumScores[i][0].ob}
                                                            </Th>
                                                        )}
                                                        {checkedItems.pe && (
                                                            <Th {...styleName} left="0">
                                                                {sumScores[i][0].pe}
                                                            </Th>
                                                        )}
                                                        {checkedItems.PoP && (
                                                            <Th {...styleName} left="0">
                                                                {sumScores[i][0].PoP}
                                                            </Th>
                                                        )}
                                                        {checkedItems.BiP && (
                                                            <Th {...styleName} left="0">
                                                                {sumScores[i][0].BiP}
                                                            </Th>
                                                        )}
                                                        {checkedItems.BDy && (
                                                            <Th {...styleName} left="0">
                                                                {sumScores[i][0].BDy}
                                                            </Th>
                                                        )}
                                                        {checkedItems.DPt && (
                                                            <Th {...styleName} left="0">
                                                                {sumScores[i][0].DPt}
                                                            </Th>
                                                        )}
                                                        {checkedItems.NPt && (
                                                            <Th {...styleName} left="0">
                                                                {sumScores[i][0].NPt}
                                                            </Th>
                                                        )}
                                                        {checkedItems.On1 && (
                                                            <Th {...styleName} left="0">
                                                                {sumScores[i][0].On1}
                                                            </Th>
                                                        )}
                                                    </>
                                                )
                                        })}
                                    </Tr>
                                )}
                                {i == 17 && (
                                    <>
                                        <Tr>
                                            <Td {...styleHole}>{second_half}</Td>
                                            <Td {...stylePar}></Td>
                                            {members.map((member, i) => {
                                                if (member.name != '')
                                                    return (
                                                        <>
                                                            {checkedItems.st && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][1].stroke}
                                                                </Th>
                                                            )}
                                                            {checkedItems.sd && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][1].st_delta}
                                                                </Th>
                                                            )}
                                                            {checkedItems.pt && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][1].putt}
                                                                </Th>
                                                            )}
                                                            {checkedItems.ol && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][1].ol}
                                                                </Th>
                                                            )}
                                                            {checkedItems.dp && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][1].dp}
                                                                </Th>
                                                            )}
                                                            {checkedItems.fw && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][1].ol}
                                                                </Th>
                                                            )}
                                                            {checkedItems.bk && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][1].bk}
                                                                </Th>
                                                            )}
                                                            {checkedItems.ob && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][1].ob}
                                                                </Th>
                                                            )}
                                                            {checkedItems.pe && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][1].pe}
                                                                </Th>
                                                            )}
                                                            {checkedItems.PoP && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][1].PoP}
                                                                </Th>
                                                            )}
                                                            {checkedItems.BiP && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][1].BiP}
                                                                </Th>
                                                            )}
                                                            {checkedItems.BDy && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][1].BDy}
                                                                </Th>
                                                            )}
                                                            {checkedItems.DPt && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][1].DPt}
                                                                </Th>
                                                            )}
                                                            {checkedItems.NPt && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][1].NPt}
                                                                </Th>
                                                            )}
                                                            {checkedItems.On1 && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][1].On1}
                                                                </Th>
                                                            )}
                                                        </>
                                                    )
                                            })}
                                        </Tr>
                                        <Tr>
                                            <Td {...styleHole}>TTL</Td>
                                            <Td {...stylePar}></Td>
                                            {members.map((member, i) => {
                                                if (member.name != '')
                                                    return (
                                                        <>
                                                            {checkedItems.st && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][2].stroke}
                                                                </Th>
                                                            )}
                                                            {checkedItems.sd && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][2].st_delta}
                                                                </Th>
                                                            )}
                                                            {checkedItems.pt && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][2].putt}
                                                                </Th>
                                                            )}
                                                            {checkedItems.ol && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][2].ol}
                                                                </Th>
                                                            )}
                                                            {checkedItems.dp && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][2].dp}
                                                                </Th>
                                                            )}
                                                            {checkedItems.fw && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][2].ol}
                                                                </Th>
                                                            )}
                                                            {checkedItems.bk && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][2].bk}
                                                                </Th>
                                                            )}
                                                            {checkedItems.ob && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][2].ob}
                                                                </Th>
                                                            )}
                                                            {checkedItems.pe && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][2].pe}
                                                                </Th>
                                                            )}
                                                            {checkedItems.PoP && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][2].PoP}
                                                                </Th>
                                                            )}
                                                            {checkedItems.BiP && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][2].BiP}
                                                                </Th>
                                                            )}
                                                            {checkedItems.BDy && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][2].BDy}
                                                                </Th>
                                                            )}
                                                            {checkedItems.DPt && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][2].DPt}
                                                                </Th>
                                                            )}
                                                            {checkedItems.NPt && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][2].NPt}
                                                                </Th>
                                                            )}
                                                            {checkedItems.On1 && (
                                                                <Th {...styleName} left="0">
                                                                    {sumScores[i][2].On1}
                                                                </Th>
                                                            )}
                                                        </>
                                                    )
                                            })}
                                        </Tr>
                                    </>
                                )}
                            </>
                        )
                    })}
                </Tbody>
            </Table>
        )
    }

    function TeamPtTable() {
        return (
            <Table variant="simple" colorScheme="facebook" size="sm">
                <Thead>
                    <Tr>
                        <Th {...level3} left="0" top="0" color="white" bg="green.800">
                            <Box
                                onClick={() =>
                                    setCheckedItems({
                                        ...checkedItems,
                                        st: true,
                                        sd: false,
                                        pt: false,
                                        ol: false,
                                        dp: false,
                                        fw: false,
                                        ob: false,
                                        bk: false,
                                        pe: false,
                                        ho: false,
                                        SxS: false,
                                        SDpSD: false,
                                        PpP: false,
                                        PoP: false,
                                        BiP: false,
                                        BDy: false,
                                        DPt: false,
                                        NPt: false,
                                        Ho3: false,
                                        StD: false,
                                        PtD: false,
                                        On1: false,
                                        HxH: true,
                                        Pt6: false,
                                        St6: false,
                                        OLt: false,
                                    })
                                }
                            >
                                <Tooltip label="HxH">Hole</Tooltip>
                            </Box>{' '}
                        </Th>
                        <Th {...level3} left="45px" top="0" color="white" bg="green.700">
                            <Box
                                onClick={() =>
                                    setCheckedItems({
                                        ...checkedItems,
                                        st: false,
                                        sd: true,
                                        pt: true,
                                        ol: false,
                                        dp: false,
                                        fw: false,
                                        ob: false,
                                        bk: false,
                                        pe: false,
                                        ho: false,
                                        SxS: false,
                                        SDpSD: true,
                                        PpP: true,
                                        PoP: false,
                                        BiP: false,
                                        BDy: false,
                                        DPt: false,
                                        NPt: false,
                                        Ho3: false,
                                        StD: false,
                                        PtD: false,
                                        On1: false,
                                        HxH: false,
                                        Pt6: true,
                                        St6: true,
                                        OLt: false,
                                    })
                                }
                            >
                                <Tooltip label="Rule 6">Par</Tooltip>
                            </Box>{' '}
                        </Th>
                        {teamMemberOrder.map((i, n) => {
                            if (members[i].name != '')
                                return (
                                    <>
                                        {/* 名前の colspan を算出 */}
                                        <Th
                                            {...styleName}
                                            colspan={[
                                                checkedItems.st,
                                                checkedItems.sd,
                                                checkedItems.pt,
                                                checkedItems.ol,
                                                checkedItems.OLt,
                                            ].reduce((cnt, v) => (v == true ? cnt + 1 : cnt), 0)}
                                            textAlign="left"
                                            left="0"
                                        >
                                            {members[i].name}
                                        </Th>
                                        {n % 2 == 1 && (
                                            <>
                                                {checkedItems.SxS && <Th {...styleName} left="0" />}
                                                {checkedItems.SDpSD && (
                                                    <Th {...styleName} left="0" />
                                                )}
                                                {checkedItems.PpP && <Th {...styleName} left="0" />}
                                                {checkedItems.HxH && <Th {...styleName} left="0" />}
                                                {checkedItems.OLt && <Th {...styleName} left="0" />}
                                                {checkedItems.Pt6 && <Th {...styleName} left="0" />}
                                                {checkedItems.St6 && <Th {...styleName} left="0" />}
                                            </>
                                        )}
                                    </>
                                )
                        })}
                    </Tr>
                    <Tr>
                        <Th {...level3} left="0" top="40px" color="white" bg="green.800">
                            <Box
                                onClick={() =>
                                    setCheckedItems({
                                        ...checkedItems,
                                        st: false,
                                        sd: true,
                                        pt: true,
                                        ol: true,
                                        dp: false,
                                        fw: false,
                                        ob: false,
                                        bk: false,
                                        pe: false,
                                        ho: false,
                                        SxS: false,
                                        SDpSD: false,
                                        PpP: false,
                                        PoP: false,
                                        BiP: false,
                                        BDy: false,
                                        DPt: false,
                                        NPt: false,
                                        Ho3: false,
                                        StD: false,
                                        PtD: false,
                                        On1: false,
                                        HxH: false,
                                        Pt6: false,
                                        St6: false,
                                        OLt: true,
                                    })
                                }
                            >
                                <Tooltip label="OL">Hole</Tooltip>
                            </Box>{' '}
                        </Th>
                        <Th {...level3} left="45px" top="40px" color="white" bg="green.700">
                            <Box
                                onClick={() =>
                                    setCheckedItems({
                                        ...checkedItems,
                                        st: true,
                                        sd: true,
                                        pt: true,
                                        ol: true,
                                        dp: true,
                                        fw: false,
                                        ob: false,
                                        bk: false,
                                        pe: false,
                                        ho: false,
                                        SxS: true,
                                        SDpSD: true,
                                        PpP: true,
                                        PoP: true,
                                        BiP: true,
                                        BDy: true,
                                        DPt: true,
                                        NPt: true,
                                        Ho3: true,
                                        StD: true,
                                        PtD: true,
                                        On1: true,
                                        HxH: true,
                                        Pt6: true,
                                        St6: true,
                                        OLt: true,
                                    })
                                }
                            >
                                <Tooltip label="Disp All">Par</Tooltip>
                            </Box>{' '}
                        </Th>
                        {teamMemberOrder.map((i, n) => {
                            if (members[i].name != '')
                                return (
                                    <>
                                        {checkedItems.st && (
                                            <Th {...styleTop} left="0">
                                                ST
                                            </Th>
                                        )}
                                        {checkedItems.sd && (
                                            <Th {...styleTop} left="0">
                                                SD
                                            </Th>
                                        )}
                                        {checkedItems.pt && (
                                            <Th {...styleTop} left="0">
                                                PT
                                            </Th>
                                        )}
                                        {checkedItems.ol && (
                                            <Th {...styleTop} left="0">
                                                OL
                                            </Th>
                                        )}
                                        {checkedItems.OLt && (
                                            <Th {...styleTopResult} left="0">
                                                <Tooltip label="Olympic 縛り含む">OLt</Tooltip>
                                            </Th>
                                        )}

                                        {n % 2 == 1 && (
                                            <>
                                                {checkedItems.SxS && (
                                                    <Th {...styleTop2} left="0">
                                                        <Tooltip label="Stroke x Stroke">
                                                            SxS
                                                        </Tooltip>
                                                    </Th>
                                                )}
                                                {checkedItems.SDpSD && (
                                                    <Th {...styleTop2} left="0">
                                                        <Tooltip label="Std + Std">SDp</Tooltip>
                                                    </Th>
                                                )}
                                                {checkedItems.PpP && (
                                                    <Th {...styleTop2} left="0">
                                                        <Tooltip label="Putt + Putt">PpP</Tooltip>
                                                    </Th>
                                                )}
                                                {checkedItems.HxH && (
                                                    <Th {...styleTopResult} left="0">
                                                        <Tooltip label="Hole by Hole Point">
                                                            HxH
                                                        </Tooltip>
                                                    </Th>
                                                )}
                                                {checkedItems.OLt && (
                                                    <Th {...styleTopResult} left="0">
                                                        <Tooltip label="OL (Team計-最下位)/2">
                                                            OLM
                                                        </Tooltip>
                                                    </Th>
                                                )}
                                                {checkedItems.Pt6 && (
                                                    <Th {...styleTopResult} left="0">
                                                        <Tooltip label="Putt計 6打">Pt6</Tooltip>
                                                    </Th>
                                                )}
                                                {checkedItems.St6 && (
                                                    <Th {...styleTopResult} left="0">
                                                        <Tooltip label="StD計 6打">St6</Tooltip>
                                                    </Th>
                                                )}
                                            </>
                                        )}
                                    </>
                                )
                        })}
                    </Tr>
                </Thead>
                <Tbody>
                    {teamScores.map((teamScore, i) => {
                        let ip = (i % 9) + 1
                        const half = i < 9 ? first_half : second_half
                        const pa = course_dts
                            .filter((dts) => dts.half_name == half && dts.half_num == ip)
                            .shift().par
                        const hole = course_dts
                            .filter((dts) => dts.half_name == half && dts.half_num == ip)
                            .shift().hole_num

                        return (
                            <>
                                <Tr>
                                    <Td {...styleHole}>{hole}</Td>
                                    <Td {...stylePar}>{pa}</Td>
                                    {teamMemberOrder.map((odr, n) => {
                                        if (members[odr].name != '')
                                            return (
                                                <>
                                                    {checkedItems.st && (
                                                        <Td {...styleDt}>
                                                            {scores[i][odr].stroke}
                                                        </Td>
                                                    )}
                                                    {checkedItems.sd && (
                                                        <Td {...styleDt}>
                                                            {getStDelta(scores[i][odr].st_delta)}
                                                        </Td>
                                                    )}
                                                    {checkedItems.pt && (
                                                        <Td {...styleDt}>{scores[i][odr].putt}</Td>
                                                    )}
                                                    {checkedItems.ol && (
                                                        <Td {...styleDt}>
                                                            {getOL(scores[i][odr].ol, pa)}
                                                        </Td>
                                                    )}
                                                    {checkedItems.OLt && (
                                                        <Td {...styleDt}>
                                                            {scores[i][odr].OLt != 0
                                                                ? scores[i][odr].OLt
                                                                : ''}
                                                        </Td>
                                                    )}
                                                    {n % 2 == 1 && (
                                                        <>
                                                            {checkedItems.SxS && (
                                                                <Td {...styleDt}>
                                                                    {teamScore[(n + 1) / 2 - 1].SxS}
                                                                </Td>
                                                            )}
                                                            {checkedItems.SDpSD && (
                                                                <Td {...styleDt}>
                                                                    {
                                                                        teamScore[(n + 1) / 2 - 1]
                                                                            .SDpSD
                                                                    }
                                                                </Td>
                                                            )}
                                                            {checkedItems.PpP && (
                                                                <Td {...styleDt}>
                                                                    {teamScore[(n + 1) / 2 - 1].PpP}
                                                                </Td>
                                                            )}
                                                            {checkedItems.HxH && (
                                                                <Td {...styleDt}>
                                                                    {teamScore[(n + 1) / 2 - 1].HxH}
                                                                </Td>
                                                            )}
                                                            {checkedItems.OLt && (
                                                                <Td {...styleDt}>-</Td>
                                                            )}
                                                            {checkedItems.Pt6 && (
                                                                <Td {...styleDt}>
                                                                    {teamScore[(n + 1) / 2 - 1].Pt6}
                                                                </Td>
                                                            )}
                                                            {checkedItems.St6 && (
                                                                <Td {...styleDt}>
                                                                    {teamScore[(n + 1) / 2 - 1].St6}
                                                                </Td>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )
                                    })}
                                </Tr>
                                {i == 8 && (
                                    <Tr>
                                        <Td {...styleHole}>{first_half}</Td>
                                        <Td {...stylePar}></Td>
                                        {teamMemberOrder.map((odr, n) => {
                                            if (members[odr].name != '')
                                                return (
                                                    <>
                                                        {checkedItems.st && (
                                                            <Td {...styleName}>
                                                                {sumScores[odr][0].stroke}
                                                            </Td>
                                                        )}
                                                        {checkedItems.sd && (
                                                            <Td {...styleName}>
                                                                {sumScores[odr][0].st_delta}
                                                            </Td>
                                                        )}
                                                        {checkedItems.pt && (
                                                            <Td {...styleName}>
                                                                {sumScores[odr][0].putt}
                                                            </Td>
                                                        )}
                                                        {checkedItems.ol && (
                                                            <Td {...styleName}>
                                                                {sumScores[odr][0].ol}
                                                            </Td>
                                                        )}
                                                        {checkedItems.OLt && (
                                                            <Th {...styleName}>
                                                                {sumScores[odr][0].OLt}
                                                            </Th>
                                                        )}
                                                        {n % 2 == 1 && (
                                                            <>
                                                                {checkedItems.SxS && (
                                                                    <Td {...styleName}></Td>
                                                                )}
                                                                {checkedItems.SDpSD && (
                                                                    <Td {...styleName}>
                                                                        {
                                                                            sumTeamScores[
                                                                                (n + 1) / 2 - 1
                                                                            ][0].SDpSD
                                                                        }
                                                                    </Td>
                                                                )}
                                                                {checkedItems.PpP && (
                                                                    <Td {...styleName}></Td>
                                                                )}
                                                                {checkedItems.HxH && (
                                                                    <Td {...styleName}>
                                                                        {
                                                                            sumTeamScores[
                                                                                (n + 1) / 2 - 1
                                                                            ][0].HxH
                                                                        }
                                                                    </Td>
                                                                )}
                                                                {checkedItems.OLt && (
                                                                    <Td {...styleName}>-</Td>
                                                                )}
                                                                {checkedItems.Pt6 && (
                                                                    <Td {...styleName}>
                                                                        {
                                                                            sumTeamScores[
                                                                                (n + 1) / 2 - 1
                                                                            ][0].Pt6
                                                                        }
                                                                    </Td>
                                                                )}
                                                                {checkedItems.St6 && (
                                                                    <Td {...styleName}>
                                                                        {
                                                                            sumTeamScores[
                                                                                (n + 1) / 2 - 1
                                                                            ][0].St6
                                                                        }
                                                                    </Td>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )
                                        })}
                                    </Tr>
                                )}
                                {i == 17 && (
                                    <>
                                        <Tr>
                                            <Td {...styleHole}>{second_half}</Td>
                                            <Td {...stylePar}></Td>
                                            {teamMemberOrder.map((odr, n) => {
                                                if (members[odr].name != '')
                                                    return (
                                                        <>
                                                            {checkedItems.st && (
                                                                <Td {...styleName}>
                                                                    {sumScores[odr][1].stroke}
                                                                </Td>
                                                            )}
                                                            {checkedItems.sd && (
                                                                <Td {...styleName}>
                                                                    {sumScores[odr][1].st_delta}
                                                                </Td>
                                                            )}
                                                            {checkedItems.pt && (
                                                                <Td {...styleName}>
                                                                    {sumScores[odr][1].putt}
                                                                </Td>
                                                            )}
                                                            {checkedItems.ol && (
                                                                <Td {...styleName}>
                                                                    {sumScores[odr][1].ol}
                                                                </Td>
                                                            )}
                                                            {checkedItems.OLt && (
                                                                <Th {...styleName}>
                                                                    {sumScores[odr][1].OLt}
                                                                </Th>
                                                            )}
                                                            {n % 2 == 1 && (
                                                                <>
                                                                    {checkedItems.SxS && (
                                                                        <Td {...styleName}></Td>
                                                                    )}
                                                                    {checkedItems.SDpSD && (
                                                                        <Td {...styleName}>
                                                                            {
                                                                                sumTeamScores[
                                                                                    (n + 1) / 2 - 1
                                                                                ][1].SDpSD
                                                                            }
                                                                        </Td>
                                                                    )}
                                                                    {checkedItems.PpP && (
                                                                        <Td {...styleName}></Td>
                                                                    )}
                                                                    {checkedItems.HxH && (
                                                                        <Td {...styleName}>
                                                                            {
                                                                                sumTeamScores[
                                                                                    (n + 1) / 2 - 1
                                                                                ][1].HxH
                                                                            }
                                                                        </Td>
                                                                    )}
                                                                    {checkedItems.OLt && (
                                                                        <Td {...styleName}>-</Td>
                                                                    )}
                                                                    {checkedItems.Pt6 && (
                                                                        <Td {...styleName}>
                                                                            {
                                                                                sumTeamScores[
                                                                                    (n + 1) / 2 - 1
                                                                                ][1].Pt6
                                                                            }
                                                                        </Td>
                                                                    )}
                                                                    {checkedItems.St6 && (
                                                                        <Td {...styleName}>
                                                                            {
                                                                                sumTeamScores[
                                                                                    (n + 1) / 2 - 1
                                                                                ][1].St6
                                                                            }
                                                                        </Td>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )
                                            })}
                                        </Tr>
                                        <Tr>
                                            <Td {...styleHole}>TTL</Td>
                                            <Td {...stylePar}></Td>
                                            {teamMemberOrder.map((odr, n) => {
                                                if (members[odr].name != '')
                                                    return (
                                                        <>
                                                            {checkedItems.st && (
                                                                <Td {...styleName}>
                                                                    {sumScores[odr][2].stroke}
                                                                </Td>
                                                            )}
                                                            {checkedItems.sd && (
                                                                <Td {...styleName}>
                                                                    {sumScores[odr][2].st_delta}
                                                                </Td>
                                                            )}
                                                            {checkedItems.pt && (
                                                                <Td {...styleName}>
                                                                    {sumScores[odr][2].putt}
                                                                </Td>
                                                            )}
                                                            {checkedItems.ol && (
                                                                <Td {...styleName}>
                                                                    {sumScores[odr][2].ol}
                                                                </Td>
                                                            )}
                                                            {checkedItems.OLt && (
                                                                <Th {...styleName}>
                                                                    {sumScores[odr][2].OLt}
                                                                </Th>
                                                            )}
                                                            {n % 2 == 1 && (
                                                                <>
                                                                    {checkedItems.SxS && (
                                                                        <Td {...styleName}></Td>
                                                                    )}
                                                                    {checkedItems.SDpSD && (
                                                                        <Td {...styleName}>
                                                                            {
                                                                                sumTeamScores[
                                                                                    (n + 1) / 2 - 1
                                                                                ][2].SDpSD
                                                                            }
                                                                        </Td>
                                                                    )}
                                                                    {checkedItems.PpP && (
                                                                        <Td {...styleName}></Td>
                                                                    )}
                                                                    {checkedItems.HxH && (
                                                                        <Td {...styleName}>
                                                                            {
                                                                                sumTeamScores[
                                                                                    (n + 1) / 2 - 1
                                                                                ][2].HxH
                                                                            }
                                                                        </Td>
                                                                    )}
                                                                    {checkedItems.OLt && (
                                                                        <Td {...styleName}>
                                                                            {
                                                                                sumTeamScores[
                                                                                    (n + 1) / 2 - 1
                                                                                ][2].OLM
                                                                            }
                                                                        </Td>
                                                                    )}
                                                                    {checkedItems.Pt6 && (
                                                                        <Td {...styleName}>
                                                                            {
                                                                                sumTeamScores[
                                                                                    (n + 1) / 2 - 1
                                                                                ][2].Pt6
                                                                            }
                                                                        </Td>
                                                                    )}
                                                                    {checkedItems.St6 && (
                                                                        <Td {...styleName}>
                                                                            {
                                                                                sumTeamScores[
                                                                                    (n + 1) / 2 - 1
                                                                                ][2].St6
                                                                            }
                                                                        </Td>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )
                                            })}
                                        </Tr>
                                    </>
                                )}
                            </>
                        )
                    })}
                </Tbody>
            </Table>
        )
    }

    function PartyTable() {
        return (
            <Table variant="simple" colorScheme="facebook" size="sm">
                <Thead>
                    <Tr>
                        <Th {...level3} left="0" top="0" color="white" bg="green.800">
                            HOLE
                        </Th>
                        <Th {...level3} left="45px" top="0" color="white" bg="green.700">
                            Par
                        </Th>
                        {members
                            .sort(function (a, b) {
                                return a.pty < b.pty ? -1 : 1
                            })
                            .map((member, n) => {
                                if (member.name != '')
                                    return (
                                        <>
                                            <Th key={'m0' + n} {...styleName} left="0">
                                                {member.name}
                                            </Th>
                                            <Th key={'m1' + n} {...styleName} left="0" />
                                        </>
                                    )
                            })}
                    </Tr>
                    <Tr>
                        <Th {...level3} left="0" top="40px" color="white" bg="green.800">
                            HOLE
                        </Th>
                        <Th {...level3} left="45px" top="40px" color="white" bg="green.700">
                            Par
                        </Th>
                        {members.map((member, n) => {
                            if (member.name != '')
                                return (
                                    <>
                                        <Th key={'ms0' + n} {...styleTop} left="0">
                                            ST
                                        </Th>
                                        <Th key={'ms1' + n} {...styleTop} left="0">
                                            Hor
                                        </Th>
                                    </>
                                )
                        })}
                    </Tr>
                </Thead>
                <Tbody>
                    {scores.map((hole_score, i) => {
                        let ip = (i % 9) + 1
                        const half = i < 9 ? first_half : second_half
                        const pa = course_dts
                            .filter((dts) => dts.half_name == half && dts.half_num == ip)
                            .shift().par
                        const hole = course_dts
                            .filter((dts) => dts.half_name == half && dts.half_num == ip)
                            .shift().hole_num

                        return (
                            <>
                                <Tr>
                                    <Td {...styleHole}>{hole}</Td>
                                    <Td {...stylePar}>{pa}</Td>
                                    {hole_score.map((member_score, n) => {
                                        if (members[n].name != '')
                                            return (
                                                <>
                                                    <Td key={'sc0' + n} {...styleDt}>
                                                        {member_score.stroke}
                                                    </Td>
                                                    <Td key={'sc1' + n} {...styleDt}>
                                                        {member_score.Ho3}
                                                    </Td>
                                                </>
                                            )
                                    })}
                                </Tr>
                                {i == 8 && (
                                    <Tr>
                                        <Td {...styleHole}>{first_half}</Td>
                                        <Td {...stylePar}></Td>
                                    </Tr>
                                )}
                                {i == 17 && (
                                    <>
                                        <Tr>
                                            <Td {...styleHole}>{second_half}</Td>
                                            <Td {...stylePar}></Td>
                                        </Tr>
                                        <Tr>
                                            <Td {...styleHole}>TTL</Td>
                                            <Td {...stylePar}></Td>
                                        </Tr>
                                    </>
                                )}
                            </>
                        )
                    })}
                </Tbody>
            </Table>
        )
    }

    function getStDelta(data) {
        var res
        switch (data) {
            case -2:
                res = '◎'
                break
            case -1:
                res = '◯'
                break
            case 0:
                res = '-'
                break
            case 1:
                res = '△'
                break
            case 2:
                res = '□'
                break
            case 3:
                res = '■'
                break
            default:
                res = '+' + data
                break
        }
        return res
    }
    function getOL(data) {
        var res
        switch (data) {
            case 1:
                res = '鉄'
                break
            case 2:
                res = '銅'
                break
            case 3:
                res = '銀'
                break
            case 4:
                res = '金'
                break
            case 5:
                res = 'ダ'
                break
            default:
                res = ''
                break
        }
        return res
    }
    function getDP(data, par) {
        if (par > 3 && data == 1) return 'DP'
        else if (par == 3 && data == 2) return 'NP'
        else return ''
    }
    function getFW(data, par) {
        var res
        if (par > 3) {
            switch (data) {
                case 1:
                    res = 'FW'
                    break
                case 2:
                    res = '右'
                    break
                case 3:
                    res = '左'
                    break
                case 4:
                    res = '右OB'
                    break
                case 5:
                    res = '左OB'
                    break
                case 6:
                    res = '☒'
                    break
                default:
                    res = ''
                    break
            }
        } else {
            switch (data) {
                case 1:
                    res = '(1on)'
                    break
                case 2:
                    res = '(右)'
                    break
                case 3:
                    res = '(左)'
                    break
                case 4:
                    res = '(Short)'
                    break
                case 5:
                    res = '(Over)'
                    break
                case 6:
                    res = '☒'
                    break
                default:
                    res = ''
                    break
            }
        }
        return res
    }
    //
    // Style Definitions
    //
    const styleHole = {
        pos: 'sticky',
        zIndex: '4',
        left: '0',
        color: 'white',
        bg: 'green.600',
        textAlign: 'center',
        px: '0',
        py: '1',
        m: '0',
        fontSize: '22',
        //fontSize: 'md',
        //px: '2px',
    }
    const stylePar = {
        pos: 'sticky',
        zIndex: '4',
        left: '45px',
        color: 'white',
        bg: 'green.500',
        textAlign: 'center',
        px: '0',
        py: '1',
        m: '0',
        fontSize: '22',
        //fontSize: 'md',
        //px: '2px',
    }

    const styleName = {
        pos: 'sticky',
        zIndex: '3',
        top: '0',
        bg: 'green.100',
        textAlign: 'center',
        fontSize: '22',
        lineHeight: '1.0',
        p: '0',
        m: '0',
        //px: '2px',
        //py: '4px',
    }
    //const styleName2 = { pos: "sticky", zIndex: "1", top: '0', colSpan: '1', bg: "green.100" }
    const styleTop = {
        pos: 'sticky',
        zIndex: '4',
        top: '40px',
        bg: 'green.100',
        textAlign: 'center',
        px: '1',
        py: '0',
        m: '0',
        fontSize: '22',
        //fontSize: 'md',
    }
    const styleTop2 = {
        pos: 'sticky',
        zIndex: '4',
        top: '40px',
        bg: 'green.300',
        textAlign: 'center',
        p: '0',
        m: '0',
        fontSize: '22',
        //fontSize: 'md',
    }
    const styleTopResult = {
        pos: 'sticky',
        zIndex: '4',
        top: '40px',
        color: 'white',
        bg: 'green.500',
        textAlign: 'center',
        fontSize: 'md',
        px: '2px',
    }
    const styleDt = {
        textAlign: 'center',
        zIndex: '2',
        color: 'gray.600',
        bg: 'white',
        p: '0',
        m: '0',
        fontSize: '22',
    }
    const level1 = { pos: 'sticky', zIndex: '1', fontSize: 'md' }
    const level3 = {
        pos: 'sticky',
        zIndex: '5',
        bg: 'green.300',
        textAlign: 'center',
        fontSize: 'sm',
        px: '10px',
    }
    /*
    const stylePointDt = {
        pos: 'sticky',
        bg: 'yellow.100',
        textAlign: 'center',
        fontSize: 'md',
    }
    const styleRefDt = { pos: 'sticky', textAlign: 'center', fontSize: 'md' }
    const styleResult1 = {
        pos: 'sticky',
        zIndex: '3',
        top: '0',
        color: 'white',
        bg: 'green.500',
        textAlign: 'center',
        fontSize: 'md',
    }
    const styleResult2 = {
        pos: 'sticky',
        zIndex: '3',
        color: 'white',
        bg: 'green.600',
        textAlign: 'center',
        fontSize: 'md',
    }
    const styleTotalTh = { pos: 'sticky', zIndex: '3', bg: 'green.300' }
    const styleTotalTd = { pos: 'sticky', zIndex: '3', bg: 'green.300' }
    const styleSubTotalTh = { pos: 'sticky', zIndex: '3', bg: 'green.300' }
    const styleSubTotalTd = { pos: 'sticky', zIndex: '3', bg: 'green.300' }
    const level2 = { pos: 'sticky', zIndex: '2', bg: 'green.100' }
    */

    return (
        <Stack pos="relative" top="43px">
            <Flex pos="fixed">
                <Box mx={2}>( {course_name} )</Box>
                <Spacer />
                {/* <Link to={'/Round/' + rid}>
                    <IconButton h={6} w={12} color="gray.700"
                        icon={<ArrowBackIcon />} />
                </Link>
                <IconButton
                    h={6}
                    w={12}
                    color="gray.700"
                    icon={<ArrowBackIcon />}
                    onClick={() => {
                        window.history.back()
                    }}
                />
                */}
                <Button m="0" h={8} colorScheme="teal" onClick={() => window.history.back()}>
                    Back
                </Button>
                <DrawerRegulationSettings
                    checkedRules={checkedRules}
                    setCheckedRules={setCheckedRules}
                />
                <DrawerDispSettings checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
                {
                    /* TABの表示ごとに QuickSettings の menu を変える
                  個人 */ tabId == 0 && (
                        <DrawerDispQuickSettings0
                            checkedItems={checkedItems}
                            setCheckedItems={setCheckedItems}
                        />
                    )
                }
                {
                    /* TEAM */ tabId == 1 && (
                        <DrawerDispQuickSettings1
                            checkedItems={checkedItems}
                            setCheckedItems={setCheckedItems}
                        />
                    )
                }
                {/* THRU  tabId == 3 && <DrawerDispQuickSettings3 checkedRules={checkedRules} setCheckedRules={setCheckedRules} /> */}
                {/*  Team の組合せを一時的に変更できるようにすると面白いかも ***** Pending *****
                {isLoading ? <Spinner /> :
                    <DrawerTeams
                        checkedItems={checkedItems} setCheckedItems={setCheckedItems}
                        playMode={playMode}  memberList={members} />
                }
                */}
            </Flex>

            {/* Point Table */}
            {isLoading ? (
                <Spinner />
            ) : (
                <Tabs
                    defaultIndex={0}
                    onChange={(index) => {
                        setTabId(index)
                        if (index == 1) {
                            // Team 表示では HxH をまずは表示
                            setCheckedItems({
                                ...checkedItems,
                                st: true,
                                sd: false,
                                pt: false,
                                ol: false,
                                dp: false,
                                fw: false,
                                ob: false,
                                bk: false,
                                pe: false,
                                ho: false,
                                SxS: false,
                                SDpSD: false,
                                PpP: false,
                                PoP: false,
                                BiP: false,
                                BDy: false,
                                DPt: false,
                                NPt: false,
                                Ho3: false,
                                StD: false,
                                PtD: false,
                                On1: false,
                                HxH: true,
                                Pt6: false,
                                St6: false,
                                OLt: false,
                            })
                        } else {
                            setCheckedItems(defaultCheckedItems)
                        }
                        console.log('TAB Id: ', index)
                    }}
                >
                    <TabList pos="fixed" top="70px">
                        <Tab {...level1} _selected={{ color: 'white', bg: 'blue.500' }}>
                            個人
                        </Tab>
                        <Tab {...level1} _selected={{ color: 'white', bg: 'blue.500' }}>
                            Team
                        </Tab>
                        <Tab {...level1} _selected={{ color: 'white', bg: 'blue.500' }}>
                            Pty
                        </Tab>
                        <Tab {...level1} _selected={{ color: 'white', bg: 'cyan.600' }}>
                            {/* THRU Table ============ */}
                            <Link to={thru_path}>THRU</Link>
                        </Tab>
                        <Link to={presen_path}>
                            <Image w={7} h={7} src="/P-icon.png"></Image>
                        </Link>
                    </TabList>
                    <TabPanels pos="relative" top="40px">
                        <TabPanel>
                            {/* Indivisual Table ============ */}
                            <IndivisualPtTable />
                        </TabPanel>
                        <TabPanel>
                            {/* Team Table ================== */}
                            <TeamPtTable />
                        </TabPanel>
                        <TabPanel>
                            {/* Party Table ================= */}
                            <PartyTable />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            )}
        </Stack>
    )
}
