/*
    Score Entry

    Nov.03,2024 : Layout 調整
    Nov.01,2024 : plusMinusMode 変更ボタンを BoxTotal に移動，HStack spacing 設定
    Oct.29,2024 : MidBody を merge
                  BoxMemo を DrawMemo に変更
    Oct.28,2024 : code 整理
    Feb.27,2024 : TeeShot は BoxScore に移動
    Jul.18,2023 : plusMinusMode 対応
    Dec.06,2021 : Nav fixed 対応
    Apr.12,2021 : PointBoard を TopBody から，こちらの Bottom に移動,
                  Leader Board もエントリを追加した

*/
import React, { useContext } from 'react'
import { Box, Button, Flex, Stack, Spacer, HStack, VStack, Center } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import { PartyContext } from '../pages/Score'

import TopBody from './TopBody'
import BoxScore from './BoxScore'
import DrawOBPE from './DrawOBPE'
import DrawMemo from './DrawMemo'
import DrawExtSite from './DrawExtSite'

export default function ScoreBoard(props) {
    const { course, inputUid, partyData, parties } = useContext(PartyContext)

    const point_path = {
        pathname: '/Point/' + partyData.t_round,
        // t_round = round_id in this case
        state: {
            course: course,
            parties: parties,
        },
    }

    return (
        <Stack pos="relative" top="32px" spacing={1}>
            <TopBody />
            <HStack spacing={1}>
                <Box w="25%">
                    <BoxScore key="s1" id="u1" uid="1" name={partyData.player1_name} />
                </Box>
                <Box w="25%">
                    <BoxScore key="s2" id="u2" uid="2" name={partyData.player2_name} />
                </Box>
                <Box w="25%">
                    <BoxScore key="s3" id="u3" uid="3" name={partyData.player3_name} />
                </Box>
                <Box w="25%">
                    <BoxScore key="s4" id="u4" uid="4" name={partyData.player4_name} />
                </Box>
            </HStack>
            {/* OB/PE/BK */}
            <DrawOBPE />
            {/* Memo */}
            <DrawMemo uid={inputUid} />
            <Flex>
                {/* Leaders Board / Point Board */}
                <Spacer />
                <DrawExtSite />
                <Link to={point_path}>
                    <Button colorScheme="yellow">Pt</Button>
                </Link>
            </Flex>
        </Stack>
    )
}
