/*
  これの出典は以下
  https://dev.to/d__raptis/build-a-landing-page-with-chakra-ui-part-1-56gd

    Nov.13,2021 : Regulation 追加
    Apr.26,2021 : Logo の Link が2重になって Warning が出ていたのを修正
                  component Logo は不要．埋込みとした．
*/

import React from "react"
import { Link } from "react-router-dom"
import { Box, Flex, Text, Button, HStack, Image } from "@chakra-ui/react"
//import Logo from "./Logo"

import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'

import config from '../pages/const';

const MenuItems = props => {
    const { children, isLast, to = "/", ...rest } = props
    return (
        <Text
            mb={{ base: isLast ? 0 : 8, sm: 0 }}
            mr={{ base: 0, sm: isLast ? 0 : 8 }}
            display="block"
            {...rest}
        >
            <Link to={to}>{children}</Link>
        </Text>
    )
}

const Header2 = props => {
    const [show, setShow] = React.useState(false)
    const toggleMenu = () => setShow(!show)

    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            mb={1}
            p={1}
            bg={["gray.500", "gray.500", "gray.500", "gray.400"]}
            color={["white", "white", "white", "gray.700"]}
            pos="fixed"
            top='0'
            left='0'
            zIndex='1'
            {...props}
        >
            <Flex align="center">
                <HStack>
                    <Link to="/">
                        <Image boxSize="28px" objectFit="cover" src="/ura.png"></Image>
                    </Link>
                    <Text fontSize="sm">Ura Pj2.: {config.REVISION} </Text>
                </HStack>
            </Flex>

            <Box display={{ base: "block", md: "none" }} onClick={toggleMenu}>
                {show ? <CloseIcon /> : <HamburgerIcon />}
            </Box>

            <Box
                display={{ base: show ? "block" : "none", md: "block" }}
                flexBasis={{ base: "100%", md: "auto" }}
            >
                <Flex
                    align={["center", "center", "center", "center"]}
                    justify={["center", "space-between", "flex-end", "flex-end"]}
                    direction={["column", "row", "row", "row"]}
                    pt={[4, 4, 0, 0]}
                >
                    <MenuItems to="/About">About </MenuItems>
                    <MenuItems to="/Regulation">Regulation </MenuItems>
                    <MenuItems to="/ReleaseNote">ReleaseNote </MenuItems>
                    <MenuItems to="/Issues">Known Issues</MenuItems>
                    <MenuItems to="/UserManual">Manual</MenuItems>
                    <MenuItems to="/Login" isLast>
                        <Button
                            size="sm"
                            rounded="md"
                            color={["primary.500", "primary.500", "white", "white"]}
                            bg={["gray.400", "gray.400", "gray.400.500", "gray.400"]}
                            _hover={{
                                bg: [
                                    "primary.100",
                                    "primary.100",
                                    "primary.600",
                                    "primary.600",
                                ],
                            }}
                        >
                            Login
                        </Button>
                    </MenuItems>
                </Flex>
            </Box>
        </Flex>
    )
}

export default Header2