/*
Box Score

ひとり分の Score の表示(ttl, st, pt, ol, dpnp)

Nov.03,2024 : layout 調整
Nov.02,2024 : 文字列 PlayerName / Pt が不用意に選択されて，うざいので選択不可に sx css を設定
Oct.28,2024 : DrawOBPE を単独ファイルに移動
Oct.01,2024 : OE/PE/BK を Drawer で入力可とした．4人のデータ入力ができるようになった．
Feb.27,2024 : DPNP, TeeShot を全面変更．TeeShot で DPNP を兼用した
Jul.15,2023 : PT -> Pt fontsize 変更
Apr.19,2021 : 3人/Pty など名前が無い時の対応
Apr.15,2021 : st_delta +/- 表記に変更

*/
import React, { useContext } from 'react'
import { Box, Center, Flex, HStack, VStack, Stack } from '@chakra-ui/react'
import { StarIcon } from '@chakra-ui/icons'

import config from '../pages/const'
import BoxTotal from './BoxTotal'
import BoxDPNP from './BoxDPNP'
import BoxOL from './BoxOL'
import BoxSt from './BoxSt'
import BoxPt from './BoxPt'
import TeeShot from './TeeShot'

import { PartyContext } from '../pages/Score'

export default function BoxScore(props) {
    const {
        curHole,
        courseData,
        colorZone1,
        colorZone2,
        colorZone3,
        inputMode,
        inputUid,
        isOnLine,
    } = useContext(PartyContext)

    // Set Name plate bg Color
    var myZone = colorZone3
    if (inputUid === props.uid) myZone = colorZone1
    else if (inputMode === config.VOLUNTEER_MODE) myZone = colorZone2

    //console.log("BoxScore: uid)
    //console.log("BoxScore: SDATA : ", sdata[0].stroke)
    //const data = eval("sdata" + props.uid)
    //console.log("BoxScore: SDATA : ", data)
    //console.log("BoxScore: isOnLine : ", props.id, isOnLine, isOnLine[props.id])

    return (
        <VStack spacing={1}>
            {/* Player Name */}
            <Box
                fontSize={26}
                textAlign="center"
                color="white"
                bg={myZone}
                w="100%"
                sx={{
                    webkitUserSelect: 'none',
                    mozUserSelect: 'none',
                    webkitTouchCallout: 'none',
                    userSelect: 'none',
                }}
            >
                {props.name.length === 0 ? '-' : props.name}
                {inputMode === config.INDIVISUAL_MODE ? (
                    <StarIcon
                        color={isOnLine[props.id] === true ? 'yellow.300' : 'gray.600'}
                        w={3}
                    />
                ) : (
                    ''
                )}
            </Box>
            <Box w="100%">
                <BoxTotal uid={props.uid} zone={myZone} />
            </Box>
            {/* Stroke */}
            <BoxSt uid={props.uid} zone={myZone} />
            <HStack w="100%">
                <Center
                    fontSize={18}
                    color="gray.600"
                    w="30%"
                    sx={{
                        webkitUserSelect: 'none',
                        mozUserSelect: 'none',
                        webkitTouchCallout: 'none',
                        userSelect: 'none',
                    }}
                >
                    Pt
                </Center>
                {/* Putt */}
                <Box w="70%">
                    <BoxPt cat="putt" uid={props.uid} zone={myZone} />
                </Box>
            </HStack>
            <HStack w="100%">
                {/* DP */}
                <VStack w="50%" spacing="0">
                    <Center color="gray.600" fontSize={14}>
                        {courseData[curHole - 1].par == 3 ? 'NP' : 'DP'}
                    </Center>
                    <BoxDPNP uid={props.uid} zone={myZone} />
                </VStack>
                {/* OL */}
                <VStack w="50%" spacing="0">
                    <Center color="gray.600" fontSize={14}>
                        OL
                    </Center>
                    <BoxOL uid={props.uid} zone={myZone} />
                </VStack>
            </HStack>
            {/* TeeShot */}
            <Center h={3} color="gray.600" fontSize={14}>
                TeeShot
            </Center>
            <TeeShot uid={props.uid} zone={myZone} />
        </VStack>
    )
}
