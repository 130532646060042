/*
   Auth.js: 

   User で login を check する
   
   Nov.24,2021 : DEBUG mode なら auth を pass
*/
import React from 'react';
import { Redirect } from 'react-router-dom';
import User from './User';

const DEBUG = (process.env.REACT_APP_DEBUG == "DEBUG") ? true : false
console.log("AUTH: DEBUG : ", DEBUG)

const Auth = props =>
    User.isLoggedIn() || DEBUG ?
        props.children : <Redirect to={'/Login'} />;

export default Auth;
