/*
   Stroke Input
    props.uid に対応した scorePx に stroke を設定する．

    Input: PartyContext, uid
    Output: scorePx.stroke

    Mar.08,2025 : ±モードで未入力が分かるように，表示を '.' に変更
    Feb.26,2025 : ±モードのときの Par の表示を '-' に変更
    Nov.03,2024 : layout 調整
    Nov.01,2024 : plusMinusMode 対応，menuList の位置と幅を調整
    Jul.15,2023 : box & menulist fontsize 大きくした
    Aug.10,2021 : Lock時の Button Color 変更
    Apr.26,2021 : map の key 修正

*/
import React, { useState, useContext } from 'react'
import { Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'

import { PartyContext } from '../pages/Score'

export default function BoxSt(props) {
    // 基本 Box
    const {
        curHole,
        courseData,
        plusMinusMode,
        scoreP1,
        setScoreP1,
        scoreP2,
        setScoreP2,
        scoreP3,
        setScoreP3,
        scoreP4,
        setScoreP4,
        colorZone3,
    } = useContext(PartyContext)

    const scorePx = eval('scoreP' + props.uid)
    const setScorePx = eval('setScoreP' + props.uid)

    const menus = plusMinusMode
        ? [
              { label: '-3', value: -3 },
              { label: '-2', value: -2 },
              { label: '-1', value: -1 },
              { label: '-', value: 0 },
              { label: '+1', value: 1 },
              { label: '+2', value: 2 },
              { label: '+3', value: 3 },
              { label: '+4', value: 4 },
              { label: '+5', value: 5 },
              { label: '+6', value: 6 },
              { label: '+7', value: 7 },
              { label: '+8', value: 8 },
              { label: '+9', value: 9 },
              { label: '+10', value: 10 },
              { label: '+11', value: 11 },
          ]
        : [
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
              { label: '4', value: 4 },
              { label: '5', value: 5 },
              { label: '6', value: 6 },
              { label: '7', value: 7 },
              { label: '8', value: 8 },
              { label: '9', value: 9 },
              { label: '10', value: 10 },
              { label: '11', value: 11 },
              { label: '12', value: 12 },
              { label: '13', value: 13 },
              { label: '14', value: 14 },
              { label: '15', value: 15 },
          ]

    const updateValue = (e) => {
        setScorePx({
            ...scorePx,
            stroke: plusMinusMode
                ? Number(e.target.value) + courseData[curHole - 1].par
                : Number(e.target.value),
            st_delta: plusMinusMode
                ? Number(e.target.value)
                : Number(e.target.value) - courseData[curHole - 1].par,
        })
        console.log('St val = ', e.target.value)
    }
    const writeProtect = scorePx.lock || props.zone == colorZone3

    return (
        <Menu {...props} offset={[-30, -170]}>
            <MenuButton
                as={Button}
                bg={props.zone}
                color="white"
                w="100%"
                fontSize={44}
                _disabled={{ opacity: 0.8, color: 'white' }}
                _hover={{ _disabled: { bg: 'gray.200', color: 'gray.800' } }}
                isDisabled={writeProtect}
            >
                {plusMinusMode
                    ? scorePx.st_delta > 0
                        ? '+' + scorePx.st_delta
                        : (scorePx.st_delta == 0) & (scorePx.stroke == 0)
                        ? '.'
                        : scorePx.st_delta == 0
                        ? '-'
                        : scorePx.st_delta
                    : scorePx.stroke}
            </MenuButton>
            <MenuList
                maxHeight={400}
                overflowY="auto"
                fontSize={28}
                minW="0"
                w={'60px'}
                p={0}
                borderWidth="2px"
                borderColor="gray.300"
            >
                {menus.map((menu, n) => (
                    <MenuItem key={n} value={menu.value} px={2} py={0} onClick={updateValue}>
                        {menu.label}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}
