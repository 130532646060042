//
//  Dec.14,2023 : On1 3on = 2p に格上げ，DefaultRules に NetT を追加 @u23.12
//  Oct.17,2023 : URI は DB へ移管した @u23.10R
//  Dec.03,2021 : LINE 関連は環境変数 に移動
//  Apr.10,2021 : 環境変数 ./frontend/.env
//                REACT_APP_ENV = LOCAL / DOCKER で切替える
//               (本来はコードに条件分岐を書かずに
//                環境変数のみで対応できるようにすべきである)
//
// Object.freezeを使用しないと外部で値を書き換えることが出来る
// Object.freezeはネストしたオブジェクトまで見ないので、
// Objectの中でObjectを宣言する場合、宣言するObjectにもObject.freezeをつける
/*
TODO

- tee shot の分解能を上げる
- 各個人の fw keep 率を取れるようにする
- Par3 の着地点を取る
- putt のデータを取れるようにする
*/
export default Object.freeze({
    REVISION: 'u24.11b  Nov.04,2024',
    // Team Mode
    MODE2: 2, // team mode 2p/team
    MODE4: 4, // team mode 4p/team

    // Input Mode
    VOLUNTEER_MODE: 4,
    INDIVISUAL_MODE: 1,

    // websocket status
    WS_CONNECTING: 0,
    WS_OPEN: 1,
    WS_CLOSING: 2,
    WS_CLOSED: 3,
    //
})
