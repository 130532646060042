/*
    Create / Update Round
        /AddRound/:rid
        state: date, course_id, course_name

    Dec.06,2021 : Nav fixed 対応
    Dec.02,2021 : 環境変数を config から .env に変更
    Nov.30,2021 : delete に確認 Dialog を追加
    Jul.04,2021 : react-hook-form の register の使い方が変更されていた
    Apr.14,2021 : Submit 後に Back を入れた, Cancel Button 追加
    Apr.10,2021 : API_ORIGIN を config に

*/
import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
    Box,
    Text,
    Flex,
    FormLabel,
    FormControl,
    Input,
    Button,
    Stack,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react'
import { useParams, useLocation } from 'react-router-dom'

import config from './const'

const API_ORIGIN = process.env.REACT_APP_BESVR_URI_ORIGIN

const AlertDialogDelete = (props) => {
    const [isOpen, setIsOpen] = useState(false)

    const onClose = () => setIsOpen(false)
    const cancelRef = useRef()

    const handleDelete = async (e) => {
        // Update Round
        fetch(API_ORIGIN + '/api/round/' + props.id, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        })
        console.log('DELETE: rid: ', props.id)
        onClose()
        window.history.go(-2)
    }

    return (
        <>
            <Button mt={props.mt} colorScheme={props.colorScheme} onClick={() => setIsOpen(true)}>
                Delete Round
            </Button>

            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Round
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? 関連する Party/Score は削除されません． You can't undo
                            this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme={props.colorScheme} onClick={handleDelete} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export function AddRound(props) {
    const [courses, setCourses] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const { handleSubmit, errors, register, formState } = useForm()
    const { rid } = useParams()
    const location = useLocation()

    const rdate = location.state ? location.state.date : ''
    const rcourse_id = location.state ? location.state.course_id : ''
    const rcourse_name = location.state ? location.state.course_name : ''
    const s_name = location.state ? location.state.s_name : ''

    if (rid == undefined) {
        console.log('AddRound: Undefined rid: ')
    } else {
        console.log('AddRound: rid: ', rid)
    }
    //
    //
    const onSubmit = (values) => {
        console.log('form =', values)
        if (rid == undefined) {
            // Create New Round
            fetch(API_ORIGIN + '/api/round', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values),
            })
        } else {
            // Update Round
            fetch(API_ORIGIN + '/api/round/' + rid, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values),
            })
        }
        window.history.back()
    }

    const handleCancel = (e) => {
        window.history.back()
    }

    const onClickDelete = (e) => {
        fetch(API_ORIGIN + '/api/round/' + rid, {
            method: 'DELETE',
        })
        window.history.back()
    }
    //
    // Get All Course List
    //
    useEffect(() => {
        async function fetchCourses() {
            setIsLoading(true)
            const response = await fetch(API_ORIGIN + '/api/course')
            const resps = await response.json()
            setCourses(resps)
            setIsLoading(false)
            console.log('courses :', resps)
        }
        fetchCourses()
    }, [])
    //   <form onSubmit={handleSubmit(onSubmit)}>
    return (
        <Stack pos="relative" top="43px" zIndex="2" bg="white">
            <Box m="14px">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl isRequired>
                        <FormLabel htmlFor="date">Date</FormLabel>
                        <Input
                            name="date"
                            type="date"
                            format="yyyy/mm/dd"
                            w={200}
                            {...register('date')}
                            defaultValue={rdate}
                        />
                    </FormControl>
                    <Flex>
                        <FormControl isRequired>
                            <FormLabel htmlFor="course_id">Course ID</FormLabel>
                            <Flex>
                                <Input
                                    name="course_id"
                                    placeholder="番号"
                                    {...register('course_id')}
                                    w={100}
                                    mr={10}
                                    defaultValue={rcourse_id}
                                />
                                <Text pt={3}>{rcourse_name}</Text>
                            </Flex>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="s_name">Round Nick Name(opt)</FormLabel>
                            <Input
                                name="s_name"
                                defaultValue={s_name}
                                placeholder="ura etc"
                                {...register('s_name')}
                                w={200}
                                mr={10}
                            />
                        </FormControl>
                    </Flex>
                    <Button
                        mt={4}
                        colorScheme="teal"
                        variant="outline"
                        onClick={handleCancel}
                        type="reset"
                    >
                        Cancel
                    </Button>
                    <AlertDialogDelete mt={4} colorScheme="orange" id={rid} />
                    {/*
                <Button mt={4} colorScheme="orange"
                    onClick={onClickDelete} >Delete</Button>
                */}
                    <Button
                        mt={4}
                        colorScheme="teal"
                        isLoading={formState.isSubmitting}
                        type="submit"
                    >
                        Submit
                    </Button>
                </form>
                {courses.map((value) => {
                    return (
                        <Text>
                            {value.id} : {value.course_name}
                        </Text>
                    )
                })}
            </Box>
        </Stack>
    )
}

/*
              ref={register()}
            <Text>{test} {courses[1].id} </Text>
                {courses.map((course) => {
                    return (
                        <Text>{course.id} : {course.course_name}</Text>
                    )
                })}

*/
