/*

    Dec.12,2021 : Presen ページ追加
    Dec.01,2021 : MgmtDB ページ追加
    Nov.13,2021 : Regulation ページ追加
    Apr.18,2021 : Auth は使ってないので削除
    Apr.10,2021 : Line Login 追加

*/
import React from 'react';
import { render } from 'react-dom';
import {
    BrowserRouter as Router,
    Route, Redirect, Switch,
} from "react-router-dom"
import { ChakraProvider } from "@chakra-ui/react";

import Auth from "./pages/Auth";
import Login from "./pages/Login";

import Home from "./pages/Home";
import { AddRound } from "./pages/AddRound";
import Round from "./pages/Round";
import Party from "./pages/Party";
import Score from "./pages/Score";
import Point from "./pages/Point";
import Presen from "./pages/Presen";
import MgmtDB from "./pages/MgmtDB";
import About from "./pages/About";
import Regulation from "./pages/Regulation";
import Issues from "./pages/Issues";
import ReleaseNote from "./pages/ReleaseNote";
import UserManual from "./pages/UserManual";
import TBD from "./pages/TBD";

import Header2 from "./components/Header2";


function App() {

    return (
        <ChakraProvider>
            <Router>
                <Header2 />
                <Switch>
                    <Route exact path="/Login" component={Login} />
                    <Auth>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/Round/:rid" component={Round} />
                        <Route exact path="/AddRound" component={AddRound} />
                        <Route path="/AddRound/:rid" component={AddRound} />
                        <Route exact path="/Party" component={Party} />
                        <Route path="/Party/:pid" component={Party} />
                        <Route path="/Score/:rid/:pid" component={Score} />
                        <Route exact path="/Score" component={Score} />
                        <Route path="/DB/:pid" component={MgmtDB} />
                        <Route path="/Point/:rid" component={Point} />
                        <Route path="/Presen" component={Presen} />
                        <Route path="/About" component={About} />
                        <Route path="/Regulation" component={Regulation} />
                        <Route path="/ReleaseNote" component={ReleaseNote} />
                        <Route path="/UserManual" component={UserManual} />
                        <Route path="/Issues" component={Issues} />
                        <Route path="/TBD" component={TBD} />
                    </Auth>
                </Switch>
            </Router>
        </ChakraProvider>
    )
}

const rootElement = document.getElementById("root")
render(<App />, rootElement)