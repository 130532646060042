/*
    Score 入力で Hole# を表示，curHole を選択する Top 部分
//
// input : PartyContext
// output : curHole

    Mar.18,2024 : 本人の Score を入れないと submit できなかったが，どのメンバの Score を入れても submit できるようにした．
    Feb.26,2024 : submit したときに，他メンバの lock が効いてないので，全員 lock する
    Feb.26,2024 : storoke を入れた に加えて，ts を入れたときにも submit するように変更
    Nov.10,2022 : Half 名が入るように ボタンを変更など,Layout 変更
    Nov.29,2021 : 自 Hole で Submit した時の Write Protect をこっちに移動
    Nov.17,2021 : processing を 500 -> 5000 に変更
    Nov.15,2021 : BoxHole は Lock 時に gray に変更
    Oct.31,2021 : コメント追加
    Oct.22,2021 : 2重登録防止ため processing Flag を追加．
    Aug.10,2021 : Lock Button bg Color 変更
    Apr.22,2021 : couse data の受け渡しを course に変更(course_id, course_name をまとめた)
    Apr.20,2021 : Lock Button を Score 登録を兼ねて Toggle に変更．
    Apr.14,2021 : yard, HDCP 表示追加．font color 変更
    Apr.13,2021 : 18 Hole 目で Next Hole Button したときに描画しないと
                  Score が 2度入る Bug のため，18 の次は 1 にした
*/
import React, { useContext, useRef } from 'react'
import { Center, IconButton, HStack, Spacer, Text, Flex } from '@chakra-ui/react'
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    LockIcon,
    UnlockIcon,
} from '@chakra-ui/icons'
import { Link } from 'react-router-dom'

import BoxLB from './BoxLeaderBoard'
import BoxHole from '../components/BoxHole'

import { PartyContext } from '../pages/Score'

export default function TopBody(props) {
    const {
        curHole,
        setCurHole,
        inputUid,
        partyData,
        isUpdate,
        setIsUpdate,
        scoreP1,
        setScoreP1,
        scoreP2,
        setScoreP2,
        scoreP3,
        setScoreP3,
        scoreP4,
        setScoreP4,
        courseData,
        course,
        handleSubmit,
    } = useContext(PartyContext)
    //console.log("TopBody courseData", courseData)
    const scorePx = eval('scoreP' + inputUid)
    const setScorePx = eval('setScoreP' + inputUid)

    //console.log ("TopBody: pn stroke", scorePx.stroke)

    const processing = useRef(false)
    //console.log("Processing: ", processing.current)

    const round_path = {
        pathname: '/Round/' + partyData.t_round,
    }

    return (
        <HStack>
            {/* curHole-- */}
            <IconButton
                bg="green.100"
                color="green.700"
                aria-label="Prev"
                icon={<ArrowLeftIcon />}
                h={10}
                w={7}
                size="sm"
                onClick={(e) => {
                    if (scorePx.lock == false && scorePx.stroke != 0) handleSubmit()
                    const newCur = curHole - 1
                    if (newCur > 0) setCurHole(newCur)
                    // console.log("Mainus: ", curHole)
                }}
            />
            {/* curHole 表示 */}
            <BoxHole
                bg={scorePx.lock === false ? 'green.600' : 'gray.300'}
                color={scorePx.lock === false ? 'white' : 'green.700'}
                update={scorePx.lock == false && scorePx.stroke != 0}
            />
            {/* curHole++ */}
            <IconButton
                bg="green.100"
                color="green.700"
                aria-label="Next"
                icon={<ArrowRightIcon />}
                h={10}
                w={7}
                size="sm"
                onClick={(e) => {
                    if (scorePx.lock == false && scorePx.stroke != 0) handleSubmit()
                    const newCur = curHole + 1
                    if (newCur <= 18) setCurHole(newCur)
                    else setCurHole(1)
                    console.log('Plus: ', curHole, newCur)
                }}
            />
            {/* Par, etc 表示 */}
            <Link to={round_path}>
                <Flex>
                    <Center fontSize={12} color="gray" ml={0} mr={2} my={0}>
                        Par.
                        <Text fontSize={21} color="gray.600" ml={1}>
                            {courseData ? courseData[curHole - 1].par : ''}
                        </Text>
                    </Center>
                    <Center fontSize={20} color="gray.600" mx={0} my={0}>
                        {courseData ? courseData[curHole - 1].yard : ''}
                        <Text fontSize={12} color="gray.600" mx={1} mt={1}>
                            yd
                        </Text>
                    </Center>
                </Flex>
                <Flex>
                    <Center fontSize={12} color="gray" my={0}>
                        HC:
                        <Text fontSize={16} color="gray.600" mt={0} ml={1}>
                            {courseData ? courseData[curHole - 1].res1 : ''}
                        </Text>
                    </Center>
                    <Center fontSize={10} color="gray.700" ml={2} mr={0}>
                        {course.course_name}{' '}
                    </Center>
                </Flex>
            </Link>
            <Spacer />
            <BoxLB size="sm" rid={partyData.t_round} />

            {/* Lock Button の Toggle */}
            {processing.current === true || scorePx.lock === true ? (
                <IconButton
                    colorScheme="orange"
                    aria-label="Edit"
                    size="lg"
                    icon={<LockIcon />}
                    onClick={(e) => {
                        setScoreP1({ ...scoreP1, lock: false })
                        setScoreP2({ ...scoreP2, lock: false })
                        setScoreP3({ ...scoreP3, lock: false })
                        setScoreP4({ ...scoreP4, lock: false })
                        processing.current = false
                        setIsUpdate(true) // Unlock => Update Mode
                    }}
                />
            ) : (
                <IconButton
                    colorScheme="teal"
                    aria-label="Edit"
                    size="lg"
                    icon={<UnlockIcon />}
                    onClick={(e) => {
                        if (
                            scoreP1.stroke != 0 ||
                            scoreP2.stroke != 0 ||
                            scoreP3.stroke != 0 ||
                            scoreP4.stroke != 0 ||
                            scoreP1.tee_shot > 0 ||
                            scoreP2.tee_shot > 0 ||
                            scoreP3.tee_shot > 0 ||
                            scoreP4.tee_shot > 0
                        ) {
                            // storoke を入れた OR teeShot を入れたときには update する
                            // 2重登録(2度押し)防止のための処理中フラグ．
                            // 正常に動作すれば reset されるはずだが，
                            // DB err など異常時は timer で reset
                            processing.current = true
                            console.log('SUBMIT')
                            setTimeout((processing.current = false), 5000)
                            handleSubmit() // 登録
                            // 自 Hole を選んだときにはこれで Write Protect するをこっちに移動
                            //setScorePx({ ...scorePx, lock: true })
                            // submit したときに，他メンバの lock が効いてない
                            setScoreP1({ ...scoreP1, lock: true })
                            setScoreP2({ ...scoreP2, lock: true })
                            setScoreP3({ ...scoreP3, lock: true })
                            setScoreP4({ ...scoreP4, lock: true })
                        }
                    }}
                />
            )}
        </HStack>
    )
}
