/*
    Nov.03,2024 : Layout 調整
    Nov.01,2024 : menuList の位置と幅を調整
    Jul.15,2023 : box & menulist fontsize 大きくした
    Aug.10,2021 : Lock時の Button Color 変更
    May.07,2021 : Diamond 時の Putt連携が，Type違いで出来てなかった．
    Apr.26,2021 : map の key 修正
*/
import React, { useContext } from 'react'
import { Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'

import { PartyContext } from '../pages/Score'

export default function BoxOL(props) {
    // 基本 Box
    const {
        scoreP1,
        setScoreP1,
        scoreP2,
        setScoreP2,
        scoreP3,
        setScoreP3,
        scoreP4,
        setScoreP4,
        colorZone3,
    } = useContext(PartyContext)

    const scorePx = eval('scoreP' + props.uid)
    const setScorePx = eval('setScoreP' + props.uid)

    //const [value, setValue] = useState(0);

    const menus = [
        { label: '-', value: 0 },
        { label: '鉄', value: 1 },
        { label: '銅', value: 2 },
        { label: '銀', value: 3 },
        { label: '金', value: 4 },
        { label: 'ダ', value: 5 },
    ]

    const updateValue = (e) => {
        const pt = e.target.value > 0 && e.target.value < 5 ? 1 : e.target.value === '5' ? 0 : 2
        setScorePx({
            ...scorePx,
            ol: Number(e.target.value),
            putt: pt,
        })
        console.log('OL val = ', e.target.value)
    }

    const writeProtect = scorePx.lock || props.zone === colorZone3

    return (
        <Menu {...props} offset={[-60, -135]}>
            <MenuButton
                as={Button}
                px={0}
                bg={props.zone}
                color="white"
                fontSize={30}
                w="100%"
                _disabled={{ opacity: 0.8, color: 'white' }}
                _hover={{ _disabled: { bg: 'gray.200', color: 'gray.800' } }}
                isDisabled={writeProtect}
            >
                {menus[scorePx.ol].label}
            </MenuButton>
            <MenuList fontSize={28} minW="0" w={'60px'} borderWidth="2px" borderColor="gray.300">
                {menus.map((menu, n) => (
                    <MenuItem key={n} value={menu.value} pt={0} pb={0} onClick={updateValue}>
                        {menu.label}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}
