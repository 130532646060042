/*
    MgmtDB : DB Mgmt

    Score の重複 Record 削除を目的とする

    pid: party id

    Dec.06,2021 : Nav fixed 対応
    Dec.02,2021 : 環境変数を config から .env に変更
    Dec.01,2021 : issued
*/
import React, { useState, useEffect, useRef } from "react";
import {
    Button, Text, Flex, Stack, Spinner,
    Table, Thead, Tbody, Tfoot, Tr, Th, Td,
    AlertDialog, AlertDialogBody, AlertDialogFooter,
    AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom"

const API_ORIGIN = process.env.REACT_APP_BESVR_URI_ORIGIN

const AlertDialogDelete = (props) => {
    //
    // Alert Dialog for Delete
    //
    const [isOpen, setIsOpen] = useState(false)

    const onClose = () => setIsOpen(false)
    const cancelRef = useRef()

    const handleDelete = async (e) => {
        // Update Party
        fetch(API_ORIGIN + "/api/score/" + props.id, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
        })
        console.log("DELETE: id: ", props.id)
        onClose()
        window.location.reload(false)
    }
    return (
        <>
            <Td>
                <Button mt={props.mt} colorScheme={props.colorScheme}
                    onClick={() => setIsOpen(true)}>
                    Delete
                </Button>
            </Td>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader
                            fontSize='lg' fontWeight='bold'>
                            Delete Record
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure?
                            Score をひとつ削除します．
                            You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef}
                                onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme={props.colorScheme}
                                onClick={handleDelete} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default function MgmtDB(props) {
    const { pid } = useParams();

    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    //
    // Get Non Uniq DB
    //
    const fetchRecords = async () => {
        setIsLoading(true)
        const response = await fetch(API_ORIGIN + "/api/db/check/" + pid)
        if (response.ok) {
            const resps = await response.json()
            console.log("resps :", resps)
            setData(resps)
            setIsLoading(false)
        }
    }
    useEffect(() => {
        fetchRecords()
    }, [])
    // onClick= window.history.back() 
    return (
        <Stack pos='relative' top='38px'>
            <Flex>
                <Text>重複レコードチェック</Text>
                <Button colorScheme="teal" mb={0} size="xs"
                    onClick={() => window.history.back()}>
                    戻る</Button>
            </Flex>

            {/* DB Table */}
            {(data === null) || isLoading ? <Spinner /> :
                <>
                    {data.length > 0 ?
                        <Text>重複レコードがあります．不要なレコードを削除してください．</Text> :
                        <Text>重複レコードはありませんでした．</Text>}
                    <Table variant="simple" colorScheme="facebook" size="sm" >
                        <Thead>
                            <Tr>
                                <Th>DELETE</Th>
                                <Th>RecID</Th>
                                <Th>uid</Th>
                                <Th>Name</Th>
                                <Th>Hole#</Th>
                                <Th>Stroke</Th>
                                <Th>Putt</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.map((dt, i) => {
                                return (
                                    <>
                                        {dt.map((d, k) => {
                                            console.log(d.id, d.p_id, d.p_name)
                                            return (
                                                <Tr>
                                                    <AlertDialogDelete colorScheme="orange" id={d.id} />
                                                    <Td>{d.id}</Td>
                                                    <Td>{d.p_id}</Td>
                                                    <Td>{d.p_name}</Td>
                                                    <Td>{d.hole_order}</Td>
                                                    <Td>{d.stroke}</Td>
                                                    <Td>{d.putt}</Td>
                                                </Tr>
                                            )
                                        })
                                        }
                                    </>
                                )
                            })}
                        </Tbody>
                    </Table>
                </>
            }
        </Stack>
    );
}