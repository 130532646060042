/*

About.js :

Oct.12,2023 : Back Btn 追加

*/
import React from 'react'
import ReactMarkdown from 'react-markdown'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer'
import { Stack, Button, Flex, Spacer } from '@chakra-ui/react'

const markdown = `
### Ura Pj2 の About
#### 目的
U.R.Association の一層の発展に寄与することを目的とする．

- 2020/12 に示された Point 計算を半自動化する．

#### プログラムの概要

- websocket で通信することで，各個人で入力し，そのデータをリアルタイムで見ることが出来る．
- モダンプログラミングの技術を導入し最大の生産性と保守性を追求する．
  具体的なスタックは以下で構成する (React,Chakra-UI, FastAPI,SQLAlchemy,SQLite3,docker,docker-compose)

#### 機能概要

- 2つの スコア入力モード
  - Party 全員分をひとりで入力する，まとめ入力モード
  - 各個人が自分の分を入力する，個別入力モード
    - 他メンバの入力は相互通信でほぼリアルタイムで表示
- リーダースボード機能
  - 同じラウンドメンバーの個人スコア合計が順位順で表示
- ポイント集計機能
  - ポイント項目の内容は Regulation を参照ください．
`

export default function About(props) {
    return (
        <Flex>
            <Stack spacing={3} m={5}>
                <ReactMarkdown
                    components={ChakraUIRenderer()}
                    children={markdown}
                    escapeHtml={false}
                />
            </Stack>
            <Spacer />
            <Button m="14" p="5" w="30" colorScheme="teal" onClick={() => window.history.back()}>
                Back
            </Button>
        </Flex>
    )
}
