/*
    DrawExtSite: 外部サイト読込み

    Nov.04,2024 : iframe は Jalan 専用
    Oct.17,2023 : VStack で画面をわずかに広くなった
    Nov.18,2021 : placement = right -> bottom に
    Nov.17,2021 : COUSE_URI を辞書形式にして course.id にて選択可能とした
    Nov.16,2021 : console.log コメントアウト
    Apr.26,2021 : URI を直書きから config 経由で渡すとした．
    Apr.23,2021 : issued: じゃらんGolf site のコースレイアウトを出してみた．

*/
import React, { useContext } from 'react'
import {
    Button,
    VStack,
    Drawer,
    DrawerBody,
    DrawerOverlay,
    useDisclosure,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'

// import config from '../pages/const'
import { PartyContext } from '../pages/Score'

export default function DrawExtSite(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    const { course } = useContext(PartyContext)
    // console.log('URI: ', course.uri)

    function handleOnOpen(e) {
        onOpen()
    }

    return (
        <>
            <Button colorScheme="teal" ref={btnRef} onClick={handleOnOpen}>
                CourseLO
            </Button>
            <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} finalFocusRef={btnRef}>
                <DrawerOverlay>
                    <DrawerContent>
                        <VStack>
                            <DrawerCloseButton />
                            <DrawerBody>
                                <iframe
                                    w="100%"
                                    height="500px"
                                    src={course.uri + '#golfcourseTab#layoutBox'} // jalan 専用 id
                                    sandbox="allow-same-origin allow-scripts"
                                />
                            </DrawerBody>
                        </VStack>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    )
}
// GDO https://reserve.golfdigest.co.jp/golf-course/325501/course-info/#hole-layout-1-2
//    modal を開くのが面倒 -> iframe Block
// Rakuten https://booking.gora.golf.rakuten.co.jp/guide/course_info/layout/disp/c_id/90109
//    一番スッキリだが，-> iframe Block
// Jalan https://golf-jalan.net/gc00640/detail/#golfcourseTab
//    tab 操作が面倒だけど，これしかないみたい
