/*
    Dec.03,2021 : 環境変数を config.js から .env に変更
    Apr.10,2021 : LINE_redirect_URI を config に
    Apr.04,2021 : 環境変数を config file に追い出し

    Line Developer の Redirect URI と 
    config に設定している URIを一致させること．
*/
import React, { useState, useEffect, } from 'react';
import { LineLogin } from 'reactjs-line-login';
import {
    Box, Center, Link, Container, Image,
} from "@chakra-ui/react";

//import config from './const';

import User from './User';
import 'reactjs-line-login/dist/index.css';
import { Redirect } from 'react-router-dom';

function User2(props) {
    //const uid = '1'
    //console.log("msg = ", props.idToken.email)
    // ここでユーザ登録とニックネームを入れる．
    // すでに入っていればそのまま抜けてもよい． --> やめた

    return (
        <div>
            Hello, {props.idToken.name}さん: Login successful
            <Redirect to="/" />
        </div>
    );
}

export default function Login(props) {
    const [payload, setPayload] = useState(null);
    const [idToken, setIdToken] = useState(null);

    console.log("REACT ID: ", process.env.REACT_APP_LINE_LOGIN_CHANNEL_ID)
    useEffect(() => {
        if (payload != null) {
            sessionStorage.setItem('payload', payload)
        }
    }, [payload]);

    useEffect(() => {
        if (idToken != null) {
            sessionStorage.setItem('idToken', idToken)
            //sessionStorage.setItem('isLoggedIn', 'true')
            console.log("LOGIN OK??: ", idToken)
            User.login()
        }
    }, [idToken]);

    return (
        <Container>
            {payload && idToken ? (
                <User2 idToken={idToken} />
            ) : (
                <>
                    <Center bg="white" h="100px" color="gray">
                        LINEアカウントでログインを行います．
                    </Center>
                    <Box>本サービスでは，ログイン認証のため，あなたのLineアカウントに登録されている mail address を取得します．</Box>
                    <Box>習得した mail address は以下の目的以外では使用しません．また，法令に定められた場合を除き，第三者へは提供いたしません．</Box>

                    <Box m="5">
                        <li>ユーザーIDとしてアカウントの管理に利用</li>
                    </Box>
                    <LineLogin
                        clientID={process.env.REACT_APP_LINE_LOGIN_CHANNEL_ID}
                        clientSecret={process.env.REACT_APP_LINE_LOGIN_CHANNEL_SECRET}
                        state={process.env.REACT_APP_LINE_LOGIN_state}
                        redirectURI={process.env.REACT_APP_LINE_LOGIN_RedirectURI}
                        scope={process.env.REACT_APP_LINE_LOGIN_scope}
                        setPayload={setPayload}
                        setIdToken={setIdToken}
                    />
                </>
            )}
        </Container>
    );
};
//nonce = ''
