/*
DrawMemo

memo の Drawer 入力

Nov.03,2024 : memo のformat: 2桁数字の bug fix
Nov.02,2024 : memo のformat を過去互換に修正
Nov.02,2024 : 初期状態での Gon を 2 にした．
Nov.01,2024 : Slider の lock 対応，CntBtn を DrawOBPE と共通化
Oct.29,2024 : Drawer で inputUid の memo を入力する．

残り120y から Green On までの打数  Gon: ##
最初の Putt の距離(歩数)   Pt1st: ##
Cup in した Putt の距離(歩数)   PtCin: ##

memo の Format : {Gon: ##, Pt1st: ##, PtCin: ##} メモ....
*/
import React, { useContext, useState, useEffect, useRef } from 'react'
import {
    Box,
    Button,
    Checkbox,
    HStack,
    VStack,
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Input,
    useNumberInput,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
    Spacer,
} from '@chakra-ui/react'

import { CntBtn } from './DrawOBPE'

import { PartyContext } from '../pages/Score'

function BoxMemo(props) {
    // 基本 Box
    const inputEl = useRef(null)
    const handleType = () => {
        props.setMemo(inputEl.current.value)
        // console.log('BoxMemo val = ', inputEl.current.value)
    }

    return (
        <Input
            ref={inputEl}
            type="text"
            defaultValue={props.memo}
            isDisabled={props.lock}
            placeholder="これは自由メモ欄です max 128文字"
            onChange={handleType}
        />
    )
}

function SliderPutt(props) {
    // const [sliderValue, setSliderValue] = useState(props.slVar)

    const labelStyles = {
        mt: '2',
        ml: '-2.5',
        fontSize: 'sm',
    }

    const handleSlide = (val) => {
        // console.log('handle slide = ', val)
        // setSliderValue(val)
        props.setSlVal(val)
    }

    return (
        <Box w="100%" pt={12} pb={3} pl={5}>
            <Slider
                aria-label="slider-ex-6"
                min={props.min}
                max={props.max}
                defaultValue={props.slVal}
                onChange={handleSlide}
                isDisabled={props.lock}
            >
                <SliderMark value={props.min} {...labelStyles}>
                    &lt;1
                </SliderMark>
                <SliderMark value={props.tick1} {...labelStyles}>
                    {props.tick1}
                </SliderMark>
                <SliderMark value={props.tick2} {...labelStyles}>
                    {props.tick2}
                </SliderMark>
                <SliderMark value={props.max} {...labelStyles}>
                    {props.max}+
                </SliderMark>
                <SliderMark
                    value={props.slVal}
                    textAlign="center"
                    fontSize={28}
                    fontWeight="bold"
                    bg="blue.500"
                    color="white"
                    mt="-14"
                    ml="-5"
                    w="12"
                >
                    {props.slVal}
                </SliderMark>
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb bg="blue.400" boxSize={5} />
            </Slider>
        </Box>
    )
}
export default function DrawMemo(props) {
    //
    // Drawer for OB/PE/BK
    //
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    const {
        inputMode,
        inputUid,
        isOnLine,
        colorZone1,
        colorZone2,
        colorZone3,
        scoreP1,
        setScoreP1,
        scoreP2,
        setScoreP2,
        scoreP3,
        setScoreP3,
        scoreP4,
        setScoreP4,
    } = useContext(PartyContext)

    const scorePx = eval('scoreP' + inputUid)
    const setScorePx = eval('setScoreP' + props.uid)
    let newMemo = scorePx.memo
    const origMemo = scorePx.memo
        .replace(/{/g, '')
        .replace(/}/g, ',')
        .replace(/Pt1st/g, ' Pt1')
        .replace(/PtCin/g, ' PtF')

    /*
    memo の Format : {Gon: ##, Pt1st: ##, PtCin: ##} メモ.... から
    必要な部分を切り出す
    */
    const [memoGon, setMemoGon] = useState(
        newMemo.match(/Gon: (\d+)/i) ? newMemo.match(/Gon: (\d+)/i)[1] : 2
    )
    const [memoPt1st, setMemoPt1st] = useState(
        newMemo.match(/Pt1st: e*(\d+)/) ? newMemo.match(/Pt1st: e*(\d+)/)[1] : 2
    )
    const [memoPt1stE, setMemoPt1stE] = useState(
        newMemo.match(/Pt1st: (e*)\d+/) ? newMemo.match(/Pt1st: (e*)\d+/)[1] : false
    )
    const [memoPtCin, setMemoPtCin] = useState(
        newMemo.match(/PtCin: (\d+)/) ? newMemo.match(/PtCin: (\d+)/)[1] : 0
    )
    const [memo, setMemo] = useState(
        scorePx.memo.match(/{.*}(.*)/)
            ? scorePx.memo.match(/{.*}(.*)/)[1]
            : scorePx.memo.match(/(.*)/)
            ? scorePx.memo.match(/(.*)/)[1]
            : ''
    )

    const handleOnClose = () => {
        // drawer Close 時に memo を set する
        if (!scorePx.lock)
            setScorePx({
                ...scorePx,
                memo:
                    '{ Gon: ' +
                    memoGon +
                    ',Pt1st: ' +
                    (memoPt1stE ? 'e' : '') +
                    memoPt1st +
                    ',PtCin: ' +
                    memoPtCin +
                    '} ' +
                    memo,
            })
        onClose()
    }

    return (
        <>
            <Button textAlign="center" color="teal.600" bg="gray.300" onClick={onOpen}>
                {origMemo ? origMemo : 'memo'}
            </Button>
            <Drawer
                size="md"
                isOpen={isOpen}
                placement="bottom"
                onClose={handleOnClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerBody>
                            <Box h={10}>memo</Box>
                            <Box fontSize={18} bg="gray.100" h={7} p="2px" m="2px">
                                残り120y から Green On までの打数
                            </Box>
                            <CntBtn
                                key="st0"
                                cat="Gon"
                                uid={inputUid}
                                memoGon={memoGon}
                                setMemoGon={setMemoGon}
                                lock={scorePx.lock}
                            />
                            <Box fontSize={18} bg="gray.100" h={7} p="2px" m="2px">
                                最初の Putt の距離(歩数)
                            </Box>
                            <HStack>
                                <VStack spacing="0">
                                    <Checkbox
                                        size="lg"
                                        w="10%"
                                        colorScheme="green"
                                        borderColor="green"
                                        value="edge"
                                        isChecked={memoPt1stE}
                                        isDisabled={scorePx.lock}
                                        onChange={(e) => setMemoPt1stE(e.target.checked)}
                                    ></Checkbox>
                                    <Box>Edge</Box>
                                </VStack>
                                <SliderPutt
                                    key="pt0"
                                    cat="Pt1st"
                                    min={0}
                                    max={40}
                                    tick1={10}
                                    tick2={20}
                                    slVal={memoPt1st}
                                    setSlVal={setMemoPt1st}
                                    lock={scorePx.lock}
                                />
                            </HStack>

                            <Box fontSize={18} bg="gray.100" h={7} p="2px" m="2px">
                                Cup in した Putt の距離(歩数)
                            </Box>
                            <SliderPutt
                                key="pt1"
                                cat="PtCin"
                                min={0}
                                max={10}
                                tick1={2}
                                tick2={5}
                                slVal={memoPtCin}
                                setSlVal={setMemoPtCin}
                                lock={scorePx.lock}
                            />
                            <Box fontSize={18} bg="gray.100" h={7} p="2px" m="2px">
                                自由記述
                            </Box>
                            <BoxMemo memo={memo} setMemo={setMemo} lock={scorePx.lock} />
                            <Box h={7} />
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    )
}
