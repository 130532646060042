/*
ティーショットの結果を表示する

    Nov.03,2024 : Layout 調整
    Nov.02,2024 : console.log 削除
    Nov.01,2024 : menuList の位置と幅を調整
    Feb.26,2024 : TeeShot に加えて DP/NP も入力するように変更

*/
import React, { useContext } from 'react'
import { Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'

import { PartyContext } from '../pages/Score'

export default function TeeShot(props) {
    // 基本 Box
    const {
        curHole,
        courseData,
        scoreP1,
        setScoreP1,
        scoreP2,
        setScoreP2,
        scoreP3,
        setScoreP3,
        scoreP4,
        setScoreP4,
        colorZone3,
    } = useContext(PartyContext)

    const scorePx = eval('scoreP' + props.uid)
    const setScorePx = eval('setScoreP' + props.uid)

    const menus3 = [
        { label: '-', value: 0 },
        { label: 'NP', value: 12 },
        { label: '1on', value: 1 },
        { label: '▶ 右', value: 2 },
        { label: '◀ 左', value: 3 },
        { label: '⬇短', value: 4 },
        { label: '⬆Ovr', value: 5 },
        { label: 'Miss', value: 6 },
    ]
    const menus45 = [
        { label: '-', value: 0 },
        { label: 'DP', value: 11 },
        { label: 'fw', value: 1 },
        { label: '▶ 右', value: 2 },
        { label: '◀ 左', value: 3 },
        { label: 'OB右', value: 4 },
        { label: 'OB左', value: 5 },
        { label: 'Miss', value: 6 },
    ]

    var menus = []
    if (courseData[curHole - 1].par == 3) {
        menus = menus3
    } else {
        menus = menus45
    }

    const updateValue = (e) => {
        var dpv, ts
        if (e.target.value == 0) {
            dpv = 0
            ts = 0
        } else if (e.target.value >= 10) {
            // DP, NP
            dpv = e.target.value - 10
            ts = 1
        } else {
            dpv = 0
            ts = e.target.value
        }
        const ob = courseData[curHole - 1].par != 3 && (ts == 4 || ts == 5) ? 1 : 0
        setScorePx({
            ...scorePx,
            dp: Number(dpv),
            tee_shot: ts,
            ob: ob,
        })
        console.log('TeeShot val = ', dpv, ts)
    }

    const writeProtect = scorePx.lock || props.zone === colorZone3
    // console.log('TeeShot INIT label = ', menus.find((x) => x.value == scorePx.tee_shot).label)

    //const tsVal = menus.find((x) => x.value == scorePx.tee_shot).label

    return (
        <Menu {...props} offset={[0, -410]}>
            <MenuButton
                as={Button}
                bg={props.zone}
                color="white"
                fontSize={24}
                w="100%"
                _disabled={{ opacity: 0.8, color: 'white' }}
                _hover={{ _disabled: { bg: 'gray.200', color: 'gray.800' } }}
                isDisabled={writeProtect}
                px={0}
            >
                {menus.find((x) => x.value == scorePx.tee_shot).label}
            </MenuButton>
            <MenuList fontSize={30} minW="0" w={'110px'} borderWidth="2px" borderColor="gray.300">
                {menus.map((menu, n) => (
                    <MenuItem key={n} value={menu.value} onClick={updateValue} pt={0} pb={0}>
                        {menu.label}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}
