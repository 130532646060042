/*
   Round List (Home Page)

   - Round Data を fetch
   - date で sort
   - button と一緒に表示

    Oct.16,2023 : database v2: s_name 対応
    Dec.06,2021 : Nav fixed 対応
    Dec.02,2021 : 環境変数を config から .env に変更
    Nov.25,2021 : fetchRound : error 処理を強化
    Apr.26,2021 : map の key を追加
    Apr.10,2021 : API_ORIGIN を config に

*/
import React, { useState, useEffect } from 'react'
import { Box, Button, Flex, Spacer, Spinner, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

function RoundList(round) {
    console.log('RoundList:', round, round.round.date)
    const edit_round_path = {
        pathname: '/AddRound/' + round.round.id,
        state: {
            date: round.round.date,
            course_id: round.round.course.id,
            course_name: round.round.course.course_name,
            s_name: round.round.s_name,
        },
    }
    const round_path = {
        pathname: '/Round/' + round.round.id,
    }
    return (
        <Box p={1} m={1} border="1px" borderColor="gray.300">
            <Flex m={1}>
                <Box p={0} mx={2}>
                    {round.round.date}
                </Box>
                <Box mx={2}>{round.round.course.course_name}</Box>/
                <Box mx={2}>{round.round.s_name}</Box>
                <Link to={edit_round_path}>
                    <Text color="tomato" mr={3}>
                        : Edit
                    </Text>
                </Link>
                <Link to={round_path}>
                    <Button colorScheme="teal" mb={0} size="sm">
                        Select
                    </Button>
                </Link>
            </Flex>
        </Box>
    )
}

export default function Home(props) {
    const API_ORIGIN = process.env.REACT_APP_BESVR_URI_ORIGIN

    const [rounds, setRounds] = useState([])

    const [isLoading, setIsLoading] = useState(false)
    const fetchRounds = async () => {
        setIsLoading(true)
        try {
            const response = await fetch(API_ORIGIN + '/api/round', {
                mode: 'cors',
                headers: {
                    'Content-type': 'application/json',
                    Accept: 'application/json',
                },
            })
            if (response.ok) {
                const results = await response.json()
                console.log('Response:', response)
                console.log('results(json):', results)
                setRounds(results)
            } else {
                throw new Error('Fetch Error: ' + response.status + ' : ' + response.statusText)
            }
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }
    useEffect(() => {
        fetchRounds()
    }, [])

    return (
        <Box pos="relative" top="43px" zIndex="2" bg="white">
            <Flex>
                <Spacer />
                <Link to="/AddRound">
                    <Button colorScheme="teal">New Round</Button>
                </Link>
            </Flex>
            {isLoading ? (
                <Spinner />
            ) : (
                rounds.map((round, n) => {
                    return <RoundList key={n} round={round} />
                })
            )}
        </Box>
    )
}
