/*
   Putt / OB / Banker / Penalty Input
    props.uid に対応した scorePx を設定する．

    Input: PartyContext, uid, cat(putt|ob|banker|penalty)
    Output: scorePx.putt/ob/banker/penalty

    Nov.03,2024 : layout 調整
    Nov.01,2024 : menuList の位置と幅を調整
    Oct.01,2024 : max 7->6 に変更
    Jul.15,2023 : box & menulist fontsize 大きくした
    Aug.10,2021 : Lock時の Button Color 変更
    Apr.26,2021 : map の key 修正

*/
import React, { useContext } from 'react'
import { Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'

import { PartyContext } from '../pages/Score'

export default function BoxPt(props) {
    // Ptの基本 Box
    const {
        scoreP1,
        setScoreP1,
        scoreP2,
        setScoreP2,
        scoreP3,
        setScoreP3,
        scoreP4,
        setScoreP4,
        colorZone3,
    } = useContext(PartyContext)

    const scorePx = eval('scoreP' + props.uid)
    const setScorePx = eval('setScoreP' + props.uid)

    const menus = [
        { label: '0', value: 0 },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
    ]

    const updateValue = (e) => {
        if (props.cat === 'putt') {
            setScorePx({
                ...scorePx,
                putt: Number(e.target.value),
            })
        } else if (props.cat === 'ob') {
            setScorePx({
                ...scorePx,
                ob: Number(e.target.value),
            })
        } else if (props.cat === 'banker') {
            setScorePx({
                ...scorePx,
                banker: Number(e.target.value),
            })
        } else if (props.cat === 'penalty') {
            setScorePx({
                ...scorePx,
                penalty: Number(e.target.value),
            })
        }
        console.log('Pt val = ', props.cat, e.target.value)
    }
    // Menu Label Update
    let ptVal
    switch (props.cat) {
        case 'putt':
            ptVal = <>{scorePx.putt}</>
            break
        case 'ob':
            ptVal = <>{scorePx.ob}</>
            break
        case 'banker':
            ptVal = <>{scorePx.banker}</>
            break
        case 'penalty':
            ptVal = <>{scorePx.penalty}</>
            break
    }

    const writeProtect = scorePx.lock || props.zone === colorZone3

    return (
        <Menu {...props} offset={[-40, -135]}>
            <MenuButton
                as={Button}
                bg={props.zone}
                color="white"
                fontSize={44}
                w="100%"
                _disabled={{ opacity: 0.8, color: 'white' }}
                _hover={{ _disabled: { bg: 'gray.200', color: 'gray.800' } }}
                isDisabled={writeProtect}
            >
                {ptVal}
            </MenuButton>
            <MenuList
                maxHeight={350}
                overflowY="auto"
                fontSize={28}
                minW="0"
                w={'50px'}
                p={0}
                borderWidth="2px"
                borderColor="gray.300"
            >
                {menus.map((menu, n) => (
                    <MenuItem key={n} value={menu.value} py={0} onClick={updateValue}>
                        {menu.label}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}
