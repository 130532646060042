/*

UserManual.js :

Oct.12,2023 : Back Btn 追加

*/
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Stack, Button, Flex, Spacer } from '@chakra-ui/react'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer'

const manual = `## 超簡単操作マニュアル

### Score 入力

![](manual/Manual-Score.png)

### Point Board 説明

![](manual/ura-point.png)

## 見ないでしょうけど，ちょっと説明

#### Round登録

  - New Round で登録する
  - コースデータの追加は Samasa.Lab に依頼する．
    - 気が向いたら作るかも．

#### Party登録/Team登録

  - パーティメンバの入力と同時にチーム編成，ハンデ，朝アルを入力する
    - 朝アルなどは後から入力も可能

#### Score登録

- 個別入力モード(各個人が入力)とまとめ入力モード(代表者がパーティ全員分を入力)の 2つのモードがある．
- 個別入力モードでは，個人の入力はリアルタイムで他のパーティメンバ画面に反映される
- 個別入力の確定は，右上の鍵ボタンやホール遷移ボタンを押したときに保存される．
- まとめ入力モードでは，Banker など全部のデータは入力できない．
- ルールの変動に備えて，xx縛りなど，Score画面でポイントの計算はせずに，そのままを入力するにした．
- メンバの OnLine 状態は名前右の★マークで確認できます．
- DP,NP は TeeShotに反映されます
- メダルを入力すると PT数に反映されます
- Tee Shot OB は OBカウンタに反映されます
- 通信データは大したことはないと思いますが，接続の悪い場所では使えないので山奥の場合は注意．

#### Login/User名登録

- Line連携で Login 可能(今のところ Line アカウントがあれば誰でも Login できます)
- Login と Score の名前は関連付けせずに，都度，自分の名前を選択するようにした．(Gameでキャラを選択するのと同様の考えです)

#### チーム登録

- パーティ登録画面でチームを登録する．
- 対戦モードは 2人/チーム，4人/チーム(2ペア）の固定
- Team メンバの組合せは入力順で固定

#### Point表示

- 個人，チーム，総合成績表を Tab で選択
- 表示項目，採用Regulationを Checkbox で設定可能
- Regulation の xx縛りの有無を設定可能
`

export default function UserManual(props) {
    return (
        <Flex>
            <Stack pos="relative" top="43px" bg="white" spacing={2} tm={10}>
                <ReactMarkdown
                    components={ChakraUIRenderer()}
                    children={manual}
                    escapeHtml={false}
                />
            </Stack>
            <Spacer />
            <Button m="14" p="5" w="30" colorScheme="teal" onClick={() => window.history.back()}>
                Back
            </Button>
        </Flex>
    )
}
