/*
    BoxParty: 特定 Round での Party 表示 Box
        Pry #X: Edit <SCORE> player1, 2 ...

    Dec.14,2021 : uid 設定前の Score Btn を outline に変更
    Dec.02,2021 : 環境変数を config から .env に変更
    Nov.20,2021 : Score Btn で入力者指定後，そのまま Redirect するようにした．
    Sep.01,2021 : まとめ入力/個別入力 に統一．テキスト表示を目立つように変更
    Jul.01,2021 : onLineStatus() : useEffect の中に関数定義を入れた．
    Apr.22,2021 : couse data の受け渡しを course に変更(course_id, course_name, course_dt をまとめた)
    Apr.19,2021 : 2重Logon を防ぐため，すでに OnLine になっているメンバは選択不可とした．
    Apr.12,2021 : sessionStrage の後始末を追加
//  Apr.09,2021 inputUid を決める Drawer を score を開く前に決めたいのでこちらに移動
//              connection をすぐに開くため
//
*/
import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
    Box,
    Button,
    Center,
    Flex,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    useDisclosure,
    DrawerContent,
    DrawerCloseButton,
    Radio,
    RadioGroup,
    Stack,
    Spacer,
    Text,
} from '@chakra-ui/react'

import config from '../pages/const'

//
// inputUid が local Stor になかった(null)ら，Drawer で設定させる．
//
function DrawerInputSettings(props) {
    const history = useHistory()
    const API_ORIGIN = process.env.REACT_APP_BESVR_URI_ORIGIN

    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    var InputUidTmp = '0'

    //const [isLoading, setIsLoading] = useState(false);
    const [onLine, setOnLine] = useState([])

    //
    useEffect(() => {
        async function onLineStatus() {
            //setIsLoading(true)
            const response = await fetch(API_ORIGIN + '/api/ws/status/p' + props.pData.id)
            const results = await response.json()

            // すでに Logon しているメンバを Check
            console.log('BoxParty: results(json):', results, results.online, typeof results.online)
            var newOnLine = { p1: false, p2: false, p3: false, p4: false }
            results.online !== false &&
                results.online.map((p) => {
                    newOnLine[p] = true
                })

            setOnLine(newOnLine)
            //setIsLoading(false)
        }
        onLineStatus()
    }, [isOpen])

    console.log('ONLINE: ', onLine)

    const getInputUid = (e) => {
        InputUidTmp = e
        console.log('InputUID: ', e)
    }
    const toSave = (e) => {
        console.log('Save InputUID: ', InputUidTmp)
        if (InputUidTmp > 0 && InputUidTmp <= 4) {
            sessionStorage.removeItem('scoreP1tmp')
            sessionStorage.removeItem('scoreP2tmp')
            sessionStorage.removeItem('scoreP3tmp')
            sessionStorage.removeItem('scoreP4tmp')
            props.setInputUid(InputUidTmp)
            props.setMyParty(props.pData.id)
            sessionStorage.setItem('curHole', '1')
            sessionStorage.setItem('InputUid', InputUidTmp)
            sessionStorage.setItem('myParty', props.pData.id)
            onClose()
            history.push(props.score_path)
        }
    }
    const toCancel = (e) => {
        onClose()
        //window.history.back()
    }

    const Msg = () => {
        if (props.pData.input_mode === config.VOLUNTEER_MODE) {
            console.log('MSG: ', props.pData.input_mode)
            return (
                <Text>
                    <Text fontSize="xl" color="red">
                        まとめ入力モード
                    </Text>
                    代表者が全員分を入力します．
                    <br />
                    代表者のみ名前ボタンをクリックして Save してください．
                    <br />
                    この入力モードは画面を閉じるまで保持されます．
                </Text>
            )
        } else if (props.pData.input_mode === config.INDIVISUAL_MODE)
            return (
                <Text>
                    <Text fontSize="xl" color="red">
                        個別入力モード
                    </Text>
                    他者の入力は出来ません．
                    <br />
                    入力モードは画面を閉じるまで保持されます．
                </Text>
            )
        else return <Text>Idle</Text>
    }

    return (
        <>
            <Button colorScheme="teal" variant="outline" ref={btnRef} onClick={onOpen}>
                Score
            </Button>
            <Center w={12}>{props.pData.player1_name}</Center>
            <Center w={12}>{props.pData.player2_name}</Center>
            <Center w={12}>{props.pData.player3_name}</Center>
            <Center w={12}>{props.pData.player4_name}</Center>

            <Drawer
                isOpen={isOpen || props.toOpen}
                placement="right"
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Input Settings</DrawerHeader>

                        <DrawerBody>
                            <Stack spacing={1} direction="column">
                                <Msg />
                                <RadioGroup onChange={getInputUid} defaultValue="0">
                                    <Spacer />
                                    <Stack direction="column">
                                        {onLine['p1'] ? (
                                            <Radio value="1" isDisabled>
                                                {props.pData.player1_name}{' '}
                                            </Radio>
                                        ) : (
                                            <Radio value="1">{props.pData.player1_name} </Radio>
                                        )}
                                        {onLine['p2'] ? (
                                            <Radio value="2" isDisabled>
                                                {props.pData.player2_name}{' '}
                                            </Radio>
                                        ) : (
                                            <Radio value="2">{props.pData.player2_name} </Radio>
                                        )}
                                        {onLine['p3'] ? (
                                            <Radio value="3" isDisabled>
                                                {props.pData.player3_name}{' '}
                                            </Radio>
                                        ) : (
                                            <Radio value="3">{props.pData.player3_name} </Radio>
                                        )}
                                        {onLine['p4'] ? (
                                            <Radio value="4" isDisabled>
                                                {props.pData.player4_name}{' '}
                                            </Radio>
                                        ) : (
                                            <Radio value="4">{props.pData.player4_name} </Radio>
                                        )}
                                    </Stack>
                                </RadioGroup>
                            </Stack>
                            <Flex>
                                <Spacer />
                                <Button variant="outline" mr={3} onClick={toCancel}>
                                    Cancel{' '}
                                </Button>
                                <Button colorScheme="blue" onClick={toSave}>
                                    Save
                                </Button>
                            </Flex>
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    )
}

export default function BoxParty(props) {
    console.log('BoxParty = ', props)
    console.log('pData  = ', props.pData)
    console.log('pData inputMode = ', props.pData.input_mode)
    //const ptypath = `/Party/${props.party.id}`

    const party_path = {
        pathname: '/Party/' + props.pid,
        state: {
            round_id: props.rid,
            course_id: props.course.id,
            course_name: props.course.course_name,
        },
    }
    const score_path = {
        pathname: '/Score/' + props.rid + '/' + props.pid,
        state: {
            //round_id: props.rid,
            course: props.course,
            parties: props.parties,
            input_mode: props.pData.input_mode,
        },
    }

    return (
        <Box>
            <Flex m={3}>
                <Stack>
                    <Text>Pty #{props.pnum}</Text>
                    <Link to={party_path}>
                        <Center color="tomato" mx={3} my={0}>
                            : Edit
                        </Center>
                    </Link>
                </Stack>
                {props.pid !== Number(props.myParty) || props.inputUid === 0 ? (
                    <DrawerInputSettings
                        pData={props.pData}
                        inputUid={props.inputUid}
                        setInputUid={props.setInputUid}
                        myParty={props.myParty}
                        setMyParty={props.setMyParty}
                        score_path={score_path}
                    />
                ) : (
                    <>
                        <Link to={score_path}>
                            <Button colorScheme="teal">Score</Button>
                        </Link>
                        <Center w={12}>{props.pData.player1_name}</Center>
                        <Center w={12}>{props.pData.player2_name}</Center>
                        <Center w={12}>{props.pData.player3_name}</Center>
                        <Center w={12}>{props.pData.player4_name}</Center>
                    </>
                )}
            </Flex>
        </Box>
    )
}
