/*

ReleaseNOte.js :

Oct.12,2023 : Back Btn 追加

*/
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Stack, Button, Spacer, Flex } from '@chakra-ui/react'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer'
/*
今後予定項目
- Multi Thread対応：共有メモリファイル活用

要Watch
- tee_shot にありえない 0 が入っていることがある．
*/

// ●●● update時には docker-compose の image ver も update すること ●●●

const markdown = `### Release Note

#### u25.03: Mar.08,2025

- 3Pty 対応のため，OLT を
  (ペア計-最下位ペア計)/2p を単純 1/2(切上げ) にした
- Score.js で course.uri 未定義のときは CourseLO を表示しない(ScoreBoard.jsx)
- ±モードのときの未入力時は Stroke を '.' に，Par の表示は '-' に変更

#### u24.11b: Nov.03,2024

- Score Layout 調整
  - 4人の Layout が左に寄っていたので，Box をかぶせて横幅いっぱいに表示させた
  - Btn の説明がなかったので， THRU/DP/NP/OL/TeeShot の表示を追加した
- DrawOBPE, DrawMemo update
  - Drawer を閉じていても中身を表示するようにした
  - Pt距離が 2桁時の不具合を修正
  - Pt1st に Edge の Checkbox を追加した  e20 のような表示となる
- DrawExtSite: iframe に id 追加し，Jalan 専用に変更

#### u24.11a: Nov.02,2024

- DrawOBPE: input の props は readonly -> isReadOnly だった
- DrawMemo: memo のformat を過去互換に修正

#### u24.11: Nov.02,2024

- memo を Drawer に変更．memo の中で，
  - のこり 120y からの打数
  - 最初の putt の距離(歩数)
  - 最後の putt の距離(歩数)
  をmemo に挿入する
- memo は Drawer を閉じたときに発行する．(開かなければ null)
- Pt の隠し Btn を Click することで，St/Pt に Par/2 を default値として入力する
- Score 入力の menuList の位置と幅を最適化し，入力しやすくした．
- plusMinus の変更を専用のBtn ではなく，TTL Stroke を隠し Btn にした
- Score 入力を plusMinus 対応とした
- 誤操作でテキストを選択状態にしないよう，Score のテキストを選択不可にした

#### u24.10a: Oct.28,2024

- OB/PE/BK Drawer を ± 入力に変更
- OB/PE/BK Drawer は個人別ではなく，全体での Drawer に変更

#### u24.10: Oct.01,2024

- OB/PE/BK を Drawer で入力可とした．4人のデータ入力ができるようになった．

#### u24.07c: Sep.30,2024

- URA緊急招集会議@立川(Sep.21,2024)の内容反映

  - Nov.8,2021 URA事前会議@武蔵溝ノ口にて，従来の DP 1p から，パー縛りで 2p に変更したが，DP のポイントが勝敗への影響が大きすぎる，との声が上がり，慎重な審議の結果，DP をパー縛り 2p -> 1p に変更することになりました．本ルールは次回より採用予定です．なお，NP の 2p は変更ありません．
- Regulation 説明ページを追加

#### u24.07b: Aug.25,2024

- submit 後の windows.back 時に，スマホの browser では cache が使用されて，DB に設定した new Party が reload されないことがある．1組目を表示されないままに NewParty をすると，2組目にも関わらず 1組目の guid が設定されるため Point 計算が出来ない．要因は分かったが，対策を検討中．-> Sep.30,2024 文言を追加した．

#### u24.06: Jun.17,2024

- NP : 自チームからは消ゴムなし

#### u24.02g : Mar.18,2024

- PointBoard THRU の ToolTips 修正

#### u24.02f : Mar.18,2024

- PointBoard THRU の GrosT に bug が入ったを修正

#### u24.02e : Mar.18,2024

- LeadersBoard Bug fix
  - plusMinus の時は std で sort しなければいけない
- Rule6:St6 の仕様変更
  - チーム st計 -> チーム std 計 >= 6
  - 関連PointBoard の表示修正
- Submit 条件変更: 本人以外の Score でも入力可能

#### u24.02d : Feb.27,2024

- PointBoard 表示の TeeShot=Miss に対応抜けを修正

#### u24.02c : Feb.27,2024

- 入力者のみしか入力できなかった Tee Shot を各個人分も入力できるように変更．各人の FW Keep率などの算出が可能になる．
- Presen Mode の表示順を見直し(PpP追加，見せ方変更)

#### u24.02b : Feb.22,2024

- HCap DB 連携: BrkR(自己ベスト更新P)を追加
  - BrkR(自己ベスト更新P)を Presen に追加
- Presen Table を全面見直し

#### u24.02 : Feb.20,2024

- HCap DB 連携を実装

#### u23.12 : Dec.14,2023

- On1 3on = 2p に格上げ
- NetT を DefaultRules に追加
- database v2 対応もれ修正(Round.js)

#### u23.10R : Oct.17,2023 :

- database v2 対応
- round db への s_name 書き込みを追加
- team_win の db への書き込みを追加
- URI は const.js から DB へ移管

#### u23.10a : Oct.13,2023 :

- BoxTHRU と Presen を Presen に統合した
- ReleaseNote, Regulation らに Back Btn を追加した

#### u23.10 : Oct.08,2023 :

- NetT の表示で Grs を表示するように変更
- HxH の default 表示から SxS を false とした

#### u23.07e : Jul.21,2023 :

- plusMinus 表示に対応修正
- LB も plusMinus 表示に対応した
- PointBoard の font を大きくした

#### u23.07c : Jul.18,2023 :

- plusMinus 表示に対応した
- docker-compose 見直し

#### u23.07b : Jul.17,2023 :

- u23.07 からの落ち穂拾い
  - Score Hole の MenuList の文字を大きくした

#### u23.07 : Jul.15,2023 :

- 2人/team での組み合わせ表示ミスを fix．(得点には影響なし)
- 文字が小さくて見えにくいので，Score 表示の font を大きくした．
- LB の msg は，Par 以下，または，ダボ以下の OL を表示に変更．(以前の OL は制限なしだった)
- python 環境に rye を採用
- python 3.10 対応

#### u22.11 : Nov.14,2022 :

- 二重登録がまだ出るため,POST は UPsert に変更
- Point Board 関係: name="" は非表示に変更
- Point Board Back は元に正しくBack する.
- iPhone SE で Half 3文字のコース名だとはみ出て見えないのを,Btn を大きくして対応
- Leaders Board に Half ごとの集計を表示. sd は割愛した.
  - 後半に score が無いときに Undefined Err するの対応

#### u22.09d : Oct.10,2022 :

- Presen icon を変更
- Presen mode で master の rule 設定が優先されていることを確認．

#### u22.09c : Sep.27,2022 :

- Presen/THRU 1Pty 表示時の Back Btn 抜け修正．
- Point/Team Tab で隠しBtn 追加

#### u22.09b : Sep.27,2022 :

- THRU では checkedRules を変更させないように変更
- Point Board の個人/Team の初期表示項目を使用頻度の高い st+pt, HxH に変更

#### u22.09a : Sep.26,2022 :

- Presen Mode の Bugfix.
  - SessionStorage に TodaysRules の初期値が必要だった．
- be 側に，新コースを登録するための API を追加

#### u21.04-β31b,c: Sep.08,2022 :

- course data 追加

#### u21.04-β31a: Jul.21,2022 :

- THRU で NetT を表示できてなかったのを修正

#### u21.04-β31: Jul.21,2022 :

- Point Presen mode の改善
  - Point での Regulation 設定を THRU を含めて共通で使用するとした
    - 以前は THRU 独自の初期値を持っていたのを改善
- default Rules の見直し.
  - HC を入れるメドがないので, NetTeam を default から除外.
  - HO3 は自動計算のメドが立たないので除外.
- Point THRU の Bug fix
  - BKiP が表示されてなかった.

#### u21.04-β30: May.28,2022 :

- Point に memo 表示復活
- Point の 表示Reset を All 表示に変更

#### u21.04-β29: Dec.14,2021 :

- Point から計算部分を分離して，プレゼンモード Present と共有できるようにした．

#### u21.04-β28: Dec.12,2021 :

- Point から計算部分を分離

#### u21.04-β27: Dec.12,2021 :

- Point のプレゼンモード: 結果発表で発信者の設定が全員に配信される

#### u21.04-β26: Dec.11,2021 :

- ws 管理を再度見直し． ws に関する State Machine を作成
- Point のプレゼンモードを書いたが，その前に登録することにした

#### u21.04-β25: Dec.07,2021 :

- Point Board 表示改善: ◯，△ 表示など
- Point Board 項目別表示スイッチ追加
- Point Board から Score 修正のための Link 作成
- Leaders Board に Par 以上，medal 獲得の Round Msg を追加
- nav 固定して，Point Board の横スクロールしても nav button が見えるように改善．
- Download : iPhone/Chrome では NG だが，ひとまずこれで良しとする．

#### u21.04-β24: Dec.03,2021 :

- Party Delete の実装
- Score Record Delete の実装 (2重登録があった時の対処のため)
- websocket 管理の大幅見直し．手元の iPhone/iPad では確認．
- Score Data の Download 確認(ただし，iPhone/Chrome は NG．safari で代用)
- Dev/Prod の環境スイッチを .env に統一．docker 環境も見直し統合した．
- VScode の環境見直し．かなり使えるようになった．

Dec.01,2021 :

Score DB の record 単位での delete 実装完了．
JSX 内での多重配列の map の書き方にはクセがあり，これを理解するのに手間取ったが，約6H で作業完了．
data の DL を調査．どうやら iPhone Chrome では NG だが，iPhone Safari なら DL の Dialog Box が出て file を DL できる．なぜなんだぁ.......

Nov.30,2021 :

Party Del 実装完了．確認用の Dialog も入れた．Round Del にも Dialog を追加．
Score DB の record Del は明日．

Nov.29,2021 :

- WebSocket 管理を全面見直し．iPhone/iPad では Total, score の同期が suspend 含めて正しく出来るようになった．通信障害などへの対応力は未知．
- POST/PUT 問題に悩んでいたが，ひっとしたら useState フラグのリセットの問題かもしれない．React の使い方に，まだ不慣れなところは否めない．見直しはしたが自信なし．
- Party Del の Backend API を実装．front 側は明日．POST/PUT issue での 2重登録懸念もあるので，Score DB の Del I/F も実装を決断．明日から取り掛かる．

#### u21.04-β23: Nov.20,2021 :

- 初回の Score Btn で input uid 登録で元画面に戻っていたが，そのまま Score 画面に Redirect するよう変更
- Download のサンプル実装(未完成)

#### u21.04-β22: Nov.17,2021 :

- 2重登録対策の Bug fix. 設定していた flag isUpdate が反対だった．
- COURSE_URI を辞書形式で const.js に定義した．
- 個人入力モードで sleep後に isOnLine を使って Score を同期する．

#### u21.04-β21: Nov.16,2021 :

- 個別入力モードで Sleep後のデータ共有の既知不具合修正
- コメント修正，debug msg を整理
- 不要な useState を見直し

#### u21.04-β20: Nov.13,2021 :

- Nov.8,2021 URA事前会議@武蔵溝ノ口の内容反映
  - DP をパー縛り 2p
  - NP を消ゴムあり 2p
- Regulation 説明ページを追加

#### u21.04-β19: Oct.22,2021 :

-  2重登録防止ため processing Flag を追加 etc

#### u21.04-β18: Sep.29,2021 :

- 集計用の API を追加

#### u21.04-β17: Sep.24,2021 :

- いつの間にか，round 作成時に pty が無いと err していた．
- course_dt の res1 = int に 文字が入っていると err していた．
   - ===> /api/round, /api/round/{rid} の schema などを見直し．

#### u21.04-β16: Sep.02,2021 :

- NewParty 時に 2組目の Default 設定(入力モード，前半，後半，チーム構成)を 1組目と同じにする．(マニュアルで変更は可能) 2組目作成後の 1組目の変更は反映されないので注意．
- 超簡単マニュアル追加

#### u21.04-β15: Aug.10,2021 :

- Lock時の Button コンストラクトが低く見にくかったので，opacity=0.4 -> 0.8にして，見やすくした．この結果，色の変化が小さくなったので，より Lock モードが明確になるよう，Lock ボタンを Orange で目立つようにした．
- Point Board から Score に Jumpするとき，input_mode 不定の時がある．state.location から取得して不定とならないようにした．

#### u21.04-β14: Jul.06,2021 : 新規Party 作成時の bug fix

- 前回の fetch-then-render 変更で，新Partyを作るときに，無限ループになっていたのを修正．

#### u21.04-β13: Jul.04,2021 : react-hook-form の仕様変更に対する対応

- β12 の update時に，docker の npm が動かなくて update した．このはずみで，いろいろな module が update されてしまい，
  register の使い方が変更され，動かなくなっていた．対応 5H. しくしく．(;_;)
- Party.js で fetch-then-render の新方式を採用．

#### u21.04-β12: Jun.30,2021 : Document を Markdown で書けるように環境作成

- Manual, RelaseNote など，すべての Document の保守性向上の為，Markdown を表示できるよう chakra-ui-markdown-renderer を Install.

#### u21.04-β11: Jun.28,2021 : POST/PUT method 問題は isUpdate の初期化のミス

- POST すべきが PUT になっていたらしい．isUpdate の初期化が抜けていたのではないか？
  curHole 時に初期化を入れて様子見する．
- さらに，鍵ボタンの登録では Score.id 不定のため，そのまま再編集をすると PUT が Err することが判明．対応した

#### u21.04-β10: Jun.27,2021 : POST/PUT method timing を修正

- POST すべきが PUT になっていた．method 判定タイミングを修正して様子見
#### u21.04-β9: Jun.27,2021 : BiP Bug Fix
- Banker In Par が Typo で算出できていなかったのを修正
- Score 未入力時の Point Board 表示が 待ち状態になっていた解除した
#### u21.04-β8: May.26,2021 : Minor Bug Fix
- Score入力画面で，コース名が長いとボタンからはみ出るので３文字でCutほか，配置を調整
#### u21.04-β7: May.17,2021 : Minor Bug Fix
- 旧DB をマージした．(新DB での追加Field は空のため完全互換は無し)
- Point Board の Pty Tab に memo 表示機能を追加した(memoありは St の数字が Green になるので，タッチするとmemoを表示する)
- DP と TeeShot，OL と Putt，連携するはずが，Typo してたを修正．
#### u21.04-β6: Apr.29,2021
- Party編集で 1st/2nd Half 表示の Bug Fix
- Point Board Regulation がすぐに表示に反映されないの Bug fix
- Rendering 性能向上のための Score fetch を 4->1回 に変更．その副作用で Tee Shot Button 初期表示不具合直ったかもしれない
#### u21.04-β5: Apr.23,2021
- 鍵ボタンで登録＆Lock, UnLock 可能とした
- Score 訂正が簡単にできるようにScore一覧から Jump可能とした
- 2重 Login禁止
- Total 値の Update のため，Browser Resume 時の Update を追加
- Leader Board: Stroke=0 が最上位の Bug Fix
- Print.js の Tableで "" を作らない書き方に変更
- guid の入力自動化
- テスト運用: Score 入力画面に外部サイトのコースレイアウト表示機能を追加
#### u21.04-β4: Apr.18,2021
- PoP は w/o Short Hole に修正
- Score の 2重登録防止: 自hole を選択したときの post 時に scorePn に lock=true をすることで，WriteProtect に移行させる
- Total Score をコンパクト化して，LB のボタンを上部に移動
#### u21.04-β3: Apr.15,2021
- New Party作成時の default値を設定．初期入力の手間を省く
- New Round作成後 Submitで前の画面に戻すようにした．
- New Round: Delete Round を実装
- useRef による memo の描画高速化(このため OK Buttonが必要になった)
- memo の drawer は iPhone画面が崩れるのでやめる．
- 画面縮小や，スコア入力で画面の一部が隠れてしまうとどうしようのなくなるので，Zoom Lock機能は使用をしない．
- Tee Shot Short Hole 時 OB 連携の bug fix
- Tee Shot Button 初期表示不具合再発．
#### u21.04-β2: Apr.14,2021
- Yard, HDCP 表示対応．
- Leaders Board を実装しました．
- Tee Shot Button Write Protect の不具合を修正，Short Hole 対応追加
- 4人チーム時の総合計を作成しました．
- Point Board: 集計Bug Fix: 9,18 Hole目, BDy の集計漏れ, Regulation On/Off の THRUへの反映漏れ Fix．
- 18 Hole から右矢印で Score が2度書きされる Bug 修正
#### u21.04-β: Apr.12,2021
- 本日，u21.04-β版を release します．
- 個人入力対応のための WebSocket と Loginを実装しました．初期の目的の機能はほぼ実装できましました．(ルールのいくつかはサボったままですが :-p )
- WebSocket版．想定上に難航しました，なんとかなったと思います．Masters と並行してこの3日間は本当に苦しかった．裏で勝手に動いていた Multi Thread のゴーストに気が付き，これを Off としたら落ち着きました．なんとか回避できそうです．)
- 実践が楽しみになってきました．
- Login は Line Login を使わせてもらいました．Loginまではしっかりしてるのですが，後の key とかの管理は適当です．:-p
- 既知の不具合： TeeShot のボタン,Backgroundで設定すると色が反映されない．Lock時 も同様． トータルが realtime で反映できてない．

#### Rev 0.9: Apr.05,2021
- 本日，draft版 0.9 を release しました．
- 中身は，Round設定，Party設定，従来どおりの一人が4人分を入力する Score 入力とPoint集計表です．
- はじめて Score のボタンを押すと，あなたは誰？ と聞かれますので他人とバッティングしないように答えてください．一度答えると Browser の Tab を閉じるまで記憶されるはずです．(他人になりすますには Tab を閉じる必要があります．)
- 予想されたことですが，Point Board がメチャメチャ大きい！はて，どうしたものか...
- Point Board は大きいのでTab 形式で4つに分けましたが，まだ大きい．最終チームまとめは THRU のTab にあります．項目の数字は福元さんにいただいた Regulationの項目番号です．意味不明なものや労力に見合わない稀なケースものなど実装をサボりました．Regulation 略号の意味は Regulationsボタンで分かるかもしれません．
- 新機能として，Point Board の項目表示の有無の CheckBox と 各Regulation の採用有無の CheckBox をつけました．(xx縛りのOn/Offも)
- 検討が数ヶ月，具体的なコーディングに 2/10 入ってから約2ヶ月．新規開発あるあるで，予定の日程の約２倍かかっています．中身は30年前の中古車から，最新版になっているのですが，機能的には現行版に少し毛が生えた程度．
- 残件は Login 認証と websocket を使った，各個人での入力モード．既知の不具合もあるのですが，運用で逃げるとして，未知の新規部分に取り掛かって出口を探します．
- 4/17 に間に合うのか? 現行版で行くのか? 今週の一週間が勝負です．
- p.s. 極太グリップに変えたあとに購入した Mavric W3 のグリップ．単体で似たようなのがなかなか見つからず，どうするか悩んでたのですが，amazon で 8本セット追加購入に踏み切り届きました．これから換装予定です．
`

export default function ReleaseNote(props) {
    return (
        <Flex>
            <Stack spacing={3} m={5}>
                <ReactMarkdown
                    components={ChakraUIRenderer()}
                    children={markdown}
                    escapeHtml={false}
                />
            </Stack>
            <Spacer />
            <Button m="14" p="5" w="30" colorScheme="teal" onClick={() => window.history.back()}>
                Back
            </Button>
        </Flex>
    )
}
