/*
    OB / Penalty / Banker の menu btn 部分の表示

    Nov.03,2024 : Layout 調整
    Nov.03,2024 : issued

*/
import React, { useContext } from 'react'
import { Center, HStack, VStack, Spacer } from '@chakra-ui/react'

import { PartyContext } from '../pages/Score'

export default function MiniBoxOBPE(props) {
    const { inputUid, scoreP1, scoreP2, scoreP3, scoreP4 } = useContext(PartyContext)

    const scorePx = eval('scoreP' + props.uid)
    const myZone = inputUid == props.uid ? 'green.500' : 'blue.400'

    // Style
    const styleBox = {
        color: 'gray.700',
        spacing: '0',
    }
    const styleOB = {
        fontSize: '16',
        color: 'red.200',
    }
    const stylePE = {
        fontSize: '16',
        color: 'yellow.300',
    }
    const styleBK = {
        fontSize: '16',
        color: 'gray.300',
    }
    const styleTxt = {
        fontSize: '40',
        color: 'gray.100',
    }

    return (
        <>
            <HStack>
                <VStack w="28%" {...styleBox} bg={myZone}>
                    <Center {...styleOB}>OB</Center>
                    <Center {...styleTxt}>{scorePx.ob}</Center>
                </VStack>
                <VStack w="28%" {...styleBox} bg={myZone}>
                    <Center {...stylePE}>PE</Center>
                    <Center {...styleTxt}>{scorePx.penalty}</Center>
                </VStack>
                <VStack w="28%" {...styleBox} bg={myZone}>
                    <Center {...styleBK}>BK</Center>
                    <Center {...styleTxt}>{scorePx.banker}</Center>
                </VStack>
                <Spacer />
            </HStack>
        </>
    )
}
