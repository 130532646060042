/*

TeamTable : Round 表示で対戦チームを表示するテーブルを作る

Dec.01,2021 : MgmtDB への Link を追加

*/
import React from 'react'
import {
    //Button, Flex, Select, Spacer,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import config from '../pages/const'

export default function TeamTable(props) {
    // input:
    //     playMode, setPlayMode, defaultMembers[{ pty:, oid:, name: }] members, setMembers
    // output:
    //     playMode, Members
    // play mode mode2: 2p/team, mode4: 4p/team

    return (
        <>
            <Table variant="simple" colorScheme="facebook" size="sm">
                <Thead>
                    <Tr>
                        {props.parties[0].play_mode === config.MODE2 ? (
                            <>
                                <Th w="20%" color="white" bg="green.800">
                                    Team{' '}
                                </Th>
                                <Th w="20%" textAlign="center" color="white" bg="green.600">
                                    A
                                </Th>
                                <Th w="20%" textAlign="center" color="white" bg="green.700">
                                    B
                                </Th>
                                <Th w="20%" textAlign="center" color="white" bg="green.600">
                                    C
                                </Th>
                                <Th w="20%" textAlign="center" color="white" bg="green.700">
                                    D
                                </Th>
                            </>
                        ) : (
                            <>
                                <Th w="20%" color="white" bg="green.800">
                                    Team{' '}
                                </Th>
                                <Th
                                    w="40%"
                                    textAlign="center"
                                    colSpan="2"
                                    color="white"
                                    bg="green.600"
                                >
                                    A
                                </Th>
                                <Th
                                    w="40%"
                                    textAlign="center"
                                    colSpan="2"
                                    color="white"
                                    bg="green.700"
                                >
                                    B
                                </Th>
                            </>
                        )}
                    </Tr>
                </Thead>
                <Tbody>
                    {props.parties.map((pty, n) => {
                        return (
                            <Tr key={n}>
                                <Th color="white" bg="green.600">
                                    <Link to={{ pathname: '/DB/' + pty.id }}>Pty {n + 1}</Link>
                                </Th>
                                {pty.player1_name !== '' && (
                                    <Td textAlign="center">{pty.player1_name}</Td>
                                )}
                                {pty.player2_name !== '' && (
                                    <Td textAlign="center">{pty.player2_name}</Td>
                                )}
                                {pty.player3_name !== '' && (
                                    <Td textAlign="center">{pty.player3_name}</Td>
                                )}
                                {pty.player4_name !== '' && (
                                    <Td textAlign="center">{pty.player4_name}</Td>
                                )}
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </>
    )
}
