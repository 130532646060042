/*

Calc Point

Jun.17,2024 : NP : 自チームからは消ゴムなし
Mar.18,2024 : TTL 得点なしは null -> 0
Mar.18,2024 : SpS を元の stroke に戻して，SDpSD を追加
Mar.18,2024 : St6 の条件変更．std のペア計 >= 6 で -1p
Feb.24,2024 : putt 合計を表示するため，PpP を追加
Dec.13,2023 : On1 3on = 2p に格上げ
Jul.21,2022 : NetT を ODDT の違いが不明のため当面は同じにして,
              TTL では両方を加算した.regulation で片方にすること.
Dec.13,2021 : issued

*/
import config from './const'

export default function calcPoint(
    playMode,
    data,
    parData,
    scores,
    sumScores,
    teamScores,
    sumTeamScores,
    pnum,
    members,
    teamMemberOrder,
    checkedRules
) {
    //
    var scoresTmp = scores
    //console.log("calcBaseData: resps :", data)
    // 元データから Table の項目を算出し，let tmpScore を作る．
    // tmpScore は score に保存．  18 hole x 8 人
    data.map((d, id) => {
        d.sc.map((s, iid) => {
            //
            // get Scores & Calc Parsonal Scores
            //
            const parNum = parData[Number(s.hole_order) - 1]
            //console.log("sc :", iid, parData0[0], parNum, s.hole_order, s.p_id, s.stroke, s.putt)
            const PoP0 =
                parNum >= 4 && s.stroke > 0 && s.stroke <= parNum && s.stroke - s.putt <= parNum - 2
                    ? 1
                    : 0
            const BiP0 = s.stroke > 0 && s.stroke <= parNum && s.banker > 0 ? 1 : 0
            const BDy0 = s.stroke == parNum - 1 ? 2 : s.stroke == parNum - 2 ? 3 : 0
            const DPt0 =
                parNum >= 4 &&
                s.dp > 0 &&
                ((!checkedRules.reqDPtBgi && !checkedRules.reqDPtPar) ||
                    (s.stroke <= parNum && checkedRules.reqDPtPar) ||
                    (s.stroke <= parNum + 1 && checkedRules.reqDPtBgi))
                    ? 1
                    : 0 // Sep.22,2024 DPt = 2 -> 1 (Nov.13,2021 DPt = 1 -> 2)
            // NPt0 : 消ゴム前の NP 権利
            const NPt0 =
                parNum < 4 && s.dp > 0 && (s.stroke <= parNum || !checkedRules.reqNPt) ? 2 : 0 // Nov.13,2021 NPt = 1 -> 2
            // NPtX : 消ゴムの権利チェック : 1on != true && stroke <= par
            const NPtX0 =
                parNum < 4 &&
                s.dp == 0 &&
                checkedRules.reqNPtXon &&
                ((s.stroke == parNum - 1 && s.putt == 0) || (s.stroke == parNum && s.putt <= 1))
            const On10 = parNum < 4 && s.stroke - s.putt == 1 ? 1 : 0
            const OLt0 = s.ol > 0 && (s.stroke <= parNum + 2 || !checkedRules.reqOLt) ? s.ol : 0

            scoresTmp[Number(s.hole_order) - 1][s.guid] = {
                half_name: s.half_name,
                hole_num: s.hole_num,
                stroke: s.stroke,
                st_delta: s.st_delta,
                putt: s.putt,
                ol: s.ol,
                dp: s.dp,
                fw: s.tee_shot,
                ob: s.ob,
                bk: s.banker,
                pe: s.penalty,
                memo: s.memo,
                PoP: PoP0,
                BiP: BiP0,
                BDy: BDy0,
                DPt: DPt0,
                NPt: NPt0,
                NPtX: NPtX0,
                OLt: OLt0,
                On1: On10,
                Ho3: '',
            }
        })
    })
    console.log('scoresTmp', scoresTmp)
    //
    // Calc by Party
    //   Ho3: Hole で単独首位を +1
    for (let hole = 0; hole < 18; hole++) {
        //for (let pty = 0; pty < 2; pty++) {
        for (let pty = 0; pty < pnum; pty++) {
            if (checkedRules.reqNPtXon) {
                // NP の消ゴム処理
                // BDy でなく，消ゴム権利が成立すれば，NP 剥奪(自チームなら消ゴムしない)
                if (
                    scoresTmp[hole][4 * pty + 0].BDy == 0 &&
                    ((playMode == config.MODE2 && scoresTmp[hole][4 * pty + 1].NPtX) ||
                        scoresTmp[hole][4 * pty + 2].NPtX ||
                        scoresTmp[hole][4 * pty + 3].NPtX)
                )
                    scoresTmp[hole][4 * pty + 0].NPt = 0
                if (
                    scoresTmp[hole][4 * pty + 1].BDy == 0 &&
                    ((playMode == config.MODE2 && scoresTmp[hole][4 * pty + 0].NPtX) ||
                        scoresTmp[hole][4 * pty + 2].NPtX ||
                        scoresTmp[hole][4 * pty + 3].NPtX)
                )
                    scoresTmp[hole][4 * pty + 1].NPt = 0
                if (
                    scoresTmp[hole][4 * pty + 2].BDy == 0 &&
                    (scoresTmp[hole][4 * pty + 0].NPtX ||
                        scoresTmp[hole][4 * pty + 1].NPtX ||
                        (playMode == config.MODE2 && scoresTmp[hole][4 * pty + 3].NPtX))
                )
                    scoresTmp[hole][4 * pty + 2].NPt = 0
                if (
                    scoresTmp[hole][4 * pty + 3].BDy == 0 &&
                    (scoresTmp[hole][4 * pty + 0].NPtX ||
                        scoresTmp[hole][4 * pty + 1].NPtX ||
                        (playMode == config.MODE2 && scoresTmp[hole][4 * pty + 2].NPtX))
                )
                    scoresTmp[hole][4 * pty + 3].NPt = 0
            }
            /*
            // NP の消ゴム: 消す権利あり(NPtX=1) なら NP は 0
            if (
                checkedRules.reqNPtXon &&
                (scoresTmp[hole][4 * pty + 0].NPtX ||
                    scoresTmp[hole][4 * pty + 1].NPtX ||
                    scoresTmp[hole][4 * pty + 2].NPtX ||
                    scoresTmp[hole][4 * pty + 3].NPtX)
            ) {
                // BDy でなければ，NP 剥奪
                if (scoresTmp[hole][4 * pty + 0].BDy == 0) scoresTmp[hole][4 * pty + 0].NPt = 0
                if (scoresTmp[hole][4 * pty + 1].BDy == 0) scoresTmp[hole][4 * pty + 1].NPt = 0
                if (scoresTmp[hole][4 * pty + 2].BDy == 0) scoresTmp[hole][4 * pty + 2].NPt = 0
                if (scoresTmp[hole][4 * pty + 3].BDy == 0) scoresTmp[hole][4 * pty + 3].NPt = 0
            }
            */

            // Pty ごとの単独首位は Ho3 = 1
            const ptySt = [
                scoresTmp[hole][4 * pty + 0].stroke,
                scoresTmp[hole][4 * pty + 1].stroke,
                scoresTmp[hole][4 * pty + 2].stroke,
                scoresTmp[hole][4 * pty + 3].stroke,
            ]
            const ptySt2 = ptySt.filter((st) => st > 0) // st = 0 は除外
            let min = Math.min(...ptySt2)
            let cnt = 0
            let candidate = []
            // min stroke par hole & party
            for (let nplayer = 0; nplayer < 4; nplayer++) {
                if (scoresTmp[hole][4 * pty + nplayer].stroke == min) {
                    cnt++
                    candidate = nplayer
                }
            }
            //console.log("Hor: Pty, Hole: ", pty, hole, min, cnt)
            if (cnt == 1) scoresTmp[hole][4 * pty + candidate].Ho3 = 1
            else {
                scoresTmp[hole][4 * pty + candidate].Ho3 = ''
                //console.log("Hor: Pty, Draw Hole: ", pty, hole)
            }
        }
    }
    //
    // Calc by Team
    //
    var teamScoresTmp = teamScores
    //console.log("TEAM SCORES INIT: ", teamScoresTmp)

    //const vuid = (playMode == config.MODE2) ?
    //    [0, 4, 1, 5, 2, 6, 3, 7] : [0, 1, 2, 3, 4, 5, 6, 7]
    const vuid = teamMemberOrder

    //console.log("vuid", vuid)
    //
    // 各Holeごとの Pair 計算
    //
    for (let hole = 0; hole < 18; hole++) {
        //for (let j = 0; j < 4; j++) {
        for (let j = 0; j < pnum * 2; j++) {
            teamScoresTmp[hole][j] = {
                SxS: scoresTmp[hole][vuid[2 * j]].stroke * scoresTmp[hole][vuid[2 * j + 1]].stroke,
                PpP: scoresTmp[hole][vuid[2 * j]].putt + scoresTmp[hole][vuid[2 * j + 1]].putt,
                SpS: scoresTmp[hole][vuid[2 * j]].stroke + scoresTmp[hole][vuid[2 * j + 1]].stroke,
                SDpSD:
                    scoresTmp[hole][vuid[2 * j]].st_delta +
                    scoresTmp[hole][vuid[2 * j + 1]].st_delta,
                // OLP: OLt Pair 各Hole合計
                OLP: scoresTmp[hole][vuid[2 * j]].OLt + scoresTmp[hole][vuid[2 * j + 1]].OLt,
            }
        }
        // Calc HxH, St6
        if (playMode == config.MODE2) {
            //
            // MODE2 は 4 pair を比較   ////
            //
            // MODE2 の HxH
            const stx = [
                teamScoresTmp[hole][0].SxS,
                teamScoresTmp[hole][1].SxS,
                teamScoresTmp[hole][2].SxS,
                teamScoresTmp[hole][3].SxS,
            ]
            let stx2 = stx.filter((sxs) => sxs > 0) // 0 は除外
            let min = Math.min(...stx2)
            let cnt = 0
            let candidate = []
            //for (let i = 0; i < 4; i++) {
            for (let i = 0; i < pnum * 2; i++) {
                if (teamScoresTmp[hole][i].SxS == min) {
                    cnt++
                    candidate = i
                }
            }
            //console.log("HxH: Hole, min, cnt: ", hole, min, cnt)
            // min の勝者に 1p
            if (cnt == 1) teamScoresTmp[hole][candidate].HxH = 1

            // MODE2 の St6
            /*
            const stp = [
                teamScoresTmp[hole][0].SpS,
                teamScoresTmp[hole][1].SpS,
                teamScoresTmp[hole][2].SpS,
                teamScoresTmp[hole][3].SpS,
            ]
            let stp2 = stp.filter((st) => st > 0) // 0 は除外
            min = Math.min(...stp2)
            let max = Math.max(...stp2)
            if (max - min >= 6)
                //for (let pair = 0; pair < 4; pair++)
                for (let pair = 0; pair < pnum * 2; pair++)
                    teamScoresTmp[hole][pair].St6 =
                        teamScoresTmp[hole][pair].SpS - min >= 6 ? -1 : null */
        } else {
            // MODE4 の HxH, St6 は pty それぞれの pair で比較
            for (let pair = 0; pair < pnum * 2; pair++) {
                if (pair % 2 == 0) {
                    teamScoresTmp[hole][pair].HxH =
                        0 < teamScoresTmp[hole][pair].SxS && // 0 は除く
                        teamScoresTmp[hole][pair].SxS < teamScoresTmp[hole][pair + 1].SxS
                            ? 1
                            : null
                    /* teamScoresTmp[hole][pair].St6 =
                        teamScoresTmp[hole][pair + 1].SpS > 0 &&
                        teamScoresTmp[hole][pair].SpS - teamScoresTmp[hole][pair + 1].SpS >= 6
                            ? -1
                            : null */
                } else {
                    teamScoresTmp[hole][pair].HxH =
                        0 < teamScoresTmp[hole][pair].SxS &&
                        teamScoresTmp[hole][pair].SxS < teamScoresTmp[hole][pair - 1].SxS
                            ? 1
                            : null
                    /* teamScoresTmp[hole][pair].St6 =
                        teamScoresTmp[hole][pair - 1].SpS > 0 &&
                        teamScoresTmp[hole][pair].SpS - teamScoresTmp[hole][pair - 1].SpS >= 6
                            ? -1
                            : null */
                }
            }
        }
        for (let pair = 0; pair < pnum * 2; pair++) {
            // Pt6 はペア単体で計算可能
            // HxH: St6: は play_mode で処理が異なる
            // StD, PtD は意味不明
            teamScoresTmp[hole][pair].Pt6 = teamScoresTmp[hole][pair].PpP >= 6 ? -1 : null
            teamScoresTmp[hole][pair].St6 = teamScoresTmp[hole][pair].SDpSD >= 6 ? -1 : null
        }
    }
    console.log('TEAM SCORES: ', teamScoresTmp)

    // 元データを前半と後半に分割
    const scoresH1 = scoresTmp.slice(0, 9)
    const scoresH2 = scoresTmp.slice(9, 18)
    //console.log("SCORE H1: ", scoresH1, scoresH2)
    //  変数の準備
    var sumScTmp = sumScores
    // 8人分の sum を算出
    //for (let p = 0; p < 8; p++) {
    for (const p of teamMemberOrder) {
        sumScTmp[p][0] = {
            stroke: scoresH1.reduce((sum, r) => {
                return sum + (r[p].stroke ?? 0)
            }, 0),
            st_delta: scoresH1.reduce((sum, r) => {
                return sum + (r[p].st_delta ?? 0)
            }, 0),
            putt: scoresH1.reduce((sum, r) => {
                return sum + (r[p].putt ?? 0)
            }, 0),
            ol: scoresH1.reduce((sum, r) => {
                return sum + (r[p].ol ?? 0)
            }, 0),
            bk: scoresH1.reduce((sum, r) => {
                return sum + (r[p].bk ?? 0)
            }, 0),
            ob: scoresH1.reduce((sum, r) => {
                return sum + (r[p].ob ?? 0)
            }, 0),
            pe: scoresH1.reduce((sum, r) => {
                return sum + (r[p].pe ?? 0)
            }, 0),
            dp: scoresH1.reduce((sum, r) => {
                return sum + (r[p].dp ?? 0)
            }, 0),
            PoP: scoresH1.reduce((sum, r) => {
                return sum + (r[p].PoP ?? 0)
            }, 0),
            BiP: scoresH1.reduce((sum, r) => {
                return sum + (r[p].BiP ?? 0)
            }, 0),
            BDy: scoresH1.reduce((sum, r) => {
                return sum + (r[p].BDy ?? 0)
            }, 0),
            DPt: scoresH1.reduce((sum, r) => {
                return sum + (r[p].DPt ?? 0)
            }, 0),
            NPt: scoresH1.reduce((sum, r) => {
                return sum + (r[p].NPt ?? 0)
            }, 0),
            On1: scoresH1.reduce((sum, r) => {
                return sum + (r[p].On1 ?? 0)
            }, 0),
            OLt: scoresH1.reduce((sum, r) => {
                return sum + (r[p].OLt ?? 0)
            }, 0),
        }
        sumScTmp[p][1] = {
            stroke: scoresH2.reduce((sum, r) => {
                return sum + (r[p].stroke ?? 0)
            }, 0),
            st_delta: scoresH2.reduce((sum, r) => {
                return sum + (r[p].st_delta ?? 0)
            }, 0),
            putt: scoresH2.reduce((sum, r) => {
                return sum + (r[p].putt ?? 0)
            }, 0),
            ol: scoresH2.reduce((sum, r) => {
                return sum + (r[p].ol ?? 0)
            }, 0),
            bk: scoresH2.reduce((sum, r) => {
                return sum + (r[p].bk ?? 0)
            }, 0),
            ob: scoresH2.reduce((sum, r) => {
                return sum + (r[p].ob ?? 0)
            }, 0),
            pe: scoresH2.reduce((sum, r) => {
                return sum + (r[p].pe ?? 0)
            }, 0),
            dp: scoresH2.reduce((sum, r) => {
                return sum + (r[p].dp ?? 0)
            }, 0),
            PoP: scoresH2.reduce((sum, r) => {
                return sum + (r[p].PoP ?? 0)
            }, 0),
            BiP: scoresH2.reduce((sum, r) => {
                return sum + (r[p].BiP ?? 0)
            }, 0),
            BDy: scoresH2.reduce((sum, r) => {
                return sum + (r[p].BDy ?? 0)
            }, 0),
            DPt: scoresH2.reduce((sum, r) => {
                return sum + (r[p].DPt ?? 0)
            }, 0),
            NPt: scoresH2.reduce((sum, r) => {
                return sum + (r[p].NPt ?? 0)
            }, 0),
            On1: scoresH2.reduce((sum, r) => {
                return sum + (r[p].On1 ?? 0)
            }, 0),
            OLt: scoresH2.reduce((sum, r) => {
                return sum + (r[p].OLt ?? 0)
            }, 0),
        }
        sumScTmp[p][2] = {
            stroke: sumScTmp[p][0].stroke + sumScTmp[p][1].stroke,
            st_delta: sumScTmp[p][0].st_delta + sumScTmp[p][1].st_delta,
            putt: sumScTmp[p][0].putt + sumScTmp[p][1].putt,
            ol: sumScTmp[p][0].ol + sumScTmp[p][1].ol,
            bk: sumScTmp[p][0].bk + sumScTmp[p][1].bk,
            ob: sumScTmp[p][0].ob + sumScTmp[p][1].ob,
            pe: sumScTmp[p][0].pe + sumScTmp[p][1].pe,
            dp: sumScTmp[p][0].dp + sumScTmp[p][1].dp,
            PoP: sumScTmp[p][0].PoP + sumScTmp[p][1].PoP,
            BiP: sumScTmp[p][0].BiP + sumScTmp[p][1].BiP,
            BDy: sumScTmp[p][0].BDy + sumScTmp[p][1].BDy,
            DPt: sumScTmp[p][0].DPt + sumScTmp[p][1].DPt,
            NPt: sumScTmp[p][0].NPt + sumScTmp[p][1].NPt,
            On1: sumScTmp[p][0].On1 + sumScTmp[p][1].On1,
            OLt: sumScTmp[p][0].OLt + sumScTmp[p][1].OLt,

            // 個人の ODD, ODPT つき Score 算出
            //    + (checkedRules.AL ? members[p].al : 0)  odds odds_pt
            odds: sumScTmp[p][0].stroke + sumScTmp[p][1].stroke - members[p].odds,
            //odds_pt: sumScTmp[p][0].putt + sumScTmp[p][1].putt - members[p].odds_pt,
            BrkR:
                sumScTmp[p][0].stroke + sumScTmp[p][1].stroke < members[p].pbest
                    ? 3
                    : sumScTmp[p][0].stroke + sumScTmp[p][1].stroke == members[p].pbest
                    ? 1
                    : 0,

            // One ON の数で Point 決定
            On1P:
                sumScTmp[p][0].On1 + sumScTmp[p][1].On1 >= 4
                    ? 3
                    : sumScTmp[p][0].On1 + sumScTmp[p][1].On1 == 3
                    ? 2
                    : sumScTmp[p][0].On1 + sumScTmp[p][1].On1 == 2
                    ? 1
                    : sumScTmp[p][0].On1 + sumScTmp[p][1].On1 == 0
                    ? -1
                    : 0,
        }
        // 個人得点TTL    checkedRules のフラグによって TTL を操作する
        // 同じ sumScTmp[p][2] の値を使うので，最初の計算の結果が出たあとで，再計算している
        sumScTmp[p][2] = {
            ...sumScTmp[p][2],
            TTL:
                (checkedRules.PoP ? sumScTmp[p][0].PoP + sumScTmp[p][1].PoP : 0) +
                (checkedRules.BiP ? sumScTmp[p][0].BiP + sumScTmp[p][1].BiP : 0) +
                (checkedRules.BDy ? sumScTmp[p][0].BDy + sumScTmp[p][1].BDy : 0) +
                (checkedRules.DPt ? sumScTmp[p][0].DPt + sumScTmp[p][1].DPt : 0) +
                (checkedRules.NPt ? sumScTmp[p][0].NPt + sumScTmp[p][1].NPt : 0) +
                (checkedRules.AL ? members[p].al : 0) +
                (checkedRules.BrkR ? sumScTmp[p][2].BrkR : 0) +
                (checkedRules.On1 ? sumScTmp[p][2].On1P : 0),
        }
        //console.log("SumScTmpin: ", sumScTmp, sumScTmp[0], sumScTmp[p][0].stroke)
    }
    console.log('SumScTmp: ', sumScTmp)
    //
    // Cal Total
    //    sumTSTmp [team][0] : H1
    //    sumTSTmp [team][1] : H2
    //    sumTSTmp [team][2] : H1+H2
    const teamScH1 = teamScoresTmp.slice(0, 9)
    const teamScH2 = teamScoresTmp.slice(9, 18)
    var sumTSTmp = sumTeamScores
    for (let tm = 0; tm < pnum * 2; tm++) {
        sumTSTmp[tm][0] = {
            SpS: teamScH1.reduce((sum, r) => {
                return sum + (r[tm].SpS ?? 0)
            }, 0),
            SDpSD: teamScH1.reduce((sum, r) => {
                return sum + (r[tm].SDpSD ?? 0)
            }, 0),
            PpP: teamScH1.reduce((sum, r) => {
                return sum + (r[tm].PpP ?? 0)
            }, 0),
            HxH: teamScH1.reduce((sum, r) => {
                return sum + (r[tm].HxH ?? 0)
            }, 0),
            Pt6: teamScH1.reduce((sum, r) => {
                return sum + (r[tm].Pt6 ?? 0)
            }, 0),
            St6: teamScH1.reduce((sum, r) => {
                return sum + (r[tm].St6 ?? 0)
            }, 0),
            OLP: teamScH1.reduce((sum, r) => {
                return sum + (r[tm].OLP ?? 0)
            }, 0),
        }
        sumTSTmp[tm][1] = {
            SpS: teamScH2.reduce((sum, r) => {
                return sum + (r[tm].SpS ?? 0)
            }, 0),
            SDpSD: teamScH2.reduce((sum, r) => {
                return sum + (r[tm].SDpSD ?? 0)
            }, 0),
            PpP: teamScH2.reduce((sum, r) => {
                return sum + (r[tm].PpP ?? 0)
            }, 0),
            HxH: teamScH2.reduce((sum, r) => {
                return sum + (r[tm].HxH ?? 0)
            }, 0),
            Pt6: teamScH2.reduce((sum, r) => {
                return sum + (r[tm].Pt6 ?? 0)
            }, 0),
            St6: teamScH2.reduce((sum, r) => {
                return sum + (r[tm].St6 ?? 0)
            }, 0),
            OLP: teamScH2.reduce((sum, r) => {
                return sum + (r[tm].OLP ?? 0)
            }, 0),
        }
        sumTSTmp[tm][2] = {
            SpS: sumTSTmp[tm][0].SpS + sumTSTmp[tm][1].SpS,
            SDpSD: sumTSTmp[tm][0].SDpSD + sumTSTmp[tm][1].SDpSD,
            PpP: sumTSTmp[tm][0].PpP + sumTSTmp[tm][1].PpP,
            HxH: sumTSTmp[tm][0].HxH + sumTSTmp[tm][1].HxH,
            Pt6: Number(sumTSTmp[tm][0].Pt6 + sumTSTmp[tm][1].Pt6),
            St6: sumTSTmp[tm][0].St6 + sumTSTmp[tm][1].St6,
            OLP: sumTSTmp[tm][0].OLP + sumTSTmp[tm][1].OLP,
        }
        console.log('>>(-1)>>> sumTSTmp TEAM 2: ', sumTSTmp[tm][2])
    }

    console.log('>>(1)>>> sumTSTmp TEAM: ', sumTSTmp, sumTSTmp[0][2])
    //
    // Calc by Through
    //
    for (let tm = 0; tm < pnum * 2; tm++) {
        sumTSTmp[tm][2].PoP =
            sumScTmp[teamMemberOrder[2 * tm]][2].PoP + sumScTmp[teamMemberOrder[2 * tm + 1]][2].PoP
        sumTSTmp[tm][2].BiP =
            sumScTmp[teamMemberOrder[2 * tm]][2].BiP + sumScTmp[teamMemberOrder[2 * tm + 1]][2].BiP
        sumTSTmp[tm][2].BDy =
            sumScTmp[teamMemberOrder[2 * tm]][2].BDy + sumScTmp[teamMemberOrder[2 * tm + 1]][2].BDy
        sumTSTmp[tm][2].DPt =
            sumScTmp[teamMemberOrder[2 * tm]][2].DPt + sumScTmp[teamMemberOrder[2 * tm + 1]][2].DPt
        sumTSTmp[tm][2].NPt =
            sumScTmp[teamMemberOrder[2 * tm]][2].NPt + sumScTmp[teamMemberOrder[2 * tm + 1]][2].NPt
        sumTSTmp[tm][2].On1P =
            sumScTmp[teamMemberOrder[2 * tm]][2].On1P +
            sumScTmp[teamMemberOrder[2 * tm + 1]][2].On1P
        sumTSTmp[tm][2].AL =
            members[teamMemberOrder[2 * tm]].al + members[teamMemberOrder[2 * tm + 1]].al
        sumTSTmp[tm][2].BrkR =
            sumScTmp[teamMemberOrder[2 * tm]][2].BrkR +
            sumScTmp[teamMemberOrder[2 * tm + 1]][2].BrkR
    }
    //console.log("SUM TEAM: ", sumTSTmp)

    console.log('HxH: ', sumTSTmp[0][0].HxH, sumTSTmp[1][0].HxH)
    console.log('HxH: ', sumTSTmp[2] ?? 0, sumTSTmp[3] ?? 0)
    // HxHH1 -----------------------------------------------
    // HxH Win Point Max @H1
    let stx =
        pnum == 1
            ? [sumTSTmp[0][0].HxH, sumTSTmp[1][0].HxH]
            : [sumTSTmp[0][0].HxH, sumTSTmp[1][0].HxH, sumTSTmp[2][0].HxH, sumTSTmp[3][0].HxH]
    let cnt = 0
    let candidate
    for (let tm = 0; tm < pnum * 2; tm++) {
        if (sumTSTmp[tm][0].HxH == Math.max(...stx)) {
            cnt++
            candidate = tm
        }
    }
    //console.log("HxH: Hole, min, cnt: ", hole, min, cnt)
    if (cnt == 1) sumTSTmp[candidate][2].HxHH1 = 3
    // HxHH2 -----------------------------------------------
    // HxH Win Point Max @H2
    stx =
        pnum == 1
            ? [sumTSTmp[0][1].HxH, sumTSTmp[1][1].HxH]
            : [sumTSTmp[0][1].HxH, sumTSTmp[1][1].HxH, sumTSTmp[2][1].HxH, sumTSTmp[3][1].HxH]
    cnt = 0
    for (let tm = 0; tm < pnum * 2; tm++) {
        if (sumTSTmp[tm][1].HxH == Math.max(...stx)) {
            cnt++
            candidate = tm
        }
    }
    //console.log("HxH: Hole, min, cnt: ", hole, min, cnt)
    // 同率ならなし
    if (cnt == 1) sumTSTmp[candidate][2].HxHH2 = 3

    // OL Result (OLM)
    const olTmp =
        pnum == 1
            ? [sumTSTmp[0][2].OLP, sumTSTmp[1][2].OLP]
            : [sumTSTmp[0][2].OLP, sumTSTmp[1][2].OLP, sumTSTmp[2][2].OLP, sumTSTmp[3][2].OLP]
    const delta = Math.min(...olTmp)
    for (let tm = 0; tm < pnum * 2; tm++)
        sumTSTmp[tm][2].OLM = Math.floor((sumTSTmp[tm][2].OLP - delta) / 2)

    // Best : Gross Team
    const st =
        pnum == 1
            ? [sumTSTmp[0][2].SpS, sumTSTmp[1][2].SpS]
            : [sumTSTmp[0][2].SpS, sumTSTmp[1][2].SpS, sumTSTmp[2][2].SpS, sumTSTmp[3][2].SpS]
    let st2 = st.filter((s) => s > 0) // 0 は除外
    for (let pair = 0; pair < pnum * 2; pair++)
        sumTSTmp[pair][2].GrsT = sumTSTmp[pair][2].SpS == Math.min(...st2) ? 3 : 0
    console.log('>>>>> sumTSTmp TEAM: ', st, sumTSTmp[0][2].GrsT) // min は 3 をつける
    console.log('>>>>> sumTSTmp TEAM: ', sumTSTmp)

    // sumTSTmp[pair][H1/H2/TTL]
    for (let j = 0; j < pnum * 2; j++) {
        sumTSTmp[j][2].SpSO = sumScTmp[vuid[2 * j]][2].odds + sumScTmp[vuid[2 * j + 1]][2].odds
        //sumTSTmp[j][2].PpPO =
        //    sumScTmp[vuid[2 * j]][2].odds_pt + sumScTmp[vuid[2 * j + 1]][2].odds_pt
    }
    // Best : Net Team
    const odds =
        pnum == 1
            ? [sumTSTmp[0][2].SpSO, sumTSTmp[1][2].SpSO]
            : [sumTSTmp[0][2].SpSO, sumTSTmp[1][2].SpSO, sumTSTmp[2][2].SpSO, sumTSTmp[3][2].SpSO]
    let odds2 = odds.filter((s) => s > 0) // 0 は除外
    for (let pair = 0; pair < pnum * 2; pair++)
        sumTSTmp[pair][2].ODDS = sumTSTmp[pair][2].SpSO == Math.min(...odds2) ? 3 : 0

    // Best : Net Putt Team
    const oddspt =
        pnum == 1
            ? [sumTSTmp[0][2].PpPO, sumTSTmp[1][2].PpPO]
            : [sumTSTmp[0][2].PpPO, sumTSTmp[1][2].PpPO, sumTSTmp[2][2].PpPO, sumTSTmp[3][2].PpPO]
    let oddspt2 = oddspt.filter((s) => s > 0) // 0 は除外
    for (let pair = 0; pair < pnum * 2; pair++)
        sumTSTmp[pair][2].Rslt = sumTSTmp[pair][2].PpPO == Math.min(...oddspt2) ? 3 : 0

    // Team TTL
    for (let tm = 0; tm < pnum * 2; tm++)
        sumTSTmp[tm][2].TTL =
            sumScTmp[teamMemberOrder[2 * tm]][2].TTL + // player1 個人TTL
            sumScTmp[teamMemberOrder[2 * tm + 1]][2].TTL + // player2 個人TTL
            (checkedRules.OLt ? sumTSTmp[tm][2].OLM ?? 0 : 0) +
            (checkedRules.HxH ? sumTSTmp[tm][2].HxH ?? 0 : 0) +
            (checkedRules.HxHH ? sumTSTmp[tm][2].HxHH1 ?? 0 : 0) +
            (checkedRules.HxHH ? sumTSTmp[tm][2].HxHH2 ?? 0 : 0) +
            (checkedRules.St6 ? sumTSTmp[tm][2].St6 ?? 0 : 0) +
            (checkedRules.Pt6 ? sumTSTmp[tm][2].Pt6 ?? 0 : 0) +
            (checkedRules.NetT ? sumTSTmp[tm][2].ODDS ?? 0 : 0) + // NetT == ODDT
            (checkedRules.Rslt ? sumTSTmp[tm][2].Rslt ?? 0 : 0) +
            (checkedRules.GrsT ? sumTSTmp[tm][2].GrsT ?? 0 : 0)

    if (playMode === config.MODE4 && pnum >= 2) {
        for (let tm = 0; tm < 2; tm++)
            sumTSTmp[tm][2].TeamTTL = sumTSTmp[tm][2].TTL + sumTSTmp[tm + 2][2].TTL
    }

    console.log('SUM TEAM: ', sumTSTmp)
    //setScores(scoresTmp)
    //setTeamScores(teamScoresTmp)
    //setSumScores(sumScTmp)
    //setSumTeamScores(sumTSTmp)
    return { sc: scoresTmp, teamSc: teamScoresTmp, sumSc: sumScTmp, sumTeamSc: sumTSTmp }
}
